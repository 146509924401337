import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BloomDefaultContentService {

  constructor( ) { }

  getBannerUrl() {
    let randomCode = Math.floor(Math.random() * 5) + 1
    let imageUrl = environment.ASSETS_BASE_URL + "banner/" + randomCode + '.png'
    return imageUrl
  }

  getDefaultPageContent(title: string) {
    let created_at = new Date().toISOString()
    let modified_at = new Date().toISOString()
    let bannerImageUrl = this.getBannerUrl()

    let defaultPageMeta = {
      "_id": Date.now(),
      "code": "homepage",
      "name": "homepage",
      "panels": [
        {
            "type": "regular",
            "alignment": "flex-start",
            "layoutCount": "1",
            "widgets": [],
            "id": 1721211483806,
            "name": "panel_1",
            "layoutMap": {
                "list": [
                    1721211483807
                ],
                "1721211483807": {
                    "gridX": 12,
                    "list": [
                        1721211484806,
                        1721213323412
                    ],
                    "1721211484806": {
                        "type": "elements",
                        "elements": []
                    },
                    "1721213323412": {
                        "type": "elements",
                        "elements": [
                            {
                                "effectStyleMap": {
                                    "list": [
                                        "color",
                                        "font-size"
                                    ],
                                    "color": {
                                        "id": "color",
                                        "name": "Color",
                                        "inputType": "color-palette"
                                    },
                                    "font-size": {
                                        "id": "font-size",
                                        "name": "Font Size",
                                        "inputType": "string"
                                    }
                                },
                                "id": "1721213319486",
                                "name": "image_1",
                                "type": "image",
                                "gridX": 12,
                                "gridY": 2,
                                "minGridX": 3,
                                "minGridY": 3,
                                "initialPixelHeight": 0,
                                "config": {
                                    "props": [
                                        "src",
                                        "tooltip",
                                        "width",
                                        "backgroundColor"
                                    ],
                                    "src": {
                                        "displayName": "Source URL",
                                        "value": bannerImageUrl,
                                        "resetValue": "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/image-placeholder.png",
                                        "type": "image-picker"
                                    },
                                    "tooltip": {
                                        "displayName": "Tooltip",
                                        "value": "",
                                        "type": "text",
                                        "enabled": true
                                    },
                                    "width": {
                                        "displayName": "Width",
                                        "value": "100%",
                                        "type": "select"
                                    },
                                    "backgroundColor": {
                                        "displayName": "Background Color",
                                        "type": "color-picker",
                                        "value": ""
                                    }
                                },
                                "initialGridY": 2,
                                "initialGridX": 6
                            }
                        ]
                    }
                }
            },
            "selectedNow": true
        },
        {
            "type": "regular",
            "alignment": "flex-start",
            "layoutCount": "1",
            "widgets": [],
            "id": 1721211500119,
            "name": "panel_2",
            "layoutMap": {
                "list": [
                    1721211500120
                ],
                "1721211500120": {
                    "gridX": 12,
                    "list": [
                        1721211501119,
                        1721211740082,
                        1721634053671,
                        1721219723131
                    ],
                    "1721211501119": {
                        "effectStyleMap": {
                            "list": [
                                "color",
                                "font-size"
                            ],
                            "color": {
                                "id": "color",
                                "name": "Color",
                                "inputType": "color-palette"
                            },
                            "font-size": {
                                "id": "font-size",
                                "name": "Font Size",
                                "inputType": "string"
                            }
                        },
                        "id": "1721211500119",
                        "name": "label_1",
                        "type": "label",
                        "gridX": 12,
                        "minGridX": 3,
                        "config": {
                            "props": [
                                "labelText",
                                "prefixText",
                                "alignment",
                                "defaultValue",
                                "tooltip",
                                "hidden",
                                "backgroundColor"
                            ],
                            "labelText": {
                                "displayName": "Label Text",
                                "value": title,
                                "resetValue": "",
                                "type": "longtext"
                            },
                            "prefixText": {
                                "displayName": "Prefix Text",
                                "value": "",
                                "type": "text"
                            },
                            "alignment": {
                                "displayName": "Text Alignment",
                                "value": "center",
                                "resetValue": "flex-start",
                                "type": "app-alignment-settings"
                            },
                            "tooltip": {
                                "displayName": "Tooltip",
                                "value": "",
                                "type": "text",
                                "enabled": true
                            },
                            "hidden": {
                                "displayName": "Hidden",
                                "type": "boolean",
                                "value": false
                            },
                            "defaultValue": {
                                "displayName": "Default Value",
                                "type": "defaultValue",
                                "enabled": false,
                                "dynamic": false,
                                "value": ""
                            },
                            "backgroundColor": {
                                "displayName": "Background Color",
                                "type": "color-picker",
                                "value": ""
                            }
                        },
                        "textFormat": {
                            "props": [
                                "class",
                                "fontFamily",
                                "fontSize",
                                "bold",
                                "italic",
                                "underline",
                                "color",
                                "backgroundColor"
                            ],
                            "class": {
                                "displayName": "Class",
                                "value": ""
                            },
                            "fontFamily": {
                                "displayName": "Font Family",
                                "value": "Arial"
                            },
                            "fontSize": {
                                "displayName": "Font Size",
                                "value": 32
                            },
                            "bold": {
                                "displayName": "Bold",
                                "value": false
                            },
                            "italic": {
                                "displayName": "Italic",
                                "value": false
                            },
                            "underline": {
                                "displayName": "Underline",
                                "value": false
                            },
                            "color": {
                                "displayName": "Font Color",
                                "value": ""
                            },
                            "backgroundColor": {
                                "displayName": "Background Color",
                                "value": ""
                            }
                        }
                    },
                    "1721211740082": {
                        "effectStyleMap": {
                            "list": [
                                "color",
                                "font-size"
                            ],
                            "color": {
                                "id": "color",
                                "name": "Color",
                                "inputType": "color-palette"
                            },
                            "font-size": {
                                "id": "font-size",
                                "name": "Font Size",
                                "inputType": "string"
                            }
                        },
                        "id": "1721211554600",
                        "name": "label_1",
                        "type": "label",
                        "gridX": 12,
                        "minGridX": 3,
                        "config": {
                            "props": [
                                "labelText",
                                "prefixText",
                                "alignment",
                                "defaultValue",
                                "tooltip",
                                "hidden",
                                "backgroundColor"
                            ],
                            "labelText": {
                                "displayName": "Label Text",
                                "value": "Welcome to Your New Page! Where creativity meets simplicity.",
                                "resetValue": "",
                                "type": "longtext"
                            },
                            "prefixText": {
                                "displayName": "Prefix Text",
                                "value": "",
                                "type": "text"
                            },
                            "alignment": {
                                "displayName": "Text Alignment",
                                "value": "center",
                                "resetValue": "flex-start",
                                "type": "app-alignment-settings"
                            },
                            "tooltip": {
                                "displayName": "Tooltip",
                                "value": "",
                                "type": "text",
                                "enabled": true
                            },
                            "hidden": {
                                "displayName": "Hidden",
                                "type": "boolean",
                                "value": false
                            },
                            "defaultValue": {
                                "displayName": "Default Value",
                                "type": "defaultValue",
                                "enabled": false,
                                "dynamic": false,
                                "value": ""
                            },
                            "backgroundColor": {
                                "displayName": "Background Color",
                                "type": "color-picker",
                                "value": ""
                            }
                        },
                        "textFormat": {
                            "props": [
                                "class",
                                "fontFamily",
                                "fontSize",
                                "bold",
                                "italic",
                                "underline",
                                "color",
                                "backgroundColor"
                            ],
                            "class": {
                                "displayName": "Class",
                                "value": ""
                            },
                            "fontFamily": {
                                "displayName": "Font Family",
                                "value": "Arial"
                            },
                            "fontSize": {
                                "displayName": "Font Size",
                                "value": 16
                            },
                            "bold": {
                                "displayName": "Bold",
                                "value": false
                            },
                            "italic": {
                                "displayName": "Italic",
                                "value": false
                            },
                            "underline": {
                                "displayName": "Underline",
                                "value": false
                            },
                            "color": {
                                "displayName": "Font Color",
                                "value": "#4f4f4f"
                            },
                            "backgroundColor": {
                                "displayName": "Background Color",
                                "value": ""
                            }
                        }
                    },
                    "1721634053671": {
                        "effectStyleMap": {
                            "list": [
                                "color",
                                "font-size"
                            ],
                            "color": {
                                "id": "color",
                                "name": "Color",
                                "inputType": "color-palette"
                            },
                            "font-size": {
                                "id": "font-size",
                                "name": "Font Size",
                                "inputType": "string"
                            }
                        },
                        "id": "1721633904638",
                        "name": "label_1",
                        "type": "label",
                        "gridX": 12,
                        "minGridX": 3,
                        "config": {
                            "props": [
                                "labelText",
                                "prefixText",
                                "alignment",
                                "defaultValue",
                                "tooltip",
                                "hidden",
                                "backgroundColor"
                            ],
                            "labelText": {
                                "displayName": "Label Text",
                                "value": "Your tagline goes here",
                                "resetValue": "",
                                "type": "longtext"
                            },
                            "prefixText": {
                                "displayName": "Prefix Text",
                                "value": "",
                                "type": "text"
                            },
                            "alignment": {
                                "displayName": "Text Alignment",
                                "value": "center",
                                "resetValue": "flex-start",
                                "type": "app-alignment-settings"
                            },
                            "tooltip": {
                                "displayName": "Tooltip",
                                "value": "",
                                "type": "text",
                                "enabled": true
                            },
                            "hidden": {
                                "displayName": "Hidden",
                                "type": "boolean",
                                "value": false
                            },
                            "defaultValue": {
                                "displayName": "Default Value",
                                "type": "defaultValue",
                                "enabled": false,
                                "dynamic": false,
                                "value": ""
                            },
                            "backgroundColor": {
                                "displayName": "Background Color",
                                "type": "color-picker",
                                "value": ""
                            }
                        },
                        "textFormat": {
                            "props": [
                                "class",
                                "fontFamily",
                                "fontSize",
                                "bold",
                                "italic",
                                "underline",
                                "color",
                                "backgroundColor"
                            ],
                            "class": {
                                "displayName": "Class",
                                "value": ""
                            },
                            "fontFamily": {
                                "displayName": "Font Family",
                                "value": "Arial"
                            },
                            "fontSize": {
                                "displayName": "Font Size",
                                "value": 18
                            },
                            "bold": {
                                "displayName": "Bold",
                                "value": true
                            },
                            "italic": {
                                "displayName": "Italic",
                                "value": false
                            },
                            "underline": {
                                "displayName": "Underline",
                                "value": false
                            },
                            "color": {
                                "displayName": "Font Color",
                                "value": ""
                            },
                            "backgroundColor": {
                                "displayName": "Background Color",
                                "value": ""
                            }
                        },
                        "actionConfig": {
                            "actions": [
                                {
                                    "event": "click",
                                    "action": "application",
                                    "actionMap": {
                                        "boxId": "",
                                        "mapping": []
                                    }
                                }
                            ]
                        },
                        "securityConfig": {
                            "securities": []
                        }
                    },
                    "1721219723131": {
                        "type": "elements",
                        "elements": [
                            {
                                "effectStyleMap": {
                                    "list": [
                                        "color",
                                        "font-size"
                                    ],
                                    "color": {
                                        "id": "color",
                                        "name": "Color",
                                        "inputType": "color-palette"
                                    },
                                    "font-size": {
                                        "id": "font-size",
                                        "name": "Font Size",
                                        "inputType": "string"
                                    }
                                },
                                "id": "1721219723131",
                                "name": "separator_1",
                                "type": "separator",
                                "gridX": 12,
                                "minGridX": 3,
                                "config": {
                                    "props": []
                                }
                            }
                        ]
                    },
                    "background": {
                        "image": {
                            "url": "",
                            "opacity": 1
                        },
                        "color": "#fefff0"
                    }
                }
            },
            "selectedNow": false,
            "background": {
                "color": "#fff",
                "image": {
                    "url": "",
                    "opacity": 50
                }
            },
            "margin": {
                "left": 0,
                "right": 0
            }
        },
        {
            "type": "regular",
            "alignment": "flex-start",
            "layoutCount": "2",
            "widgets": [],
            "id": 1721219249073,
            "name": "panel_4",
            "layoutMap": {
                "list": [
                    1721219249074,
                    1721219252783
                ],
                "1721219249074": {
                    "gridX": 6,
                    "list": [
                        1721219250073
                    ],
                    "1721219250073": {
                        "type": "elements",
                        "elements": [
                            {
                                "effectStyleMap": {
                                    "list": [
                                        "color",
                                        "font-size"
                                    ],
                                    "color": {
                                        "id": "color",
                                        "name": "Color",
                                        "inputType": "color-palette"
                                    },
                                    "font-size": {
                                        "id": "font-size",
                                        "name": "Font Size",
                                        "inputType": "string"
                                    }
                                },
                                "id": "1721219245152",
                                "name": "image_1",
                                "type": "image",
                                "gridX": 12,
                                "gridY": 8,
                                "minGridX": 3,
                                "minGridY": 3,
                                "initialPixelHeight": 0,
                                "config": {
                                    "props": [
                                        "src",
                                        "tooltip",
                                        "width",
                                        "backgroundColor"
                                    ],
                                    "src": {
                                        "displayName": "Source URL",
                                        "value": "https://storage.googleapis.com/download/storage/v1/b/appiworks-dev-ci.appspot.com/o/bloom%2Fimage-input%2F1704963491035337feb3-Screenshots-1721387688076.png?generation=1721387702774294&alt=media",
                                        "resetValue": "https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/common-assets/image-placeholder.png",
                                        "type": "image-picker"
                                    },
                                    "tooltip": {
                                        "displayName": "Tooltip",
                                        "value": "",
                                        "type": "text",
                                        "enabled": true
                                    },
                                    "width": {
                                        "displayName": "Width",
                                        "value": "100%",
                                        "type": "select"
                                    },
                                    "backgroundColor": {
                                        "displayName": "Background Color",
                                        "type": "color-picker",
                                        "value": ""
                                    }
                                },
                                "initialGridY": 4,
                                "initialGridX": 6
                            }
                        ]
                    }
                },
                "1721219252783": {
                    "gridX": 6,
                    "list": [
                        1721219274166,
                        1721219345255,
                        1721219347599,
                        1721219389259,
                        1721219402240,
                        1721219431790,
                        1721219440098
                    ],
                    "1721219274166": {
                        "type": "elements",
                        "elements": [
                            {
                                "effectStyleMap": {
                                    "list": [
                                        "color",
                                        "font-size"
                                    ],
                                    "color": {
                                        "id": "color",
                                        "name": "Color",
                                        "inputType": "color-palette"
                                    },
                                    "font-size": {
                                        "id": "font-size",
                                        "name": "Font Size",
                                        "inputType": "string"
                                    }
                                },
                                "id": "1721219274166",
                                "name": "label_1",
                                "type": "label",
                                "gridX": 12,
                                "minGridX": 3,
                                "config": {
                                    "props": [
                                        "labelText",
                                        "prefixText",
                                        "alignment",
                                        "defaultValue",
                                        "tooltip",
                                        "hidden",
                                        "backgroundColor"
                                    ],
                                    "labelText": {
                                        "displayName": "Label Text",
                                        "value": "How to use this bloom to create your solution",
                                        "resetValue": "",
                                        "type": "longtext"
                                    },
                                    "prefixText": {
                                        "displayName": "Prefix Text",
                                        "value": "",
                                        "type": "text"
                                    },
                                    "alignment": {
                                        "displayName": "Text Alignment",
                                        "value": "center",
                                        "resetValue": "flex-start",
                                        "type": "app-alignment-settings"
                                    },
                                    "tooltip": {
                                        "displayName": "Tooltip",
                                        "value": "",
                                        "type": "text",
                                        "enabled": true
                                    },
                                    "hidden": {
                                        "displayName": "Hidden",
                                        "type": "boolean",
                                        "value": false
                                    },
                                    "defaultValue": {
                                        "displayName": "Default Value",
                                        "type": "defaultValue",
                                        "enabled": false,
                                        "dynamic": false,
                                        "value": ""
                                    },
                                    "backgroundColor": {
                                        "displayName": "Background Color",
                                        "type": "color-picker",
                                        "value": ""
                                    }
                                },
                                "textFormat": {
                                    "props": [
                                        "class",
                                        "fontFamily",
                                        "fontSize",
                                        "bold",
                                        "italic",
                                        "underline",
                                        "color",
                                        "backgroundColor"
                                    ],
                                    "class": {
                                        "displayName": "Class",
                                        "value": ""
                                    },
                                    "fontFamily": {
                                        "displayName": "Font Family",
                                        "value": "Arial"
                                    },
                                    "fontSize": {
                                        "displayName": "Font Size",
                                        "value": 22
                                    },
                                    "bold": {
                                        "displayName": "Bold",
                                        "value": true
                                    },
                                    "italic": {
                                        "displayName": "Italic",
                                        "value": false
                                    },
                                    "underline": {
                                        "displayName": "Underline",
                                        "value": false
                                    },
                                    "color": {
                                        "displayName": "Font Color",
                                        "value": ""
                                    },
                                    "backgroundColor": {
                                        "displayName": "Background Color",
                                        "value": ""
                                    }
                                },
                                "actionConfig": {
                                    "actions": [
                                        {
                                            "event": "click",
                                            "action": "application",
                                            "actionMap": {
                                                "boxId": "",
                                                "mapping": []
                                            }
                                        }
                                    ]
                                },
                                "securityConfig": {
                                    "securities": []
                                }
                            }
                        ]
                    },
                    "1721219345255": {
                        "type": "elements",
                        "elements": [
                            {
                                "effectStyleMap": {
                                    "list": [
                                        "color",
                                        "font-size"
                                    ],
                                    "color": {
                                        "id": "color",
                                        "name": "Color",
                                        "inputType": "color-palette"
                                    },
                                    "font-size": {
                                        "id": "font-size",
                                        "name": "Font Size",
                                        "inputType": "string"
                                    }
                                },
                                "id": "1721219345255",
                                "name": "space_1",
                                "type": "space",
                                "gridX": 12,
                                "gridY": 1,
                                "minGridX": 12,
                                "config": {
                                    "props": [
                                        "tooltip",
                                        "backgroundColor"
                                    ],
                                    "backgroundColor": {
                                        "displayName": "Background Color",
                                        "type": "color-picker",
                                        "value": ""
                                    }
                                }
                            }
                        ]
                    },
                    "1721219347599": {
                        "type": "elements",
                        "elements": [
                            {
                                "effectStyleMap": {
                                    "list": [
                                        "color",
                                        "font-size"
                                    ],
                                    "color": {
                                        "id": "color",
                                        "name": "Color",
                                        "inputType": "color-palette"
                                    },
                                    "font-size": {
                                        "id": "font-size",
                                        "name": "Font Size",
                                        "inputType": "string"
                                    }
                                },
                                "id": "1721219347599",
                                "name": "label_1",
                                "type": "label",
                                "gridX": 12,
                                "minGridX": 3,
                                "config": {
                                    "props": [
                                        "labelText",
                                        "prefixText",
                                        "alignment",
                                        "defaultValue",
                                        "tooltip",
                                        "hidden",
                                        "backgroundColor"
                                    ],
                                    "labelText": {
                                        "displayName": "Label Text",
                                        "value": "Drag and drop widgets and relevent panels in canvas to design your page",
                                        "resetValue": "",
                                        "type": "longtext"
                                    },
                                    "prefixText": {
                                        "displayName": "Prefix Text",
                                        "value": "",
                                        "type": "text"
                                    },
                                    "alignment": {
                                        "displayName": "Text Alignment",
                                        "value": "flex-start",
                                        "resetValue": "flex-start",
                                        "type": "app-alignment-settings"
                                    },
                                    "tooltip": {
                                        "displayName": "Tooltip",
                                        "value": "",
                                        "type": "text",
                                        "enabled": true
                                    },
                                    "hidden": {
                                        "displayName": "Hidden",
                                        "type": "boolean",
                                        "value": false
                                    },
                                    "defaultValue": {
                                        "displayName": "Default Value",
                                        "type": "defaultValue",
                                        "enabled": false,
                                        "dynamic": false,
                                        "value": ""
                                    },
                                    "backgroundColor": {
                                        "displayName": "Background Color",
                                        "type": "color-picker",
                                        "value": ""
                                    }
                                },
                                "textFormat": {
                                    "props": [
                                        "class",
                                        "fontFamily",
                                        "fontSize",
                                        "bold",
                                        "italic",
                                        "underline",
                                        "color",
                                        "backgroundColor"
                                    ],
                                    "class": {
                                        "displayName": "Class",
                                        "value": ""
                                    },
                                    "fontFamily": {
                                        "displayName": "Font Family",
                                        "value": "Arial"
                                    },
                                    "fontSize": {
                                        "displayName": "Font Size",
                                        "value": 14
                                    },
                                    "bold": {
                                        "displayName": "Bold",
                                        "value": false
                                    },
                                    "italic": {
                                        "displayName": "Italic",
                                        "value": false
                                    },
                                    "underline": {
                                        "displayName": "Underline",
                                        "value": false
                                    },
                                    "color": {
                                        "displayName": "Font Color",
                                        "value": ""
                                    },
                                    "backgroundColor": {
                                        "displayName": "Background Color",
                                        "value": ""
                                    }
                                }
                            }
                        ]
                    },
                    "1721219389259": {
                        "type": "elements",
                        "elements": [
                            {
                                "effectStyleMap": {
                                    "list": [
                                        "color",
                                        "font-size"
                                    ],
                                    "color": {
                                        "id": "color",
                                        "name": "Color",
                                        "inputType": "color-palette"
                                    },
                                    "font-size": {
                                        "id": "font-size",
                                        "name": "Font Size",
                                        "inputType": "string"
                                    }
                                },
                                "id": "1721219389259",
                                "name": "separator_1",
                                "type": "separator",
                                "gridX": 12,
                                "minGridX": 3,
                                "config": {
                                    "props": []
                                }
                            }
                        ]
                    },
                    "1721219402240": {
                        "type": "elements",
                        "elements": [
                            {
                                "effectStyleMap": {
                                    "list": [
                                        "color",
                                        "font-size"
                                    ],
                                    "color": {
                                        "id": "color",
                                        "name": "Color",
                                        "inputType": "color-palette"
                                    },
                                    "font-size": {
                                        "id": "font-size",
                                        "name": "Font Size",
                                        "inputType": "string"
                                    }
                                },
                                "id": "1721219402240",
                                "name": "label_1",
                                "type": "label",
                                "gridX": 12,
                                "minGridX": 3,
                                "config": {
                                    "props": [
                                        "labelText",
                                        "prefixText",
                                        "alignment",
                                        "defaultValue",
                                        "tooltip",
                                        "hidden",
                                        "backgroundColor"
                                    ],
                                    "labelText": {
                                        "displayName": "Label Text",
                                        "value": "Create multiple pages to organize your web app better",
                                        "resetValue": "",
                                        "type": "longtext"
                                    },
                                    "prefixText": {
                                        "displayName": "Prefix Text",
                                        "value": "",
                                        "type": "text"
                                    },
                                    "alignment": {
                                        "displayName": "Text Alignment",
                                        "value": "flex-start",
                                        "resetValue": "flex-start",
                                        "type": "app-alignment-settings"
                                    },
                                    "tooltip": {
                                        "displayName": "Tooltip",
                                        "value": "",
                                        "type": "text",
                                        "enabled": true
                                    },
                                    "hidden": {
                                        "displayName": "Hidden",
                                        "type": "boolean",
                                        "value": false
                                    },
                                    "defaultValue": {
                                        "displayName": "Default Value",
                                        "type": "defaultValue",
                                        "enabled": false,
                                        "dynamic": false,
                                        "value": ""
                                    },
                                    "backgroundColor": {
                                        "displayName": "Background Color",
                                        "type": "color-picker",
                                        "value": ""
                                    }
                                },
                                "textFormat": {
                                    "props": [
                                        "class",
                                        "fontFamily",
                                        "fontSize",
                                        "bold",
                                        "italic",
                                        "underline",
                                        "color",
                                        "backgroundColor"
                                    ],
                                    "class": {
                                        "displayName": "Class",
                                        "value": ""
                                    },
                                    "fontFamily": {
                                        "displayName": "Font Family",
                                        "value": "Arial"
                                    },
                                    "fontSize": {
                                        "displayName": "Font Size",
                                        "value": 14
                                    },
                                    "bold": {
                                        "displayName": "Bold",
                                        "value": false
                                    },
                                    "italic": {
                                        "displayName": "Italic",
                                        "value": false
                                    },
                                    "underline": {
                                        "displayName": "Underline",
                                        "value": false
                                    },
                                    "color": {
                                        "displayName": "Font Color",
                                        "value": ""
                                    },
                                    "backgroundColor": {
                                        "displayName": "Background Color",
                                        "value": ""
                                    }
                                }
                            }
                        ]
                    },
                    "1721219431790": {
                        "type": "elements",
                        "elements": [
                            {
                                "effectStyleMap": {
                                    "list": [
                                        "color",
                                        "font-size"
                                    ],
                                    "color": {
                                        "id": "color",
                                        "name": "Color",
                                        "inputType": "color-palette"
                                    },
                                    "font-size": {
                                        "id": "font-size",
                                        "name": "Font Size",
                                        "inputType": "string"
                                    }
                                },
                                "id": "1721219431790",
                                "name": "separator_1",
                                "type": "separator",
                                "gridX": 12,
                                "minGridX": 3,
                                "config": {
                                    "props": []
                                }
                            }
                        ]
                    },
                    "1721219440098": {
                        "type": "elements",
                        "elements": [
                            {
                                "effectStyleMap": {
                                    "list": [
                                        "color",
                                        "font-size"
                                    ],
                                    "color": {
                                        "id": "color",
                                        "name": "Color",
                                        "inputType": "color-palette"
                                    },
                                    "font-size": {
                                        "id": "font-size",
                                        "name": "Font Size",
                                        "inputType": "string"
                                    }
                                },
                                "id": "1721219440098",
                                "name": "label_1",
                                "type": "label",
                                "gridX": 12,
                                "minGridX": 3,
                                "config": {
                                    "props": [
                                        "labelText",
                                        "prefixText",
                                        "alignment",
                                        "defaultValue",
                                        "tooltip",
                                        "hidden",
                                        "backgroundColor"
                                    ],
                                    "labelText": {
                                        "displayName": "Label Text",
                                        "value": "Preview your creation and finally publish. Your very own web app is ready to be shared to your users. ",
                                        "resetValue": "",
                                        "type": "longtext"
                                    },
                                    "prefixText": {
                                        "displayName": "Prefix Text",
                                        "value": "",
                                        "type": "text"
                                    },
                                    "alignment": {
                                        "displayName": "Text Alignment",
                                        "value": "flex-start",
                                        "resetValue": "flex-start",
                                        "type": "app-alignment-settings"
                                    },
                                    "tooltip": {
                                        "displayName": "Tooltip",
                                        "value": "",
                                        "type": "text",
                                        "enabled": true
                                    },
                                    "hidden": {
                                        "displayName": "Hidden",
                                        "type": "boolean",
                                        "value": false
                                    },
                                    "defaultValue": {
                                        "displayName": "Default Value",
                                        "type": "defaultValue",
                                        "enabled": false,
                                        "dynamic": false,
                                        "value": ""
                                    },
                                    "backgroundColor": {
                                        "displayName": "Background Color",
                                        "type": "color-picker",
                                        "value": ""
                                    }
                                },
                                "textFormat": {
                                    "props": [
                                        "class",
                                        "fontFamily",
                                        "fontSize",
                                        "bold",
                                        "italic",
                                        "underline",
                                        "color",
                                        "backgroundColor"
                                    ],
                                    "class": {
                                        "displayName": "Class",
                                        "value": ""
                                    },
                                    "fontFamily": {
                                        "displayName": "Font Family",
                                        "value": "Arial"
                                    },
                                    "fontSize": {
                                        "displayName": "Font Size",
                                        "value": 14
                                    },
                                    "bold": {
                                        "displayName": "Bold",
                                        "value": false
                                    },
                                    "italic": {
                                        "displayName": "Italic",
                                        "value": false
                                    },
                                    "underline": {
                                        "displayName": "Underline",
                                        "value": false
                                    },
                                    "color": {
                                        "displayName": "Font Color",
                                        "value": ""
                                    },
                                    "backgroundColor": {
                                        "displayName": "Background Color",
                                        "value": ""
                                    }
                                }
                            }
                        ]
                    }
                }
            },
            "background": {
                "color": "#fff",
                "image": {
                    "url": "",
                    "opacity": 50
                }
            },
            "margin": {
                "left": 0,
                "right": 0
            },
            "selectedNow": true
        }
      ],
      "created_at": created_at,
      "modified_at": modified_at
    }

    return defaultPageMeta
  }

  getSecondaryPageDefaultContent(title: string){
    let created_at = new Date().toISOString()
    let modified_at = new Date().toISOString()

    let defaultSecondaryPageMeta = {
        "_id": Date.now(),
        "code": "homepage",
        "name": "homepage",
        "panels": [
            {
                "type": "regular",
                "alignment": "flex-start",
                "layoutCount": "1",
                "widgets": [],
                "id": 1721646116946,
                "name": "panel_1",
                "layoutMap": {
                    "list": [
                        1721646116947
                    ],
                    "1721646116947": {
                        "gridX": 12,
                        "list": [
                            1721646117946,
                            1721646169493,
                            1721646585135
                        ],
                        "1721646117946": {
                            "type": "elements",
                            "elements": [
                                {
                                    "effectStyleMap": {
                                        "list": [
                                            "color",
                                            "font-size"
                                        ],
                                        "color": {
                                            "id": "color",
                                            "name": "Color",
                                            "inputType": "color-palette"
                                        },
                                        "font-size": {
                                            "id": "font-size",
                                            "name": "Font Size",
                                            "inputType": "string"
                                        }
                                    },
                                    "id": "1721646116946",
                                    "name": "label_1",
                                    "type": "label",
                                    "gridX": 12,
                                    "minGridX": 3,
                                    "config": {
                                        "props": [
                                            "labelText",
                                            "prefixText",
                                            "alignment",
                                            "defaultValue",
                                            "tooltip",
                                            "hidden",
                                            "backgroundColor"
                                        ],
                                        "labelText": {
                                            "displayName": "Label Text",
                                            "value": title,
                                            "resetValue": "",
                                            "type": "longtext"
                                        },
                                        "prefixText": {
                                            "displayName": "Prefix Text",
                                            "value": "",
                                            "type": "text"
                                        },
                                        "alignment": {
                                            "displayName": "Text Alignment",
                                            "value": "center",
                                            "resetValue": "flex-start",
                                            "type": "app-alignment-settings"
                                        },
                                        "tooltip": {
                                            "displayName": "Tooltip",
                                            "value": "",
                                            "type": "text",
                                            "enabled": true
                                        },
                                        "hidden": {
                                            "displayName": "Hidden",
                                            "type": "boolean",
                                            "value": false
                                        },
                                        "defaultValue": {
                                            "displayName": "Default Value",
                                            "type": "defaultValue",
                                            "enabled": false,
                                            "dynamic": false,
                                            "value": ""
                                        },
                                        "backgroundColor": {
                                            "displayName": "Background Color",
                                            "type": "color-picker",
                                            "value": ""
                                        }
                                    },
                                    "textFormat": {
                                        "props": [
                                            "class",
                                            "fontFamily",
                                            "fontSize",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "color",
                                            "backgroundColor"
                                        ],
                                        "class": {
                                            "displayName": "Class",
                                            "value": ""
                                        },
                                        "fontFamily": {
                                            "displayName": "Font Family",
                                            "value": "Arial"
                                        },
                                        "fontSize": {
                                            "displayName": "Font Size",
                                            "value": 32
                                        },
                                        "bold": {
                                            "displayName": "Bold",
                                            "value": true
                                        },
                                        "italic": {
                                            "displayName": "Italic",
                                            "value": false
                                        },
                                        "underline": {
                                            "displayName": "Underline",
                                            "value": false
                                        },
                                        "color": {
                                            "displayName": "Font Color",
                                            "value": ""
                                        },
                                        "backgroundColor": {
                                            "displayName": "Background Color",
                                            "value": ""
                                        }
                                    }
                                }
                            ]
                        },
                        "1721646169493": {
                            "type": "elements",
                            "elements": [
                                {
                                    "effectStyleMap": {
                                        "list": [
                                            "color",
                                            "font-size"
                                        ],
                                        "color": {
                                            "id": "color",
                                            "name": "Color",
                                            "inputType": "color-palette"
                                        },
                                        "font-size": {
                                            "id": "font-size",
                                            "name": "Font Size",
                                            "inputType": "string"
                                        }
                                    },
                                    "id": "1721646169493",
                                    "name": "label_1",
                                    "type": "label",
                                    "gridX": 12,
                                    "minGridX": 3,
                                    "config": {
                                        "props": [
                                            "labelText",
                                            "prefixText",
                                            "alignment",
                                            "defaultValue",
                                            "tooltip",
                                            "hidden",
                                            "backgroundColor"
                                        ],
                                        "labelText": {
                                            "displayName": "Label Text",
                                            "value": "Your fresh new page is ready to work. Keep going.",
                                            "resetValue": "",
                                            "type": "longtext"
                                        },
                                        "prefixText": {
                                            "displayName": "Prefix Text",
                                            "value": "",
                                            "type": "text"
                                        },
                                        "alignment": {
                                            "displayName": "Text Alignment",
                                            "value": "center",
                                            "resetValue": "flex-start",
                                            "type": "app-alignment-settings"
                                        },
                                        "tooltip": {
                                            "displayName": "Tooltip",
                                            "value": "",
                                            "type": "text",
                                            "enabled": true
                                        },
                                        "hidden": {
                                            "displayName": "Hidden",
                                            "type": "boolean",
                                            "value": false
                                        },
                                        "defaultValue": {
                                            "displayName": "Default Value",
                                            "type": "defaultValue",
                                            "enabled": false,
                                            "dynamic": false,
                                            "value": ""
                                        },
                                        "backgroundColor": {
                                            "displayName": "Background Color",
                                            "type": "color-picker",
                                            "value": ""
                                        }
                                    },
                                    "textFormat": {
                                        "props": [
                                            "class",
                                            "fontFamily",
                                            "fontSize",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "color",
                                            "backgroundColor"
                                        ],
                                        "class": {
                                            "displayName": "Class",
                                            "value": ""
                                        },
                                        "fontFamily": {
                                            "displayName": "Font Family",
                                            "value": "Arial"
                                        },
                                        "fontSize": {
                                            "displayName": "Font Size",
                                            "value": 14
                                        },
                                        "bold": {
                                            "displayName": "Bold",
                                            "value": false
                                        },
                                        "italic": {
                                            "displayName": "Italic",
                                            "value": false
                                        },
                                        "underline": {
                                            "displayName": "Underline",
                                            "value": false
                                        },
                                        "color": {
                                            "displayName": "Font Color",
                                            "value": ""
                                        },
                                        "backgroundColor": {
                                            "displayName": "Background Color",
                                            "value": ""
                                        }
                                    }
                                }
                            ]
                        },
                        "1721646585135": {
                            "type": "elements",
                            "elements": [
                                {
                                    "effectStyleMap": {
                                        "list": [
                                            "color",
                                            "font-size"
                                        ],
                                        "color": {
                                            "id": "color",
                                            "name": "Color",
                                            "inputType": "color-palette"
                                        },
                                        "font-size": {
                                            "id": "font-size",
                                            "name": "Font Size",
                                            "inputType": "string"
                                        }
                                    },
                                    "id": "1721646585134",
                                    "name": "separator_1",
                                    "type": "separator",
                                    "gridX": 12,
                                    "minGridX": 3,
                                    "config": {
                                        "props": []
                                    }
                                }
                            ]
                        }
                    }
                },
                "selectedNow": true
            },
            {
                "type": "regular",
                "alignment": "flex-start",
                "layoutCount": "1",
                "widgets": [],
                "id": 1721646305783,
                "name": "panel_2",
                "layoutMap": {
                    "list": [
                        1721646305784
                    ],
                    "1721646305784": {
                        "gridX": 12,
                        "list": [
                            1721646306783
                        ],
                        "1721646306783": {
                            "type": "elements",
                            "elements": [
                                {
                                    "effectStyleMap": {
                                        "list": [
                                            "color",
                                            "font-size"
                                        ],
                                        "color": {
                                            "id": "color",
                                            "name": "Color",
                                            "inputType": "color-palette"
                                        },
                                        "font-size": {
                                            "id": "font-size",
                                            "name": "Font Size",
                                            "inputType": "string"
                                        }
                                    },
                                    "id": "1721646305782",
                                    "name": "label_1",
                                    "type": "label",
                                    "gridX": 12,
                                    "minGridX": 3,
                                    "config": {
                                        "props": [
                                            "labelText",
                                            "prefixText",
                                            "alignment",
                                            "defaultValue",
                                            "tooltip",
                                            "hidden",
                                            "backgroundColor"
                                        ],
                                        "labelText": {
                                            "displayName": "Label Text",
                                            "value": "Check out our advanced widgets and panels from the sidebar on the right. You can create a chart, a table or a form. You can also drag your connections or starches to the canvas to visualize. ",
                                            "resetValue": "",
                                            "type": "longtext"
                                        },
                                        "prefixText": {
                                            "displayName": "Prefix Text",
                                            "value": "",
                                            "type": "text"
                                        },
                                        "alignment": {
                                            "displayName": "Text Alignment",
                                            "value": "flex-start",
                                            "resetValue": "flex-start",
                                            "type": "app-alignment-settings"
                                        },
                                        "tooltip": {
                                            "displayName": "Tooltip",
                                            "value": "",
                                            "type": "text",
                                            "enabled": true
                                        },
                                        "hidden": {
                                            "displayName": "Hidden",
                                            "type": "boolean",
                                            "value": false
                                        },
                                        "defaultValue": {
                                            "displayName": "Default Value",
                                            "type": "defaultValue",
                                            "enabled": false,
                                            "dynamic": false,
                                            "value": ""
                                        },
                                        "backgroundColor": {
                                            "displayName": "Background Color",
                                            "type": "color-picker",
                                            "value": ""
                                        }
                                    },
                                    "textFormat": {
                                        "props": [
                                            "class",
                                            "fontFamily",
                                            "fontSize",
                                            "bold",
                                            "italic",
                                            "underline",
                                            "color",
                                            "backgroundColor"
                                        ],
                                        "class": {
                                            "displayName": "Class",
                                            "value": ""
                                        },
                                        "fontFamily": {
                                            "displayName": "Font Family",
                                            "value": "Arial"
                                        },
                                        "fontSize": {
                                            "displayName": "Font Size",
                                            "value": 18
                                        },
                                        "bold": {
                                            "displayName": "Bold",
                                            "value": false
                                        },
                                        "italic": {
                                            "displayName": "Italic",
                                            "value": false
                                        },
                                        "underline": {
                                            "displayName": "Underline",
                                            "value": false
                                        },
                                        "color": {
                                            "displayName": "Font Color",
                                            "value": "#616161"
                                        },
                                        "backgroundColor": {
                                            "displayName": "Background Color",
                                            "value": ""
                                        }
                                    }
                                }
                            ]
                        }
                    }
                },
                "selectedNow": false
            }
        ],
      "created_at": created_at,
      "modified_at": modified_at
    }

    return defaultSecondaryPageMeta
  }
}
