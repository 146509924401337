import { Component, Input, OnInit, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges, AfterViewInit, ElementRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { FormControl, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.css'],
    standalone: false
})
export class TextareaComponent extends BaseWidgetComponent implements OnInit, OnChanges, AfterViewInit {

  contextMenuActions: any = {};

  hoveredNow: boolean = false;
  rowsValue: number = 2; // Default number of rows
  fc: FormControl = new FormControl({ value: '', disabled: true });

  @ViewChild('menuTrigger') inputMenuTrigger: MatMenuTrigger;
  @ViewChild('rowsMenuButton') rowsMenuButton: ElementRef;
  @ViewChild('input') input: ElementRef;
  destroy: any;
  validationSubscription: any

  constructor(
    public expressionUtility: ExpressionUtility,
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    public validationService: ValidationService
  ) {
    super(metaService, pageService, resourcePermissionService)
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if (contextActions && this.widgetMeta.id == contextActions?.widgetId) {
        this.action(contextActions);
      }
    });

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      // console.log("validation subscription", data, "widgetMeta", this.widgetMeta)
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        this.fc.markAsTouched()
      }
    })

    this.rowsValue = this.widgetMeta.config.rows.value;
    super.generateStyles()
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.widgetMeta?.config?.focus?.value) {
      this.input?.nativeElement?.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetMeta && changes.widgetMeta.currentValue) {
      this.widgetMeta = changes.widgetMeta.currentValue;
      if (this.widgetMeta.config?.expressionConfig?.id) {
        let value = this.expressionUtility.resolveExpression(this.widgetMeta.config?.expressionConfig);
        this.widgetMeta.config.value.value = value;
      }

      this.initForm()

      this.setContextActions();
    }

    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.inputMenuTrigger && this.inputMenuTrigger.menuOpen) {
        this.inputMenuTrigger.closeMenu();
      }
    }
    if (changes.contextActions?.currentValue) {
      this.action(changes.contextActions.currentValue);
    }
  }

  initForm(){
    if(this.widgetMeta.config?.required?.value){
      this.fc.addValidators(Validators.required);
    }
    if (!this.widgetMeta.config?.value?.value?.length) {
      this.widgetMeta.config.value.value = this.widgetMeta.config?.defaultValue?.value;
    }
    this.fc.patchValue(this.widgetMeta.config?.value?.value || '')
    this.fc.updateValueAndValidity()
    if (!this.widgetMeta.config.viewOnly?.value) {
      this.fc.enable()
    }

    this.emitUserInput();
  }

  setContextActions() {
    this.contextMenuActions = {
      actions: [
        "rows",
        "edit",
      ],
      appearance: {
        value: this.widgetMeta?.config.appearance.value,
        // availableTypes: this.widgetMeta?.config.appearance.availableTypes,
        type: this.widgetMeta?.config.appearance.type
      },
      rows: {
        value: this.widgetMeta.config.rows.value,
        // availableOptions: [1, 2, 3, 4], // Example available row options
        type: "number" // Assuming the type of the rows option is "number"
      }
    };
    if(this.widgetMeta.config.appearance){
      this.contextMenuActions.actions.unshift('appearance')
    }
    if(this.widgetMeta.textFormat){
      this.contextMenuActions.actions.unshift(...[
        "bold",
        "underline",
        "italic",
        "color",
        "fontSize",
        "fontFamily",
      ])
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions);
  }
  

  action(event: any) {
    if (event.actionType === "delete") {
      this.onDelete();
    } else if (event.actionType === "customPropertyUpdate") {
      if (event.propertyName === "appearance") {
        this.updateAppearance(event.data);
      } else if (event.propertyName === "rows") {
        this.updateRows(event.data);
      }
      this.generateStyles()
    } else if (event.actionType == "updateStyles") {
      if (event?.data) {
        this.widgetMeta = JSON.parse(JSON.stringify(event.data));
        this.newWidgetMeta.emit(this.widgetMeta)
        super.generateStyles();
      }
    }
  }
  


  onClick() {
    console.log("text area clicked")
    if (this.builderMode) {
      this.widgetSelection.emit(this.widgetMeta.id);
    }
  }

  onDelete() {
    this.widgetDeletion.emit(this.widgetMeta.id);
    this.inputMenuTrigger.closeMenu();
  }

  userInputDetected() {
    this.emitUserInput();
  }

  emitUserInput() {
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: this.widgetMeta.config?.value?.value,
      validity: this.isInputValid()
    }

    if (this.widgetMeta.hasOwnProperty('propName')) {
      userInput.propName = this.widgetMeta.propName;
    }
    this.userInputReceived.emit(userInput);
  }

  getTextInputAppearance(): string {
    return this.widgetMeta?.config?.appearance?.value;
  }

  updateAppearance(widgetMeta: any) {
    this.widgetMeta.config.appearance.value = widgetMeta.config.appearance.value;
    this.setContextActions();
  }
  updateRows(widgetMeta: any) {
    this.widgetMeta.config.rows.value = widgetMeta.config.rows.value;
    // this.emitUserInput();
    this.rowsValue = this.widgetMeta.config.rows.value; // Update the rowsValue property
    this.setContextActions(); // Update the context menu actions to reflect the new rows value
  }
  
  
  getFormFieldClass(): string {
    const appearanceValue = this.widgetMeta?.config?.appearance?.value;
    if (appearanceValue) {
      switch (appearanceValue) {
        case 'default':
          return 'default-appearance-class';
        case 'rounded':
          return 'rounded-appearance-class';
        case 'outlined':
          return 'outlined-appearance-class';
      }
    }
    return ''; // Default class when appearanceValue is undefined
  }

  isInputValid() {
    if (this.widgetMeta.config.required?.value) {
      if (this.widgetMeta.config.value.value !== undefined && this.widgetMeta.config.value.value !== null && this.widgetMeta.config.value.value !== '') {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }
  
}

