import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit, Output, EventEmitter, ElementRef, Input, Inject, PLATFORM_ID } from '@angular/core';
import { WidgetService } from '../../services/widget-service.service';
import { environment } from 'src/environments/environment';
import { PageService } from '../../services/page-service.service';
import { DeviceInfoService } from 'src/app/shared/services/device-info.service';
import { isPlatformBrowser } from '@angular/common';
import { MetaService } from '../../services/meta-service';

@Component({
    selector: 'app-builder-menu-tools',
    templateUrl: './builder-menu-tools.component.html',
    styleUrls: ['./builder-menu-tools.component.css'],
    standalone: false
})
export class BuilderMenuToolsComponent implements OnInit {

  data: any = [];
  boundEvent: any;
  starchPanelOpenState:boolean = false
  panelPanelOpenState:boolean = false;
  connectionPanelOpenState: boolean = false;
  sectionsVisibilityMap = {
    display: true,
    standard: true,
    dateTime: true,
    media: true,
    charts: true,
    connections: true,
    panels: true,
    starch: true
  }
  environment = environment

  @Input() sectionsToHide: any;
  isBrowser: boolean;

  constructor(
    private elementRef:ElementRef,
    public widgetService: WidgetService,
    public deviceInfo: DeviceInfoService,
    @Inject(PLATFORM_ID) platformId: Object,
    public pageService: PageService,
    public metaService: MetaService
  ){
      this.isBrowser = isPlatformBrowser(platformId);
      // if(!this.isBrowser) return;
    }

  ngOnInit(): void {
    console.log("sections to hide", this.sectionsToHide)
    if(this.sectionsToHide?.length){
      this.sectionsToHide.forEach(section => {
        this.sectionsVisibilityMap[section] = false
      })
    }
    console.log("sections vis map", this.sectionsVisibilityMap)
  }

  @Output() createPanel = new EventEmitter<string>();

  dropWidget(event: CdkDragDrop<string[]>, type) {
    // console.log("drop event", event, type)
    // this.widgetService.widgetDragNotifier(type)
    this.pageService.newWidgetDragEnd.next(event)
  }

  dragStarted(){
    console.log("drag started")
    this.pageService.widgetSelectedForDrag({ widgetId: "__NEW__" })

    // if currently active page has no panels (empty canvas/footer), we are creating an empty panel where dragged widget can be placed
    // panel component handles the drop event of a dragged widget, absence of panel component makes it not possible to handle the event
    if (this.pageService.pageMeta?.panels?.length == 0) {
      let panelId = Date.now() + Math.random().toString(16).slice(8);
      let layoutId = Date.now() + Math.random().toString(16).slice(8);
      let newEmptyPanel = {
        id: panelId,
        name: "Panel 1",
        type: "regular",
        layoutMap: {
          "list": [layoutId]
        }
      }
      newEmptyPanel.layoutMap[layoutId] = {
        "gridX": 12,
        "list": []
      }
      this.pageService.pageMeta.panels.push(newEmptyPanel)
      this.metaService.userMadeChanges.next(true);
    } else {

      // this will trigger page component to calculate panel boundary data and eventually set new panel placeholder
      this.pageService.calculatePanelBoundaryData.next({ pageId: this.pageService.pageMeta._id})
    }
  }

  dragMove(event){
    // event.stopPropagation()
    // console.log("dragMove fired", event)
    this.pageService.newWidgetDragMove.next(event)
  }

  // ----------methods----------
  newWidget(widgetName: string){
    console.log("widgetName", widgetName)
    this.createPanel.emit(widgetName)
  }
  newSearchPanel(){
    console.log("new search panel clicked")
    this.createPanel.emit("searchPanel")
  }
  onMouseDown(event){
    console.log("onMouseDown fired", event)
    this.boundEvent = this.elementRef.nativeElement.querySelector('#labelbutton').addEventListener('mousemove', (e)=>{
      console.log("mousemove detected", e)
    })

    this.elementRef.nativeElement.querySelector('#labelbutton').addEventListener('mouseup', ()=>{
      console.log("time to remove event listener")
    })

  }
}
