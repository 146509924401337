import { Subject } from "rxjs";
import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class ImageInput extends Widget {
  type = 'imageinput';
  gridX = 6
  minGridX = 3
  config = {
    props: [
      'tooltip',
      'multi'
    ],

    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    multi: {
      displayName: "Support Multiple",
      value: false,
      type: 'boolean',
      resetValue: false
    }
  }

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)

    this.config['value'].resetValue = '';
    this.config['placeholder'].value = 'Choose image';
    
    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))  
  }

  static imageInputEvent = new Subject<any>();
  static imageInputEvent$ = ImageInput.imageInputEvent.asObservable();

  public getEventSubscribe(data?:any){
    if(!data) return 1
    ImageInput.imageInputEvent.next(data);
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map)
    return map;
  }
}
