<div
  *ngIf="builderMode"
  class="outerCover"
  fxLayout="row" fxLayoutAlign="space-between center"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id ? false : hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <!-- (click)="onOuterClick($event)" -->
  <!-- <div style="font-size: .7rem; position: absolute; font-weight: bold;">{{ height }}</div> -->

  <!-- <widget-drag-handle *ngIf="widgetOptions.dragDrop !== false && !textEditMode" [class.invisible]="((selectedWidgetId !== widgetMeta.id && !hoveredNow) || textEditMode)"></widget-drag-handle> -->
  <widget-drag-handle *ngIf="widgetOptions?.dragDrop !== false" [class.invisible]="((selectedWidgetId !== widgetMeta.id && !hoveredNow))"></widget-drag-handle>

  <div class="innerCover" fxLayout="row" fxLayoutAlign="start center">
    <!-- <div
      #labelInput
      lang="en"
      (click)="onClick($event)"
      (dblclick)="onDoubleClick($event)"
      class="label-z-index content"
      [ngStyle]="styles"
      fxFlexFill
      style="display: flex; align-items: center"
      [style.justify-content]="widgetMeta?.config?.alignment?.value"
      (focusout)="onFocusOut($event)"
      contenteditable
      (input)="onInput($event)"
    >
      {{ widgetMeta?.config?.labelText?.value }}
    </div> -->

    <!-- <div style="font-size: 0.7rem;">{{ styles | json }}</div> -->

    <div
      *ngIf="!textEditMode"
      #labelInput
      lang="en"
      (click)="onClick($event)"
      class="label-z-index content"
      [ngStyle]="styles"
      fxFlexFill
      style="display: flex; align-items: center;"
      [style.justify-content]="widgetMeta?.config?.alignment?.value"
    >
      {{ widgetMeta.config?.value?.value }}
    </div>
    <!-- (dblclick)="onDoubleClick($event)" -->

    <div
      *ngIf="textEditMode && modeSwitchTransition"
      class="transition-layover label-z-index content"
      [ngStyle]="styles"
      fxFlexFill
      style="display: flex; align-items: center;"
      [style.justify-content]="widgetMeta?.config?.alignment?.value"
    >
      {{ widgetMeta.config?.value?.value }}
    </div>

    <textarea
      *ngIf="textEditMode"
      class="plain-input"
      [(ngModel)]="widgetMeta.config.value.value"
      #labelInput
      lang="en"
      (click)="onClick($event)"
      [ngStyle]="styles"
      fxFlexFill
      [style.opacity]="modeSwitchTransition ? 0.01 : 1"
      [style.justify-content]="widgetMeta?.config?.alignment?.value"
      (focusout)="onFocusOut($event)"
      (input)="onInput($event)"
    ></textarea>
      <!-- [style.min-height]="height + 'px !important' || 'auto'" -->

    <!-- [rows]="lines" -->


    <!-- [textContent]="widgetMeta?.config?.labelText?.value" -->
    <!-- [attr.contenteditable]="textEditMode ? 'plaintext-only' : 'false'" -->


    <!-- {{ widgetMeta.config.labelText.value }} -->
    <!-- (input)="onInput($event)" -->
    <!-- contenteditable="plaintext-only" -->


    <!-- <textarea
      *ngIf="textEditMode"
      [rows]="lines"
      lang="en"
      class="label-z-index content"
      [ngStyle]="styles"
      fxFlexFill
      style="display: flex; align-items: center"
      [style.justify-content]="widgetMeta?.config?.alignment?.value"
      (focusout)="onFocusOut($event)"
      [(ngModel)]="widgetMeta.config.labelText.value"
    ></textarea> -->
      <!-- (input)="onInput($event)" -->
    <!-- (keydown)="onKeyDown($event)" -->
    <!-- [textContent]="widgetMeta?.config?.labelText?.value" -->
    <!-- [attr.contenteditable]="textEditMode ? 'plaintext-only' : 'false'" -->
    <!-- [attr.contenteditable]="textEditMode ? 'plaintext-only' : 'false'" -->
    <!-- [textContent]="widgetMeta?.config?.labelText?.value" (input)="onInput($event)" -->
    <!-- [class.content]="textEditMode ? true : false" -->
    <!-- style="display: flex; align-items: center" -->
    <!-- (keydown)="onKeyDown($event)" -->
    <!-- (input)="textChanged($event, labelInput)"
    (focusout)="onFocusOut($event)" -->
    <!-- [attr.contenteditable]="textEditMode ? 'true' : 'false'" -->

    <!-- <mat-form-field *ngIf="textEditMode" class="input-layout" (mousedown)="$event.stopPropagation()" (focusout)="onFocusOut($event)">
      <textarea  [ngStyle]="styles" matInput type="text" [(ngModel)]="widgetMeta.config.labelText.value" #labelInput> </textarea>
    </mat-form-field> -->
  </div>

  <!-- <mat-icon
    *ngIf="!textEditMode && widgetOptions.textEdit !== false"
    (click)="turnOnEditMode()"
    [class.invisible]="(selectedWidgetId === widgetMeta.id || hoveredNow) ? false : true"
    class="editIcon"
  >
    edit
  </mat-icon> -->
</div>

<div
  *ngIf="!builderMode && (!widgetMeta?.config?.hidden || !widgetMeta?.config?.hidden?.value)"
  class="outerCover"
  fxLayout="row" fxLayoutAlign="space-between center"
  [style.background-color]="widgetMeta?.config.backgroundColor ? widgetMeta?.config.backgroundColor.value : 'transparent'"
  (click)="onClick($event)"
  [matTooltip]="widgetMeta?.config?.tooltip && widgetMeta?.config?.tooltip?.enabled ? widgetMeta?.config?.tooltip?.value : ''"
  [matTooltipDisabled]="!widgetMeta?.config?.tooltip || !widgetMeta?.config?.tooltip?.enabled"
>
  <div
    class="innerCover label-z-index content"
    [ngStyle]="styles" fxFlexFill style="display: flex; align-items: center; overflow-wrap: anywhere;"
    [style.justify-content]="widgetMeta?.config?.alignment?.value"
  >
  {{ widgetMeta?.config?.value?.value ? widgetMeta?.config?.prefixText?.value : '' }} {{ widgetMeta?.config?.value?.value }}
  </div>
</div>
