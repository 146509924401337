import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {CdkDragDrop,moveItemInArray,} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-attribute-selection',
    templateUrl: './attribute-selection.component.html',
    styleUrls: ['./attribute-selection.component.scss'],
    standalone: false
})
export class AttributeSelectionComponent implements OnInit {

  @Input() boxObjectAttributes: any[]
  @Input() listAttributes: any[]
  @Input() supportApplyButton: boolean
  @Input() allowReorder: boolean = true
  @Input() attributeDisplayName?: string;
  @Input() emitAtInit: boolean = true

  @Output() listAttributeSelected: EventEmitter<any> = new EventEmitter();

  filteredAttributes: any[] = []
  isSearching: boolean = false;

  listAttributesBackup: any[]
  boxObjectAttributesBackup: any[]

  constructor() { }

  ngOnInit(): void {
    // console.log("[ATTRIBUTE SELECTION] boxObjectAttributes", this.boxObjectAttributes)
    // console.log("[ATTRIBUTE SELECTION] listAttributes", this.listAttributes)
    // this.filteredAttributes = this.boxObjectAttributes

    this.listAttributesBackup = JSON.parse(JSON.stringify(this.listAttributes))
    this.boxObjectAttributesBackup = JSON.parse(JSON.stringify(this.boxObjectAttributes))

    this.init(this.boxObjectAttributes, this.listAttributes)
    if(this.emitAtInit) this.listAttributeSelected.emit(this.listAttributes)
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }

  ngOnChanges(changes: SimpleChanges): void {

    // if(changes.boxObjectAttributes?.firstChange) return
    // console.log("CHANGES", changes)
    let listAttributes = changes.listAttributes?.currentValue
    let boxAttributes = changes.boxObjectAttributes?.currentValue
    // changes.boxObjectAttributes?.currentValue?.length &&
    if(listAttributes?.length && boxAttributes?.length){
      this.init(boxAttributes, listAttributes)
      // console.log("new list assigned", JSON.parse(JSON.stringify(this.filteredAttributes)))
      // console.log("original list", JSON.parse(JSON.stringify(this.boxObjectAttributes)))
      // this.listAttributeSelected.emit(changes.listAttributes.currentValue)
    }
    // if(changes.listAttributes?.currentValue){
    //   console.log("listAttributeChanged", this.listAttributes)
    //   this.columnSelectionChanged()
    // }
  }

  /**
   * Initializes the filteredAttributes array from the boxObjectAttributes and listAttributes.
   * The isColumnSelected property is set to true if the attribute is in listAttributes and false otherwise.
   * @param boxAttributes
   * @param listAttributes
   */
  init(boxAttributes, listAttributes){
    // this.filteredAttributes = JSON.parse(JSON.stringify(boxAttributes))
    this.filteredAttributes = boxAttributes
    this.filteredAttributes.forEach((attr, i) => {
      // if(!attr.hasOwnProperty('isColumnSelected')) attr['isColumnSelected'] = true;
      let listAttr = listAttributes.find(a => a.__id == attr.__id)
      if(listAttr) {
        // console.log("attr", JSON.parse(JSON.stringify(attr)))
        // console.log("listAttr", JSON.parse(JSON.stringify(listAttr)))
        // console.log("isColumnSelected", listAttr.isColumnSelected)
        if (listAttr['isColumnSelected']) {
          attr = listAttr
          attr['isColumnSelected'] = true
        } else {
          attr['isColumnSelected'] = false;
        }
        this.filteredAttributes[i] = attr
      }
    })
    // console.log("filtered attributes", JSON.parse(JSON.stringify(this.filteredAttributes)))
  }

  emitSelectedColumns(){
    console.log("boxObjectAttributes", this.boxObjectAttributes)
    this.listAttributeSelected.emit(this.listAttributes)

    // reset the filteredAttributes
    // this.filteredAttributes = this.boxObjectAttributes
  }

  containsObject(id:any, list:any) {
    var i;
    let res = false;
    for (i = 0; i < list.length; i++) {
      if (list[i].__id === id) {
        res = true;
        break;
      }
    }
    // console.log("id", id, res)
    return res;
  }

  attributeSelected(event, attribute){
    // event.stopPropagation()
    console.log("[attribute selected] checked", event.checked, "attr", JSON.parse(JSON.stringify(attribute)))

    let i = this.boxObjectAttributes.findIndex(a => a.__id == attribute.__id)
    this.boxObjectAttributes[i].isColumnSelected = event.checked

    let indexInListAttributes = this.listAttributes.findIndex(a => a.__id == attribute.__id)
    if (event.checked && indexInListAttributes == -1) this.listAttributes.push(this.boxObjectAttributes[i])
    if (event.checked && indexInListAttributes > -1) this.listAttributes[indexInListAttributes].isColumnSelected = event.checked
    else if (!event.checked && indexInListAttributes > -1) this.listAttributes.splice(indexInListAttributes, 1)
    // this.listAttributes.forEach(listAttr => {
    //   if(listAttr.__id == attribute.__id) listAttr['isColumnSelected'] = event.checked
    // })
    // this.init(this.boxObjectAttributes, this.listAttributes)
    this.listAttributes = this.listAttributes.filter(attr => attr.isColumnSelected == true)
    this.filterAttributeList({srcElement: {value: ''}})

    // console.log("boxObjectAttributes finally", JSON.parse(JSON.stringify(this.boxObjectAttributes)))
    // console.log("listAttributes finally", JSON.parse(JSON.stringify(this.listAttributes)))
    // console.log("this.supportApplyButton", this.supportApplyButton)
    this.emitSelectedColumns()
  }

  toggleSelectAll(selectAll: boolean) {
    this.filteredAttributes.forEach(attr => {
      if (!attr.__id || attr.__id !== '__checkbox__') { // Ignore '__checkbox__' attributes
        attr.isColumnSelected = selectAll;
      }
    });
  
    // Sync with listAttributes
    if (selectAll) {
      this.listAttributes = [...this.filteredAttributes];
    } else {
      this.listAttributes = [];
    }
  
    this.emitSelectedColumns();
  }
  
  // Check if all are selected
  isAllSelected(): boolean {
    return this.filteredAttributes?.length > 0 && this.filteredAttributes.every(attr => attr.isColumnSelected);
  }  

  filterAttributeList(event){
    let txt = event.srcElement.value
    console.log("search text", txt)
    if (txt == '') {
      this.isSearching = false
      this.filteredAttributes = this.boxObjectAttributes
      this.filteredAttributes.forEach(attr => {
        if(this.containsObject(attr.__id, this.listAttributes)) {
          attr['isColumnSelected'] = true
        }
      })
    } else {
      this.isSearching = true
      this.filteredAttributes = []
      this.boxObjectAttributes.forEach(attr => {
        if (attr.__id.includes(txt) || attr.name.includes(txt)) {
          this.filteredAttributes.push(attr)
        }
      })
    }
    console.log("filtered list", JSON.parse(JSON.stringify(this.filteredAttributes)))
  }

  drop(event: CdkDragDrop<string[]>) {
    if(this.filteredAttributes.length != this.boxObjectAttributes.length) return
    moveItemInArray(this.boxObjectAttributes, event.previousIndex, event.currentIndex);

    // move the checkbox attribute to the first position
    const checkboxAttrIndex = this.boxObjectAttributes.findIndex((attr) => attr.__id === "__checkbox__")
    if (checkboxAttrIndex != -1) {
      const checkboxAttr = this.boxObjectAttributes.splice(checkboxAttrIndex, 1);
      this.boxObjectAttributes.unshift(checkboxAttr[0]);
    }
    this.filteredAttributes = this.boxObjectAttributes
    console.log("reordered", this.boxObjectAttributes)

    let reorderedSelectedList = this.boxObjectAttributes.filter(attr => attr.isColumnSelected == true)
    this.listAttributes = reorderedSelectedList
    console.log("reordered list attributes", JSON.parse(JSON.stringify(this.listAttributes)))
    if(!this.supportApplyButton) this.emitSelectedColumns()
  }

  revertChanges(): void {
    this.boxObjectAttributes = this.boxObjectAttributesBackup
    this.listAttributes = this.listAttributesBackup
    this.init(this.boxObjectAttributes, this.listAttributes)
    // this.emitSelectedColumns()
  }

}
