import { Widget } from "./baseWidget";
import { AvailableOptions } from './optionsConfig'
import { TextFormatting } from "./textFormat";

export class Chips extends Widget{
  type = 'chips';
  gridX = 12
  minGridX = 3

  config: any = {
    props: [
      'viewOnly',
      'label',
      'csvMode',
      'alignment',
      'availableOptions',
      'tooltip',
      // 'value'
    ],
    availableOptions: JSON.parse(JSON.stringify(AvailableOptions)),
    label: {
      displayName: "Label",
      value: '',
      type: 'text',
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, existing data will be shown but user can not add more chips',
      value: false,
      type: 'boolean',
      resetValue: false
    },
    csvMode: {
      displayName: 'CSV mode',
      description: 'If enabled, chips input can show csv type data, and it will emit user inputs in csv format',
      value: false,
      type: 'boolean',
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      type: 'app-alignment-settings'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
  };

  //text formatting properties
   textFormat;

  constructor(id: number, name: string){
    super(id, name)
    this.attachCommonConfig(this)

    this.config['title'].value = "Add chips"
    this.config['placeholder'].value = "Type and press Enter to add..."

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    return map;
  }

  /**
   * to indicate this widget is a selectable widget with options
   */
  public isOptionBasedWidget(){
    return true
  }

  public setOptions(values){
    console.log("[CHIPS] setOptions() this", this, "values", values)

    let array = [];
    if(Array.isArray(values)){
      values.forEach(e => {
        array.push({
          name: e,
          value: e,
        })
      })
    } else if(values.length && typeof values == 'string') {
      if(this.config.csvMode.value){
        values.split(',')?.forEach(val => {
          array.push({
            name: val,
            value: val,
          })
        })
      } else{
        let map = {
          name: values,
          value: values,
        };
        array = [map];
      }
    }
    this.config.availableOptions.staticOptions = array
    // console.log("chip widget injected", this.config)
    return array;
  }
}
