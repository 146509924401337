import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css'],
    standalone: false
})
export class CheckboxComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  textEditMode: boolean = false;
  hoveredNow: boolean = false;
  inputVisible: boolean = false;
  editingHeading: boolean = false;
  availableOptions: any[] = []
  private destroy:any = new Subject();

  isValid: boolean = false
  isTouched: boolean = false;
  oldValue: any;

  // fc: FormControl = new FormControl({ value: 0, disabled: true });
  validationSubscription: any
  // @ViewChild('menuTrigger') checkboxMenuTrigger: MatMenuTrigger

  @Output() raiseCheckboxChange: EventEmitter<any> = new EventEmitter()
  @Output() userInputReceived: EventEmitter<any> = new EventEmitter()

  constructor(
    private widgetUtilityService: WidgetUtilityService,
    public pageService: PageService,
    public metaService: MetaService,
    public resourcePermissionService: ResourcePermissionService,
    public validationService: ValidationService
  ) {
    super(metaService, pageService, resourcePermissionService)
  }

  ngOnInit(): void {
    // console.log("checkbox component ngOnInit: ", this.widgetMeta)
    super.ngOnInit()

    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })

    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      // console.log("validation subscription", data)
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        // this.fc.markAsTouched()
        // this.isValid = this.isInputValid()
        this.isTouched = true
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta && changes.widgetMeta.currentValue){
      console.log("calling from on changes", JSON.parse(JSON.stringify(changes.widgetMeta.currentValue)))
      this.generateAvailableOptions()
      this.setContextActions()
    }
  }

  ngDoCheck(): void {
    if(JSON.stringify(this.oldValue || "") !== JSON.stringify(this.widgetMeta?.config?.value?.value || "")){
      console.log("old", this.oldValue, "new", this.widgetMeta?.config?.value?.value, this.widgetMeta?.id)
      this.oldValue = this.widgetMeta?.config?.value?.value || ""
      this.generateAvailableOptions()
    }
  }

  initForm(){
    console.log("initForm hit", JSON.parse(JSON.stringify(this.widgetMeta || {})))

    /**
     * injected value can be array of strings or object: {0: string, 1: string, 2: string} ( if stored in db and retrieved, array becomes object with keys 0, 1, 2 ... )
     * both will be type object, so convert to array
     */
    if (typeof this.widgetMeta.config?.value?.value == 'object') {
      console.log("dealing array val", this.widgetMeta.config.value.value)
      this.widgetMeta.config.value.value = Object.values(this.widgetMeta.config.value.value) // convert object to array where keys are 0, 1, 2 ...

      this.convertValueToOptions(this.widgetMeta.config.value.value)

    } else if (this.widgetMeta.config.csvMode.value && typeof this.widgetMeta.config?.value?.value == 'string' && this.widgetMeta.config.value?.value?.length) {
      console.log("dealing string val", this.widgetMeta.config.value.value)
      let values = this.widgetMeta.config.value?.value?.split(',')

      this.convertValueToOptions(values)
    }

    if (!this.widgetMeta.config.value.value || !this.widgetMeta.config.value.value.length) this.handleSelectedValue()

    // this.handleSelectedValue()
    this.isValid = this.isInputValid()
    this.emitUserInput()
  }

  convertValueToOptions(values) {
    values.forEach((selectedValue: any) => {
      // if an option corresponding to currently handling string value already exists, mark it as selected
      // create an option otherwise for the value
      let index = this.availableOptions.findIndex((opt) => opt.value == selectedValue)
      if (index > -1) this.availableOptions[index].selected = true
      else {
        this.availableOptions.push({ name: selectedValue, value: selectedValue, selected: true })
      }
    })
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "bold",
        "underline",
        "italic",
        "alignment",
        "color",
        "backgroundColor",
        "fontSize",
        "fontFamily",
        "class",
        "edit",
      ]
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    // console.log("action is", JSON.parse(JSON.stringify(event)))
    // console.log("localMeta before", JSON.parse(JSON.stringify(this.widgetMeta)))

    switch (event.actionType) {
      case "delete":
        this.onDelete();
        break;
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = event.data;
          // console.log("localMeta changed", JSON.parse(JSON.stringify(this.widgetMeta)))
          this.newWidgetMeta.emit(this.widgetMeta)
          // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
          // console.log("after updateWidgetInPage", JSON.parse(JSON.stringify(this.widgetMeta)))

          super.generateStyles();
          console.log("action")
          this.generateAvailableOptions()
        }
        break;
      case "newOption":
        // console.log("need to add option", event.returnData)

        if(event.returnData && typeof event.returnData == 'string'){
          this.addItem(event.returnData)
          // this.newWidgetMeta.emit(this.widgetMeta)
          this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        }

      case 'settingsChanged':
        this.widgetMeta = event.data
        console.log("settings changed")
        this.generateAvailableOptions()
        // this.newWidgetMeta.next(this.widgetMeta)
        this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        break;
      default:
        break;
    }
  }

  checkboxChange(checked, item, index) {
    console.log("checked:", checked, "item:", item, "index:", index)
    this.availableOptions[index].selected = checked
    if (this.builderMode) return

    this.isTouched = true

    this.handleSelectedValue()
    this.emitUserInput()
  }

  handleSelectedValue(){
    let value: any = this.widgetMeta.config.csvMode.value ? "" : []; // string for csv, array otherwise
    this.availableOptions.filter(opt => opt.selected).forEach(option => {
      if(this.widgetMeta.config.csvMode?.value) {
        if (value && option.value) value = value + ","
        value = value + option.value
      } else {
        value.push(option.value)
      }
    })

    this.widgetMeta.config.value.value = value
    this.oldValue = value;
    this.isValid = this.isInputValid()
  }

  emitUserInput() {
    this.userInputReceived.emit({
      widgetId: this.widgetMeta.id,
      value: this.widgetMeta.config.value.value,
      validity: this.isInputValid()
    });

    console.log("emitting checkbox user input", this.widgetMeta.config.value.value)
  }

  /**
   * adds new static option
   * @param newOption is an object containing name[string], value[string] and default[boolean]
   */
  addItem(newOption: any) {
    // console.log("checkbox addItem hit with text:", newOption)

    let optionObject = {
      name: newOption.name,
      value: newOption.value,
      default: newOption.default
    }
    this.widgetMeta.config.availableOptions.staticOptions.push(optionObject)
    console.log("add item")
    this.generateAvailableOptions(true)
    // this.newWidgetMeta.next(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  removeOption(i: number){
    this.widgetMeta.config.availableOptions.staticOptions.splice(i, 1)
    console.log("option removed")
    this.generateAvailableOptions(true)
    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  /**
   * changes the header text
   * @param newHeaderRef reference to the input field that will hold the new header text
   */
  saveNewHeading(newHeaderRef: any) {
    if (newHeaderRef.value == '') {
      console.log("emoty text")
      //show snackbar
      return
    }
    console.log("new title will be saved", newHeaderRef.value)
    this.widgetMeta.config.title.value = newHeaderRef.value

    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  /**
   * selects the widget, will also fire context menu(mat-menu)
   * @param event
   */
  onClick(event: any) {
    // console.log("checkbox clicked", this.widgetMeta.id)
    // console.log("icon clicked", this.widgetMeta.id)
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
  }

  async generateAvailableOptions(noRefetch: boolean = false){
    // console.log("generate available options hit", noRefetch, JSON.parse(JSON.stringify(this.widgetMeta)))
    let staticOptions: any[] = this.widgetMeta.config.availableOptions.staticOptions || []

    let rawDynamicOptions: any[] = []
    let dynamicOptionItems: any[] = []
    if(noRefetch){
      dynamicOptionItems = this.availableOptions.filter(opt => opt.type == 'dynamic')
      console.log("preserved dynamic options", dynamicOptionItems)
    }else{
      if(this.widgetMeta.config.availableOptions.dynamicOptions.enabled){
        rawDynamicOptions = await this.widgetUtilityService.fetchDynamicOptions(this.widgetMeta, this.builderMode)
        dynamicOptionItems = this.widgetUtilityService.processDynamicOptions(rawDynamicOptions, this.widgetMeta)
      }
    }

    if(this.builderMode){
      console.log("static options", JSON.parse(JSON.stringify(staticOptions)))
      staticOptions.map(opt => opt['type'] = 'static')
      dynamicOptionItems.map(opt => opt['type'] = 'dynamic')
    }

    this.availableOptions = []
    this.availableOptions = this.availableOptions.concat(staticOptions)
    this.availableOptions = this.availableOptions.concat(dynamicOptionItems)
    this.availableOptions.forEach(opt => opt['selected'] = opt['default'])

    this.initForm()

    console.log("options", this.availableOptions)
  }

  isInputValid() {
    if (this.widgetMeta.config.required?.value) {
      if (this.widgetMeta.config.csvMode.value) {
        if (this.widgetMeta.config.value.value && typeof this.widgetMeta.config.value.value == 'string' && this.widgetMeta.config.value.value.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return (Array.isArray(this.widgetMeta.config.value.value) && this.widgetMeta.config.value.value.length) ? true : false
      }
    } else {
      return true
    }
  }
  trackByFn(index:number, item:any):any{
    return item || index
  }
}
