import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { WIDGET_OPTIONS } from '../../../services/widget-utility.service'
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';

@Component({
    selector: 'app-base-widget',
    templateUrl: './base-widget.component.html',
    styleUrls: ['./base-widget.component.css'],
    standalone: false
})
export class BaseWidgetComponent implements OnInit, OnChanges {

  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄► VARIABLE DECLARATION  ◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►
  @Input() widgetMeta: any;
  @Input() pageMeta: any;
  @Input() builderMode: any
  @Input() panelId: any;
  @Input() selectedWidgetId: any;
  @Input() widgetOptions: WIDGET_OPTIONS;
  @Input() contextActions: any
  @Input() isDisabled: boolean

  @Output() raiseContextMenuActions = new EventEmitter<any>();
  @Output() newWidgetMeta = new EventEmitter<any>();
  @Output() widgetDeletion = new EventEmitter<any>();
  @Output() widgetSelection = new EventEmitter<any>();
  @Output() widgetHover = new EventEmitter<any>();
  @Output() userInputReceived: EventEmitter<any> = new EventEmitter();

  hoveredNow: boolean = false;
  styles: any;

  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►  CONSTRUCTOR  ◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄
  constructor(
    public metaService?: MetaService,
    public localPageService?: PageService,
    public resourcePermissionService?: ResourcePermissionService,
    public widgetService?: WidgetService
    ) {
    // console.log("base widget constructor", this.widgetMeta)
    if(!this.widgetOptions) this.widgetOptions = {}
    // console.log("widget options receuved", this.widgetOptions)
  }

  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►   ON INIT   ◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►
  ngOnInit(): void {
    // console.log("[BASE WIDGET] onInit: widgetMeta", this.widgetMeta)
    this.generateStyles()
  }

  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►   ON CHANGES   ◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►
  ngOnChanges(changes: SimpleChanges): void {
    // console.log("[BASE WIDGET] onChanges: changes", changes)
    // if(changes.widgetMeta && changes.widgetMeta.currentValue){
    //   if(changes.widgetMeta.currentValue.type == 'input'){
    //     // console.log("meta changed in base widget", changes.widgetMeta.currentValue.config.textContent.value)
    //   }
    // }
  }

  checkAccess(){
    // return {
    //   show: true,
    //   hide: false
    // }

    // console.log("widgetMeta", this.widgetMeta)
    // console.log("metaService publishedMode", this.metaService.publishedMode);
    if(!this.metaService?.publishedMode || !this.widgetMeta?.securityConfig?.securities || this.widgetMeta.securityConfig.securities.length == 0) {
      return {
        show: true,
        hide: false
      }
    }
    let access = this.resourcePermissionService.checkAccess(this.widgetMeta.securityConfig);
    // console.log("access", access);
    return access;
  }

  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►  METHODS  ◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►
  generateStyles() {
    // console.log("generate styles hit----- widgetMeta", this.widgetMeta)
    // console.log("textFormat", this.widgetMeta.textFormat?.props)
    this.styles = {}
    if (this.widgetMeta?.textFormat?.props?.length) {
      if(this.widgetMeta.textFormat.props.find(p => p == 'bold')) this.styles.fontWeight = this.widgetMeta.textFormat.bold?.value ? 'bold' : 'normal'
      if(this.widgetMeta.textFormat.props.find(p => p == 'underline')) this.styles.textDecoration = this.widgetMeta.textFormat.underline?.value ? 'underline' : 'none'
      if(this.widgetMeta.textFormat.props.find(p => p == 'italic')) this.styles.fontStyle = this.widgetMeta.textFormat.italic?.value ? 'italic' : 'normal'
      if(this.widgetMeta.textFormat.props.find(p => p == 'fontSize')) this.styles.fontSize = this.widgetMeta.textFormat.fontSize?.value + 'px'
      if(this.widgetMeta.textFormat.props.find(p => p == 'fontFamily')) this.styles.fontFamily = this.widgetMeta.textFormat.fontFamily?.value
      if(this.widgetMeta.textFormat.props.find(p => p == 'color')) this.styles.color = this.widgetMeta.textFormat.color?.value
      // this.styles.color = 'black'
    }
    //set button color in styles if color picker used.
    if (this.widgetMeta?.config?.buttonColorType?.customValue?.startsWith('#')) {
      this.styles.backgroundColor = this.widgetMeta.config.buttonColorType.customValue;
    }
    // console.log("styles generated", this.styles)
  }

  public widgetMouseenter() {
    // console.log("inside widgetMouseEnter")
    if (!this.builderMode || this.localPageService.isDragging() || this.localPageService.panelReorderDragging) {
      return
    }
    this.hoveredNow = true
    // console.log("will emit widget hover")
    this.widgetHover.emit(this.widgetMeta.id)
    // console.log("mouse entered in", this.widgetMeta.type, this.widgetMeta.id)
  }

  public widgetMouseleave() {
    if (!this.builderMode) {
      return
    }
    // console.log("will emit widget unhover")
    this.widgetHover.emit(-1)
    this.hoveredNow = false;
    // console.log("mouse left from", this.widgetMeta.type, this.widgetMeta.id)
  }

}
  //◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►◄►
