import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { FormControl, Validators } from '@angular/forms';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';


@Component({
    selector: 'app-numberinput',
    templateUrl: './numberinput.component.html',
    styleUrls: ['./numberinput.component.css'],
    standalone: false
})
export class NumberinputComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any = {};

  // value = "";
  hoveredNow: boolean = false;
  // private destroy:any = new Subject();
  validationSubscription: Subscription;
  fc: FormControl = new FormControl({ value: '', disabled: true });

  // @ViewChild('menuTrigger') numberInputMenuTrigger: MatMenuTrigger;
  // @ViewChild('input') input: ElementRef;
  destroy: any;
  oldValue: any;
  customErrorMessage: any;
  widgetValidationObj: any;

  constructor(
    public metaService: MetaService,
    public pageService: PageService,
    public validationService: ValidationService,
    public resourcePermissionService: ResourcePermissionService,
    public expressionUtility: ExpressionUtility
  ) {
    super(metaService, pageService, resourcePermissionService)
    console.log("in text input constructor", this.widgetMeta);
  }

  ngOnInit(): void {

    super.ngOnInit()
    console.log("in text input onInit", this.widgetMeta)
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    });
  
    this.validationSubscription = this.validationService.$validationFeedback.subscribe(data => {
      // console.log("validation subscription", data)
      if(data.widgetId !== this.widgetMeta.id) return
      if(data.status == false) {
        this.fc.markAsTouched()
      }
    })
    super.generateStyles();
    if (this.isDisabled) {
      this.fc.disable();  // Disable the form control
    } else {
      this.fc.enable();   // Enable the form control
    }
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgetMeta && changes.widgetMeta.currentValue) {
      this.widgetMeta = changes.widgetMeta.currentValue
      this.initForm()
      this.emitUserInput()
      this.setContextActions()
    }
  }

  initForm(){
    if(this.widgetMeta.config?.required?.value){
      this.fc.addValidators(Validators.required);
    }
    if ((this.widgetMeta.config?.value?.value == '' || this.widgetMeta.config?.value?.value == undefined || this.widgetMeta.config?.value?.value == null) && typeof Number(this.widgetMeta.config?.defaultValue?.value) == 'number' && this.widgetMeta.config?.defaultValue?.value) {
      this.widgetMeta.config.value.value = Number(this.widgetMeta.config?.defaultValue?.value);
    }
    this.fc.patchValue(this.widgetMeta.config?.value?.value)
    this.fc.updateValueAndValidity()
    if (!this.widgetMeta.config.viewOnly.value) {
      this.fc.enable()
    }
  }

  ngDoCheck(): void {
    if(this.oldValue != this.widgetMeta?.config?.value?.value){
      this.oldValue = this.widgetMeta?.config?.value?.value || ""
      // console.log("SELECT doCheck", this.oldValue)
      this.initForm()
      // console.log("calling from doCheck", this.oldValue)
      setTimeout(() => {
        this.userInputDetected()
      }, 200);
    }
  }

  //----------------------------------- FUNCTIONS ---------------------------------------

  setContextActions() {
    this.contextMenuActions = {
      actions: [
        "appearance",
        "edit",
      ],
      appearance: {
        value: this.widgetMeta?.config?.appearance?.value,
        // availableTypes: this.widgetMeta?.config.appearance.availableTypes,
        type: this.widgetMeta?.config?.appearance?.type
      },
    };
    if(this.widgetMeta.textFormat){
      this.contextMenuActions.actions.unshift(...[
        "bold",
        "underline",
        "italic",
        "color",
        "backgroundColor",
        "fontSize",
        "fontFamily",
      ])
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions);
  }

  action(event: any) {
    console.log("action received", event);
    switch (event.actionType) {
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = JSON.parse(JSON.stringify(event.data));
          console.log("localMeta changed", this.widgetMeta)
          this.newWidgetMeta.emit(this.widgetMeta)
          super.generateStyles();
        }
        break;
      // case "bold":
      //   break;
      // case "italic":
      //   break;
      // case "underline":
      //   break;
      // case "fontSize":
      //   break;
      // case "fontFamily":

      //   break;
      // case "color":
      //     break;
      case "delete":
        this.onDelete();
        break;
    }
  }

  onClick() {
    if (!this.builderMode) {
      return;
    }

    console.log("textInput widget clicked in widget")
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    // this.numberInputMenuTrigger?.closeMenu();
  }

  userInputDetected() {
    this.widgetValidationObj = this.validationService.checkWidgetValidation(this.widgetMeta)
    if(this.widgetValidationObj){
      if (!this.widgetValidationObj?.isValid) {
        this.fc.setErrors({ invalid: true }); // Mark the form control as invalid
        this.fc.setErrors({ valid: false }); // Mark the form control as invalid
        this.customErrorMessage = this.widgetValidationObj?.customErrorMessage ? this.widgetValidationObj.customErrorMessage : "";
      } else {
        this.fc.setErrors(null); // Clear any existing errors, marking it as valid
      }
    }
    setTimeout(() => {
      this.emitUserInput();
    }, 200);
  }

  emitUserInput(event?: any) {
    console.log("input change detected", this.widgetMeta.config.value.value)
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      // value: String(this.widgetMeta.config.value.value)
      // value: String(this.widgetMeta.config.value.value)+'|number'
      value: this.widgetMeta.config.value.value,
      validity: this.isInputValid()
    }

    //this only applies when using textInput Component for widget configuration
    if (this.widgetMeta.hasOwnProperty('propName')) {
      userInput.propName = this.widgetMeta.propName
    }

    if (this.widgetMeta.config.value.value !== '') {
      this.userInputReceived.emit(userInput)
    }

    if(this.builderMode){
      this.newWidgetMeta.emit(this.widgetMeta)
    }
  }

  isInputValid() {
    if (this.widgetMeta.config.required?.value) {
      if (typeof this.widgetMeta.config.value.value == 'number') {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

}
