<div
  *ngIf="builderMode"
  fxLayout
  #cover
  class="outerCover"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover" *ngIf="widgetOptions?.hideChartIfNoData ? (isChartDataLoaded && records?.length) : true" style="margin: 0" (click)="onClick($event)" (mousedown)="$event.stopPropagation()">
    <div class="chartSecondInnerCover">
      <div class="chartActionBar" *ngIf="widgetMeta.config.dataSource.dataSourceType != 'static'" fxLayoutAlign="flex-end center" (click)="$event.stopPropagation();">
        <div *ngIf="dataLoadLock" fxLayoutAlign="center center"
        matTooltip="Force stop further data load" (click)="$event.stopPropagation(); stopIterativeDataLoad();"
        style="height: 2rem; width: 2rem;">
        <mat-spinner diameter="20" color="primary"></mat-spinner></div>
        <!-- <button *ngIf="isChartDataLoaded && dataLoadLock" fxLayoutAlign="center center" mat-icon-button matTooltip="Force stop further data load" (click)="$event.stopPropagation(); stopIterativeDataLoad();">
          <mat-icon fxLayoutAlign="center center">search_off</mat-icon>
        </button> -->
        <!-- FIlter -->
        <div *ngIf="widgetMeta?.config?.dataSource?.filter?.userFilterEnabled">
          <!-- style="height: 2rem; align-items: center;" -->
          <mat-button-toggle-group>
            <mat-button-toggle class="actionToggleButton" matTooltip="Filter" [checked]="false">
              <mat-icon class="listActionIcon" #filterMenu="matMenuTrigger" [matMenuTriggerFor]="menu1">filter_alt</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <mat-menu #menu1="matMenu" xPosition="before">
          <div (click)="$event.stopPropagation()" style="min-width: 25rem; max-width: 50rem" ngStyle.lt-md="{'min-width: '20rem', 'max-width': '40rem'}">
            <app-filter-component
            [options]="{isExistingFiltersReadOnly: isExistingFiltersReadOnly}"
            [filter]="{filterEnabled: true, filterItems: widgetMeta.config.dataSource.filter.filterItems}"
            [boxObjectAttributes]="widgetMeta?.config?.dataSource?.boxObjectAttributes || []"
            [enableSecurity]="false"
            [supportApplyButton]="true"
            [hideToggle]="true"
            [lockFilterType]="true"
            (selectedFilter)="applyUserFilters($event)"
            (cancelFilter)="cancelFilter()"
            >
            </app-filter-component>
          </div>
        </mat-menu>
        <button *ngIf="isChartDataLoaded && !dataLoadLock" fxLayoutAlign="center center" mat-icon-button matTooltip="Refresh chart data" (click)="$event.stopPropagation(); refreshChartData();">
          <mat-icon fxLayoutAlign="center center">refresh</mat-icon>
        </button>
      </div>

      <div [ngSwitch]="widgetMeta.config.chartType.value">
        <div *ngSwitchCase="'pie'" style="height: 100%;">
          <app-pie-chart
            [widgetMeta]="widgetMeta"
            [chartDimensions]="chartDimensions"
            [builderMode]="builderMode"
            [records]="records"
          ></app-pie-chart>
        </div>
        <div *ngSwitchCase="'bar'">
          <app-bar-chart
            [widgetMeta]="widgetMeta"
            [chartDimensions]="chartDimensions"
            [builderMode]="builderMode"
            [records]="records"
          ></app-bar-chart>
        </div>
        <div *ngSwitchCase="'line'">
          <app-line-chart
            [widgetMeta]="widgetMeta"
            [chartDimensions]="chartDimensions"
            [builderMode]="builderMode"
            [records]="records"
          ></app-line-chart>
        </div>
        <div *ngSwitchCase="'gauge'">
          <app-gauge-chart
            [widgetMeta]="widgetMeta"
            [chartDimensions]="chartDimensions"
            [builderMode]="builderMode"
            [records]="records"
          ></app-gauge-chart>
        </div>
        <div *ngSwitchCase="'scorecard'">
          <app-score-card
            [widgetMeta]="widgetMeta"
            [chartDimensions]="chartDimensions"
            [records]="records"
            [builderMode]="builderMode"
            [styles]="styles"
          ></app-score-card>
        </div>
        <div *ngSwitchDefault style="color: red">Chart type not found</div>
      </div>

    </div>
    <!-- <div style="height: 1rem;"></div> -->
  </div>
</div>

<div
  (mouseenter)="widgetMeta.config.mousein = true"
  (mouseleave)="widgetMeta.config.mousein = false"
  *ngIf="!builderMode" fxLayout fxLayoutAlign="flex-start center" #cover class="outerCover" (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover" *ngIf="widgetOptions?.hideChartIfNoData ? (isChartDataLoaded && records?.length) : true" (click)="onClick($event)" (mousedown)="$event.stopPropagation()">
    <div
    >
      <div class="chartActionBar"  *ngIf="widgetMeta.config.dataSource.dataSourceType != 'static'" fxLayoutAlign="flex-end center" (click)="$event.stopPropagation();">
      <!-- <div class="chartActionBar"  *ngIf="widgetMeta.config.mousein && widgetMeta.config.dataSource.dataSourceType != 'static'" fxLayoutAlign="flex-end center" (click)="$event.stopPropagation();"> -->
        <div *ngIf="dataLoadLock" fxLayoutAlign="center center"
        matTooltip="Force stop further data load" (click)="$event.stopPropagation(); stopIterativeDataLoad();"
        style="height: 2rem; width: 2rem;">
          <mat-spinner diameter="20" color="primary"></mat-spinner></div>
        <!-- <spinner *ngIf="dataLoadLock"></spinner> -->
        <!-- <button *ngIf="isChartDataLoaded && dataLoadLock" fxLayoutAlign="center center" mat-icon-button matTooltip="Force stop further data load" (click)="$event.stopPropagation(); stopIterativeDataLoad();">
          <mat-icon fxLayoutAlign="center center">search_off</mat-icon>
        </button> -->
        <!-- FIlter -->
        <div *ngIf="widgetMeta?.config?.dataSource?.filter?.userFilterEnabled">
          <mat-button-toggle-group>
            <mat-button-toggle class="actionToggleButton" matTooltip="Filter" [checked]="false" #filterMenu="matMenuTrigger" [matMenuTriggerFor]="menu2">
              <mat-icon class="listActionIcon">filter_alt</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        
        <mat-menu #menu2="matMenu" xPosition="before">
          <div (click)="$event.stopPropagation()" style="min-width: 25rem; max-width: 50rem">
            <app-filter-component
              [options]="{ isExistingFiltersReadOnly: isExistingFiltersReadOnly }"
              [filter]="{ filterEnabled: true, filterItems: widgetMeta.config.dataSource.filter.filterItems }"
              [boxObjectAttributes]="widgetMeta?.config?.dataSource?.boxObjectAttributes || []"
              [enableSecurity]="false"
              [supportApplyButton]="true"
              [hideToggle]="true"
              [lockFilterType]="true"
              (selectedFilter)="applyUserFilters($event)"
              (cancelFilter)="cancelFilter()"
            ></app-filter-component>
          </div>
        </mat-menu>
        <button *ngIf="isChartDataLoaded && !dataLoadLock" fxLayoutAlign="center center" mat-icon-button matTooltip="Refresh chart data" (click)="$event.stopPropagation(); refreshChartData();">
          <mat-icon fxLayoutAlign="center center">refresh</mat-icon>
        </button>
      </div>
      <div [ngSwitch]="widgetMeta.config.chartType.value">
        <div *ngSwitchCase="'pie'">
          <app-pie-chart
          [widgetMeta]="widgetMeta"
          [chartDimensions]="chartDimensions"
          [records]="records"
          [builderMode]="builderMode"
          ></app-pie-chart>
        </div>
        <div *ngSwitchCase="'bar'">
          <app-bar-chart
          [widgetMeta]="widgetMeta"
          [chartDimensions]="chartDimensions"
          [records]="records"
          [builderMode]="builderMode"
          ></app-bar-chart>
        </div>
        <div *ngSwitchCase="'line'">
          <app-line-chart
          [widgetMeta]="widgetMeta"
          [chartDimensions]="chartDimensions"
          [records]="records"
          [builderMode]="builderMode"
          ></app-line-chart>
        </div>
        <div *ngSwitchCase="'gauge'">
          <app-gauge-chart
          [widgetMeta]="widgetMeta"
          [chartDimensions]="chartDimensions"
          [records]="records"
          [builderMode]="builderMode"
          ></app-gauge-chart>
        </div>
        <div *ngSwitchCase="'scorecard'">
          <app-score-card
          [widgetMeta]="widgetMeta"
          [chartDimensions]="chartDimensions"
          [records]="records"
          [builderMode]="builderMode"
          [styles]="styles"
          ></app-score-card>
        </div>
        <div *ngSwitchDefault style="color: red">Chart type not found</div>
      </div>

    </div>
    <div style="height: 1rem;"></div>
  </div>
</div>
