import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { MetaService } from '../../services/meta-service';
import { WidgetManager } from '../../models/WidgetManager';
import { WidgetService } from '../../services/widget-service.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'publish-config-dialog',
    templateUrl: './publish-config.component.html',
    styleUrls: ['./publish-config.component.css'],
    standalone: false
})
export class PublishConfigComponent implements OnInit, OnDestroy {

  disabled: boolean = false
  templatePublishChecked: boolean = false
  // templatePublishDisabled: boolean = false
  bloomMeta: any
  newVersion: string = ''
  publishSpinner: boolean = false
  releaseType: string = 'major'
  releaseNote: string = ''
  publishDisabled: boolean = false
  bloomMetaBackup: any
  pageStructure: any
  pageStrSub: any
  oldPublishedUrl: string= ''
  publishedUrl: string
  publishSuccess: boolean = false
  isPublishedUrl: boolean = false
  currentBaseUrl: string
  pageMap: any = {};

  lastPublishedTemplate: string
  isBrowser: any;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PublishConfigComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private metaService: MetaService,
    private authService: AuthServiceService,
    private connectionService: ConnectionService,
    private widgetService: WidgetService,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(!this.isBrowser) return;
  }

  ngOnInit(): void {
    if(!this.isBrowser) return;
    this.currentBaseUrl = window.location.protocol + '//' + window.location.hostname
    this.currentBaseUrl = window.location.port ? this.currentBaseUrl + ":" + window.location.port : this.currentBaseUrl
    console.log("----------> current url", this.currentBaseUrl)

    this.bloomMeta = JSON.parse(JSON.stringify(this.data.bloomMeta))
    this.bloomMetaBackup = JSON.parse(JSON.stringify(this.bloomMeta))
    console.log("bloomMeta", this.bloomMeta)

    // for backward compatibility
    if(this.bloomMeta.templateData && !this.bloomMeta.template_data){
      console.log("templateData exists but not template_data")
      this.bloomMeta['template_data'] = JSON.parse(JSON.stringify(this.bloomMeta.templateData))
      delete this.bloomMeta.templateData
    } else if (this.bloomMeta.templateData && this.bloomMeta.template_data) {
      console.log("templateData exists and template_data exists")
      delete this.bloomMeta.templateData
    }

    if (this.bloomMeta.hasOwnProperty('templateStatus') && !this.bloomMeta.hasOwnProperty('template_status')) {
      this.bloomMeta['template_status'] = this.bloomMeta.templateStatus
      delete this.bloomMeta.templateStatus
    }

    // this.templatePublishChecked = this.bloomMeta.template ? this.bloomMeta.template : false

    if(this.bloomMeta.publishedUrl){
      this.oldPublishedUrl = this.bloomMeta.publishedUrl
      this.isPublishedUrl = true
    }

    // if there is no version, make it draft
    if(!this.bloomMeta.version){
      this.bloomMeta['version'] = 'draft'
    }

    // get new version if this bloom gets published
    this.newVersion = this.getNewVersion(this.bloomMeta.latestVersion || '', 'major')

    // page structure subscription
    this.pageStrSub = this.metaService.get_page_structure.subscribe(async pageStructure => {
      console.log("page structure received in publish comfig", pageStructure)
      this.pageStructure = pageStructure;

      this.pageMap = {}
      this.pageStructure.pages.forEach(async key => {
        let page = await this.metaService.get(this.pageStructure[key].id);
        this.pageMap[key] = page;
        console.log("this.pages", this.pageMap)
      })
    })

    // console.log("profile", this.authService.profile)
    // console.log("user profile", this.authService.userProfile)
  }

  ngOnDestroy(): void {
      this.pageStrSub.unsubscribe()
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  releaseTypeChanged(event){
    console.log("release Type selected", event.value, "on base version", this.bloomMeta.version)
    this.newVersion = this.getNewVersion(this.bloomMeta.latestVersion, event.value)
  }

  getNewVersion(oldVersion: string, releaseType: string){
    // this is for first publish
    if(!oldVersion || oldVersion == 'draft') return '1.0.0'

    // if already a published version exists
    let versionParts: any[] = oldVersion.split('.')
    if(releaseType == 'major'){
      versionParts[0] = parseInt(versionParts[0]) + 1
      versionParts[1] = '0'
      versionParts[2] = '0'
    }else if(releaseType == 'minor'){
      versionParts[1] = parseInt(versionParts[1]) + 1
      versionParts[2] = '0'
    }else if(releaseType == 'patch'){
      versionParts[2] = parseInt(versionParts[2]) + 1
    }
    return versionParts.join('.')
  }

  templatePublishCheckedEvent(event){
    console.log("templatePublishCheckedEvent", event)
    this.bloomMeta.template = event.checked
    this.templatePublishChecked = event.checked
    // if(this.bloomMeta.template){
    //   this.publishDisabled = true
    // }else{
    //   this.publishDisabled = false
    // }
  }

  templateDataReceived(data){
    console.log("template data received, data:", data)
    if(data !== null){
      console.log("template data", data)
      this.bloomMeta['template'] = true
      this.bloomMeta['template_data'] = data
    }
    console.log("bloomMeta now", this.bloomMeta)
    // else{
    //   this.bloomMeta['template'] = false
    // }
    // this.publishDisabled = false
  }

  async publishClicked(){
    this.publishSuccess = false
    console.log("publish clicked")

    this.disabled = true
    this.publishDisabled = true
    this.publishSpinner = true
    // this.publishedUrl = `/p/${this.bloomMeta.code}/${this.newVersion}/${this.pageStructure.homePageCode}`
    this.publishedUrl = `/p/${this.bloomMeta.code}`

    // copy the original draft meta and update the props that may have changed
    let newDraft = this.bloomMetaBackup
    newDraft['name'] = this.bloomMeta.name
    newDraft['description'] = this.bloomMeta.description
    newDraft['latestVersion'] = this.newVersion
    newDraft['publishedUrl'] = this.publishedUrl
    newDraft['template_data'] = this.bloomMeta['template_data']

    console.log("1. ----------- new draft", newDraft)

    this.bloomMeta['version'] = this.newVersion
    this.bloomMeta['latest'] = true // make latest true to indicate this is the latest published version


    // create final published bloom url
    this.bloomMeta['publishedUrl'] = this.publishedUrl

    this.bloomMeta['releaseNote'] = this.releaseNote || ''


    // TEMPLATE FIELDS
    if(this.bloomMeta.template && this.bloomMeta.template_data){
      this.bloomMeta['template_status'] = 'submitted' // 'submitted' || 'published' || 'deprecated'

      // deprecate previously submitted and published blooms
      let filters = [
        {key: 'code', value: this.bloomMeta.code, dataType: 'string', operator: '='},
        {key: 'template_status', value: 'deprecated', dataType: 'string', operator: '!='},
      ]
      let result = await this.metaService.getTemplateBlooms(1, 100, filters)
      let oldTemplates = result.data
      // console.log("=============================")
      // console.log("old templates fetched", oldTemplates)
      // console.log("=============================")
      oldTemplates.forEach(async template => {
        template['template_status'] = 'deprecated'
        let updateStatus
        try{
          updateStatus = await this.metaService.updateBloom(template)
          console.log("template deprecated", updateStatus)
        }catch(err){
          console.error("could not deprecate submitted template", err)
          this.publishSpinner = false
          this.disabled = false
          this.publishDisabled = false
          this._snackBar.open("could not deprecate submitted template", "Ok")
          return
        }
      });
    }
    this.bloomMeta.publisher_email = this.authService.profile.email

    // change flag in last published bloom
    /**
     * (try to change the last published only if a last published exists,
     * i.e. current version is greater than 1.0.0)
     */
    let lastPublishedBloomMeta
    if(this.newVersion !== '1.0.0'){
      try{
        lastPublishedBloomMeta = await this.metaService.getBloom(this.bloomMeta.code, 'latest')
        console.log("3. ----------- last published bloom meta", lastPublishedBloomMeta)
      }catch(err){
        console.error("could not fetch last published bloom", err)
        this.publishSpinner = false
        this.disabled = false
        this.publishDisabled = false
        this._snackBar.open("could not fetch last published bloom", "Ok")
        return
      }

      // turn off the latest status
      if(lastPublishedBloomMeta){
        lastPublishedBloomMeta['latest'] = false

        // save it back
        let updateStatus
        try{
          updateStatus = await this.metaService.updateBloom(lastPublishedBloomMeta)
          console.log("4. ----------------- updated the latest status of last published bloom", updateStatus)
        }catch(err){
          console.error("could not save status of last published bloom", err)
          this.publishSpinner = false
          this.disabled = false
          this.publishDisabled = false
          this._snackBar.open("could not save status of last published bloom", "Ok")
          return
        }
      }else{
        console.log("there is no previously published bloom, changing latest status does not apply")
      }

    }

    // 1. generate new Page Ids
    // let oldIds = []
    // this.pageStructure.pages.forEach(code => {
    //   oldIds.push(this.pageStructure[code].id)
    // });

    // 1.1 send list of old ids to get corresponding new Ids
    // (assuming the order of the page Ids stay intact in result)
    // let newPageIds: any = []
    // try{
    //   newPageIds = await this.metaService.getClonedPageIds(oldIds)
    // }catch(err){
    //   console.error("Could not get new Ids", err)
    //   this.publishSpinner = false
    //   this.disabled = false
    //   this.publishDisabled = false
    //   this._snackBar.open("could not create new page Ids", "Ok")
    //   return
    // }
    // console.log("new IDs", newPageIds)

    // 2. clone the old pageStructure
    let pgStr = JSON.parse(JSON.stringify(this.pageStructure))
    // 3. copy the new page Ids into the cloned pageStructure
    for (let i = 0; i < pgStr.pages.length; i++) {
      let page = this.pageMap[pgStr.pages[i]];
      if (page) this.clearDetailsPanelValues(page);
      else continue;
      delete page['_id'];
      let newPage = await this.metaService.create(page);
      pgStr[pgStr.pages[i]]['id'] = newPage._id;
    }

    // delete old page structure's id property
    delete pgStr['_id']

    // save the pageStructure
    let pgStrCreateResponse: any
    let newPageStructureId
    try{
      // console.log("2. ---------- pgStr create body", pgStr)
      pgStrCreateResponse = await this.metaService.create_page_structure(pgStr)
      console.log("---------- pgStr create response", pgStrCreateResponse)
    }catch(err){
      console.error("page Structure creation failed", err)
      this.publishSpinner = false
      this.disabled = false
      this.publishDisabled = false
      this._snackBar.open("page Structure creation failed", "Ok")
      return
    }

    // 5. get new page structure id
    newPageStructureId = pgStrCreateResponse._id

    // 6. update in new bloom meta
    this.bloomMeta['page_structure_id'] = newPageStructureId


    // 8. delete id property of bloomMeta
    delete this.bloomMeta['_id']

    // 9. create new published bloom meta record
    let bloomCreateResponse
    try{
      console.log("sending bloom for creation", this.bloomMeta)
      bloomCreateResponse = await this.metaService.create_bloom(this.bloomMeta)
      console.log("5. --------------- published bloom create response", bloomCreateResponse)
    }catch(err){
      console.error("bloom creation failed", err)
      this.publishSpinner = false
      this.disabled = false
      this.publishDisabled = false
      this._snackBar.open("bloom creation failed", "Ok")
      return
    }

    // update draft bloom
    try{
      let draftUpdateResponse = await this.metaService.updateBloom(newDraft)
      console.log("6. -------------- draft bloom update response", draftUpdateResponse)
    }catch(err){
      console.error("error occurred in updating draft", err)

      this.publishSpinner = false
      this.disabled = false
      this.publishDisabled = false

      this._snackBar.open('error occurred in updating draft', 'OK');
      return
    }

    this.publishSpinner = false
    this.disabled = false
    this.publishDisabled = false
    this.publishSuccess = true
    this.oldPublishedUrl = this.bloomMeta.publishedUrl

    let successMessage = this.bloomMeta.name + ' V' + this.bloomMeta.version + " is published"
    this._snackBar.open(successMessage, "", {duration: 1000 })

    // navigate to newly published bloom in new tab
    // window.open(this.publishedUrl, '_blank')

    // this.dialogRef.close(this.publishedUrl)
  }

  clearDetailsPanelValues(page){
    if(page == undefined || page == null) return
    page.panels.forEach(panel => {
      if(panel.type == "detailspanel"){
        let widgets = this.widgetService.getWidgetsFromPanel(panel);
        widgets.forEach(widget => {
          let isManual = ('' + widget.id)?.split("-")?.length < 2 || panel.detailsAttributes.every(attr => attr.__id !== ('' + widget.id)?.split("-")?.[1]);
          let newWidget = WidgetManager.getWidget(widget.type).getWidgetConfig();
          if(newWidget && !isManual){
            let valuePath = newWidget.valuePath;
            this.setObjectValue(widget, valuePath, '')
          }
        })
      }
    })
  }

  setObjectValue(schema, path, value) {
    var pList = path.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];
        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
    }

    schema[pList[len-1]] = value;
}

  copySuccessful(event){
    console.log("URL copied to clipboard")
    this._snackBar.open("URL copied to clipboard", "", {duration: 1000 })
  }

  navigateToOldPublished(){
    window.open(this.oldPublishedUrl, '_blank')
  }

  navigateToPublished(){
    window.open(this.publishedUrl, '_blank')
  }

  navigateToDraft(){
    let urlSplits = this.router.url.split('#')[0].split('/')
    let pageCode = urlSplits[urlSplits.length - 1]
    let bloomCode = urlSplits[urlSplits.length - 2]

    let url = this.currentBaseUrl + '/p/' + bloomCode + '?v=draft'
    window.open(url, '_blank')
  }

}
