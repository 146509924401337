import { Widget } from "./baseWidget";

export class Separator extends Widget{
  type = 'separator';
  gridX = 12
  minGridX = 3
  config = {
    props: ['weight', 'width','borderRadius', 'alignment', 'color'],
    weight: {
      displayName: "Weight",
      value: 1,
      type: 'number',
      suffix: "px"
    },
    width: {
      displayName: "Width",
      value: 100,
      type: 'number',
      suffix: "%"
    },
    borderRadius: {
      displayName: 'Rounded corners',
      value: "0",
      type: 'number',
      max: 50,
      suffix: "%"
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      resetValue: 'flex-start',
      type: 'app-alignment-settings'
    },
    color: {
      displayName: "Separator Color",
      value: "#a9a9a9",
      defaultValue: "#a9a9a9",
      type: "color-picker"
    }
  }

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
  }
}
