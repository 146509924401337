import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { MetaService } from '../../services/meta-service'
import { WidgetService } from '../../services/widget-service.service'
import { PageService } from '../../services/page-service.service';
import { MatInput } from '@angular/material/input';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageCreateDialogComponent } from '../page-create-dialog/page-create-dialog.component';
import { PageDeleteDialogComponent } from '../page-delete-dialog/page-delete-dialog.component';
import { FrontendEventService } from 'src/app/shared/services/FrontendEvent.service';

interface Node {
  name: string,
  code: string,
  interfaceType: string,
  expandable: boolean,
  level: number,
  id: any,  //page id, panel id, widget id
  children: Node[],
  hidden: boolean
}

// interface ExampleFlatNode {
//   expandable: boolean;
//   name: string;
//   level: number;
//   interfaceType: any,
//   id: any,
// }

@Component({
    selector: 'app-builder-menu-pages',
    templateUrl: './builder-menu-pages.component.html',
    styleUrls: ['./builder-menu-pages.component.css'],
    standalone: false
})

export class BuilderMenuPagesComponent implements OnInit, OnDestroy {

  @Input() bloomCode = ''
  @ViewChild('newNameInput') newNameInput: ElementRef
  @ViewChild('pageName') changingPageInput: ElementRef
  @ViewChildren('page') pageRefs: QueryList<any>

  // pageStrObserver = {
  //   next: (page_structure) => {
  //     console.log("pageStrObserver")
  //     this.newPageStructureReceived(page_structure)
  //   },
  //   error: () => { },
  //   complete: () => { }
  // }

  new_page_structure: any = {}
  pageStructure: any = []
  pageMeta: any;
  pageData: Node[] = [];
  allPages: any = {
    pages: []
  }
  pageMetas: any = []
  paddingString: string = '    '
  pageEditViz: boolean = false;
  pageEditVizId: string = '';
  editMode: boolean = false;
  currentPageCode: any = '';
  oldEditingPageCode: any = '';
  newPageName: string = '';
  editingPageName: any;
  editingPageId: any;
  pageCreateMode: boolean = false;
  creatingPageName: any = '';
  isReady: boolean = false;
  pageCreationSpinner: boolean = false;

  editModeArray: any = []

  pageStructureSubscription: any;
  pageMetaSubscription: any;
  currentPageCodeSubscription: any

  allPagesFetched: boolean = false

  private _transformer = (node: Node, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      code: node.code,
      id: node.id,
      level: level,
      interfaceType: node.interfaceType,
      hidden: node.hidden
    };
  }

  treeControl = new FlatTreeControl<Node>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level,
    node => node.expandable,
    node => node.children
  );

  hasChild = (_: number, node: Node) => node.expandable;

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  clickTimer: any

  constructor(
    private dialog: MatDialog,
    private _router: Router,
    private route: ActivatedRoute,
    private metaService: MetaService,
    private cdr: ChangeDetectorRef,
    private pageService: PageService,
    private _snackBar: MatSnackBar,
    public FEEventService: FrontendEventService
  ) {

    this.dataSource.data = [];
  }

  ngOnInit(): void {
    this.currentPageCodeSubscription = this.pageService.currentPageCode.subscribe(code => {
      this.currentPageCode = code
    })

    this.pageStructureSubscription = this.metaService.get_page_structure.subscribe(page_structure => {
      if (page_structure && Array.isArray(page_structure.pages)) {
        this.newPageStructureReceived(page_structure)
      }
    })
  }

  ngOnDestroy(): void {
    this.pageStructureSubscription.unsubscribe()
    this.currentPageCodeSubscription.unsubscribe()
  }

  click(node: Node): void {
    console.log("current pagemeta - >", this.metaService.pageMeta.value);
    this.clickTimer = setTimeout( () => {this.onClick(node);}, 300);
  }
  doubleClick(node: Node): void {
      clearTimeout(this.clickTimer);
      this.clickTimer = undefined;
      this.onDoubleClick(node);
  }
  async onClick(node: Node) {
    if (!this.clickTimer) return;
    this.FEEventService.clearHistory();
    try {
      await this.metaService.update(this.pageService.pageMeta)
      this.pageChange()
      this._router.navigate(['bloom', this.bloomCode, node.code], { fragment: 'edit' })
    } catch (error) {
      console.log("ERROR Saving Pagemeta",error);
      let snackbarRef = this._snackBar.open("Could not Save the Page ! Retry Saving.", "Navigate Anyway", {duration : 3000});

      snackbarRef.onAction().subscribe(() => {
        this.pageChange()
        this._router.navigate(['bloom', this.bloomCode, node.code], { fragment: 'edit' })
      });

    }
  }
  onDoubleClick(node: Node) {
    this.turnOnEditMode(node)
  }


  async newPageStructureReceived(page_structure: any) {
    console.log("[MENU-PAGES] newPageStructureReceived", page_structure)
    this.pageStructure = page_structure
    this.recreateTree()
  }

  recreateTree(){
    this.pageData = []
    if(!this.pageStructure || !Array.isArray(this.pageStructure.pages)) return
    this.pageStructure.pages.forEach(code => {
      this.pageData.push(this.getPageData(this.pageStructure[code]))
    });
    this.dataSource.data = this.pageData;
    console.log("pageData generated", this.dataSource.data)
  }

  savePage() {
    this.pageService.savePageRequest.next(true)
  }

  newPageOld() {
    this.creatingPageName = '';
    this.pageCreateMode = true;
    setTimeout(() => {
      this.newNameInput.nativeElement.focus()
    }, 500);
  }

  async clonePage(node: any){
    await this.newPage(true, node)
  }

  async newPage(isCloning?: boolean, node?: any){
    try {
      // this.pageCreationSpinner = true;
      this.metaService.update(this.pageService.pageMeta);
      // this.pageCreationSpinner = false;
      await this.createPageDialog(isCloning, node);
    } catch (error) {
      console.log("ERROR Saving Current Page !!",error);
      let snackbarRef = this._snackBar.open("Could not Save Current Page.  Retry Saving !", "Proceed Anyway", {duration : 3000});

      snackbarRef.onAction().subscribe(async () => {
        await this.createPageDialog(isCloning, node);
      });
    }
  }

  async createPageDialog(isCloning?: boolean, node?: { name: any; }){
    let dialogRef: MatDialogRef<PageCreateDialogComponent, any>;

    dialogRef = this.dialog.open(PageCreateDialogComponent, {
        width: '450px',
        data: {
          isCloning: isCloning,
          name: node?.name || null,
          pageStructure: this.pageStructure 
        }
    });

    dialogRef.afterClosed().subscribe(async (pageData: { name: any; }) => {
      console.log("new page data to create", pageData)
      if (pageData && pageData.name) {
        await this.createPage(pageData, isCloning, node)
      } else {
        console.log('page creation cancelled');
      }
    });
  }

  async createPage(pageData: any, isCloning?: boolean, node?: any) {
    console.log(pageData, isCloning, node)
    let name = pageData.name
    // if (!name) {
    //   this._snackBar.open("Please Enter Page Name!", "", {duration: 3000})
    //   return
    // }
    // console.log("will create page", this.creatingPageName)
    this.pageCreationSpinner = true

    // create page code
    let code: string = this.constructPagePath(name);
    // let parts = name.split(" ")

    // parts.forEach((part, index) => {
    //   if (index == 0) {
    //     code = part
    //   } else {
    //     code = code + '_' + part
    //   }
    // });

    //check that same page name does not exist
    // let found: boolean = false;
    // this.pageStructure.pages.forEach((pageCode: string) => {
    //   if (this.pageStructure[pageCode].name == name) {
    //     found = true
    //   }
    // });
    // if (name == 'pages' || name == 'homePageCode') {
    //   // console.log("page names reserved, use different name")
    //   this.pageCreationSpinner = false;
    //   this._snackBar.open("Page names reserved, please use different name!!", "", {duration: 3000});
    //   return
    // }
    // if (found) {
    //   this._snackBar.open("Same name already exits, please use different name!!", "", {duration: 3000});
    //   // console.log("same name already exits")
    //   this.pageCreationSpinner = false;
    //   return
    // }


    this.pageCreateMode = false;
    //create pageMeta
    let page;
    if(!isCloning){
      page = await this.pageService.createPageMeta(code, name)
    }else{
      let pagestructure = JSON.parse(JSON.stringify(this.pageStructure));
      console.log(pagestructure);
      let pageMeta;
      try{
        pageMeta = await this.metaService.get(node.id)
      }catch(e){
        console.log("could not get page to update", e)
        throw e
      }
      console.log(pageMeta);
      let panels = JSON.parse(JSON.stringify(pageMeta.panels))
      page = await this.pageService.createPageMeta(code, name, panels)
    }

    //CREATE THE PAGE
    let newPageMeta: any
    try {
      newPageMeta = await this.metaService.create(page)
      console.log("page created", newPageMeta)
    } catch (err) {
      console.log("could not create page", err)
      this._snackBar.open("Could not create page", "", {duration: 3000})
      this.pageCreationSpinner = false
      return
    }

    //create new page structure
    let newPageStructure = JSON.parse(JSON.stringify(this.pageStructure))
    console.log(newPageStructure.pages)
    console.log(newPageStructure)
    console.log(newPageStructure.pages)
    if(isCloning){
      let index: any;
      for(let i=0; i<newPageStructure.pages.length; i++){
        if(newPageStructure.pages[i] == node.code){
          index = i
        }
      }
      console.log(index)
      newPageStructure.pages.splice(index + 1, 0, code)
    }else{
      newPageStructure.pages.push(code)
    }
    newPageStructure[code] = {
      name: name,
      code: code,
      id: newPageMeta._id
    }
    console.log(newPageStructure)

    let updatePageStrRes: any
    try {
      updatePageStrRes = await this.metaService.updatePageStructure(newPageStructure);
      this.pageStructure = newPageStructure
      // steps after page created
      if(!isCloning){
        this._snackBar.open("Page is created!", "", {duration: 3000})
        console.log('pages', this.pageRefs)
        this.pageRefs.last.nativeElement.scrollIntoView({behavior: "smooth"})
        this.pageChange()
        this._router.navigate([`/bloom/${this.bloomCode}/${code}`], { fragment: 'edit' })
      }else{
        this._snackBar.open('Page ' + node.name + ' is succesfully cloned', "", {duration: 3000})
        console.log('pages', this.pageRefs)
      }
    } catch (err) {
      console.log("could not update page structure", err)
      this._snackBar.open("Could not create page", "", {duration: 3000})
      this.pageCreationSpinner = false
      return
    }
    this.pageCreationSpinner = false
  }


  getPageData(pageObject){
    let page: Node = {
      name: pageObject.name,
      code: pageObject.code,
      interfaceType: 'page',
      level: 1,
      expandable: false,
      id: pageObject.id,
      children: [],
      hidden: pageObject.hasOwnProperty('hidden') ? pageObject.hidden : false
    }
    return page
  }

  turnOnVisibility(node: any) {
    if (node.interfaceType !== 'page') {
      return
    }
    // console.log("hit turn on visibility", node)
    this.pageEditViz = true;
    this.pageEditVizId = node.id
  }
  turnOffVisibility(node: any) {
    if (node.interfaceType !== 'page') {
      return
    }
    // console.log("hit turn off visibility", node)
    this.pageEditViz = false;
    this.pageEditVizId = ''
  }

  turnOnEditMode(node: any) {
    //save the page id whose edit mode is turning on, will need later while saving new name
    // console.log("turning on edit mode", node)
    this.editingPageId = node.id
    // console.log("editing page id set", this.editingPageId)

    this.pageStructure.pages.forEach(code => {
      if (this.pageStructure[code].id == this.editingPageId) {
        this.editingPageName = node.name
        // console.log("editing page name set", this.editingPageName)
      }
    });
    this.editMode = true
    setTimeout(() => {
      this.changingPageInput.nativeElement.focus()
    }, 500);
    // console.log("edit mode made true", this.editMode)

    this.editModeArray[this.editingPageId] = true
    // console.log("edit mode array", this.editModeArray)
  }

  async savePageName(node: any) {
    console.log(node);
    if (!node.name) {
      this._snackBar.open("Please Enter Page Name!!", "Ok")
      return
    }
    // make sure reserved keywords are not being used
    if (this.editingPageName == 'pages' || this.editingPageName == 'homePageCode') {
      this.pageCreationSpinner = false;
      this._snackBar.open("Page names reserved, please use different name!!", "Ok");
      return
    }
    //check that same page name not already exists
    if(this.pageStructure.pages.findIndex(code => this.pageStructure[code].name == this.editingPageName) > -1){
      this.pageCreationSpinner = false;
      this._snackBar.open("Same name already exits, please use different name!!", "Ok");
    }

    let newPageStructure:any = {}
    let editingPageCode: string = node.code;
    this.pageCreationSpinner = true;
    let oldPageName: string = node.name;

    // replace in dataSource.data
    let nodeIndex = this.dataSource.data.findIndex(x => x.code == editingPageCode)
    if(nodeIndex > -1){
      this.dataSource.data[nodeIndex].name = this.editingPageName;
    }

    //Assign new Page Name into Page Structure.
    newPageStructure = JSON.parse(JSON.stringify(this.pageStructure))
    newPageStructure[editingPageCode].name = this.editingPageName

    let newPageCode = this.constructPagePath(this.editingPageName);

    let newPageMap = JSON.parse(JSON.stringify(newPageStructure[editingPageCode]));
    console.log("newPageMap", newPageMap)
    newPageMap.code = newPageCode;
    newPageMap.name = this.editingPageName;

    //remove old page code from pages
    // newPageStructure.pages =  newPageStructure.pages.filter(e => e !== editingPageCode);
    let oldCodeIndex = newPageStructure.pages.indexOf(editingPageCode);
    newPageStructure.pages.splice(oldCodeIndex, 1, newPageCode)
    // newPageStructure.pages.push(newPageCode);

    newPageStructure[newPageCode] = newPageMap;

    if(newPageStructure.homePageCode == editingPageCode) newPageStructure.homePageCode = newPageCode;

    console.log("newPageStructure", newPageStructure);


    // update pageMeta
    let newPageMeta: any
    try{
      newPageMeta = await this.updateNameInPageMeta(node.id, this.editingPageName, newPageCode)
    }catch(e){
      console.log("could not update page name", e)

      // revert the page name change in datasource
      let nodeIndex: number = this.dataSource.data.findIndex(x => x.code == editingPageCode)
      this.dataSource.data[nodeIndex].name = oldPageName
      return
    }

    let res
    try{
      res = await this.savePageStructure(newPageStructure)
      this.pageCreationSpinner = false;
    }catch(e){
      console.error("pageStructure update error occurred", e)
      this.pageCreationSpinner = false;
      this.dataSource.data.find(x => x.code == editingPageCode).name = oldPageName;
      throw e
    }

    console.log("new page structure", newPageStructure)
    console.log("new page meta", newPageMeta)

    this.editingPageId = '';
    this.pageChange()
    this._router.navigate(['bloom', this.bloomCode, newPageCode], { fragment: 'edit' });
  }



  async updateNameInPageMeta(pageId: any, newName: string, newCode?: string){
    let pageMeta: any
    try{
      pageMeta = await this.metaService.get(pageId)
    }catch(e){
      console.log("could not get page to update", e)
      throw e
    }
    console.log("pageMeta fetched for update", pageMeta)
    pageMeta.name = newName;
    if(newCode) pageMeta.code = newCode;

    console.log("pageMeta", pageMeta)
    try{
      this.metaService.update(pageMeta)
    }catch(e){
      console.log("could not update page", e)
      throw e
    }
    console.log("page name updated in db", pageMeta)
    return pageMeta
  }

  constructPagePath(code){
    //reference https://quickref.me/convert-a-string-to-url-slug;
    let slugify = (string) => string.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
    return slugify(code);
  }

  async drop(event: CdkDragDrop<string[]>) {
    // console.log("drop event fired", event)
    moveItemInArray(this.pageData, event.previousIndex, event.currentIndex);
    this.dataSource.data = this.pageData;

    let newPagesArray: string[] = []
    this.pageData.forEach(node => {
      if (node.interfaceType == 'page') {
        newPagesArray.push(node.code)
      }
    })

    this.pageStructure.pages = newPagesArray
    let res
    try{
      res = await this.savePageStructure(this.pageStructure)
    }catch(e){
      console.error("page structure update error", e)
    }
  }


  savePageStructure(pageStructure: any){
    let res
    try{
      res = this.metaService.updatePageStructure(pageStructure)
      console.log("page structure updated")
    }catch(e){
      console.log("error in updating page structure")
      throw e
    }
    return res
  }

  async togglePageView(node: any) {
    this.pageStructure[node.code].hidden = !this.pageStructure[node.code].hidden


    //change tree data
    let nodeIndex = this.pageData.findIndex(e => e.code === node.code);
    this.pageData[nodeIndex].hidden = this.pageStructure[node.code].hidden;
    this.dataSource.data = this.pageData

    // update page structure
    let res
    try{
      res = await this.savePageStructure(this.pageStructure)
    }catch(e){
      console.error("page structure update error", e)
    }

    // console.log('updated dataSource', this.dataSource.data);
    // console.log('updated pageStructure', this.pageStructure);
  }

  async deletePageConfirmation(node: any){
    const dialogRef = this.dialog.open(PageDeleteDialogComponent, {
      width: '400px',
      data: node
    });

    dialogRef.afterClosed().subscribe((confirmation) => {
      if (confirmation) {
        console.log("will delete page", node, "CURRENT PAGE STRUCTURE",this.pageStructure);
        let index = this.pageStructure.pages.indexOf(node.code);
        this.deletePage(node)
        if (this.metaService.pageMeta.value.code == node.code){
          this.pageChange()
          this._router.navigate([`/bloom/${this.bloomCode}/${this.pageStructure.pages[index-1]}`], { fragment: 'edit' })
        }
      } else {
        console.log('page deletion cancelled');
      }
    });
  }

  async deletePage(node: any) {
    console.log(node);
    //1. Deep copy of page sturcture in local variable
    let pageStructure = JSON.parse(JSON.stringify(this.pageStructure));
    console.log(pageStructure)

    //2. Delete page code in pages array in page sturcture.
    let pageArray = pageStructure.pages as Array<string>;
    if (pageArray.indexOf(node.code) == -1) {
      console.log('Page not Found');
      return
    }
    pageStructure.pages = pageArray.filter(e => e !== node.code);

    //3.Delete page object from page stucture
    delete pageStructure[node.code];
    console.log("after deletion from pageStructure", pageStructure);

    //4. Update page stucture in database
    let updateRes: any
    try{
      updateRes = await this.metaService.updatePageStructure(pageStructure)
      console.log(updateRes);
      this.pageStructure = pageStructure;
      this._snackBar.open("Page is removed", "", {duration: 2000});
    }catch(e){
      console.error(e);
      this._snackBar.open("Could not remove page", "", {duration: 2000});
      return
    }

    //6. Transverse tree data and delete page node
    this.dataSource.data = this.dataSource.data.filter(e => e.code !== node.code);
    console.log("new dataSource", this.dataSource.data);

    //7. Delete page from DB by id
    let deleteRes: any
    try{
      deleteRes = await this.metaService.deletePage(node.id)
      console.log('delete page successful');
    }catch(e){
      console.error(e);
    }
  }


  pageChange(){
    this.pageService.pageChangeNotifier.next(true)
  }
}

