import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';
import { MetaService } from '../../services/meta-service';
import { WidgetManager } from '../WidgetManager';
import { ActionServiceUtility } from './ActionServiceUtility';
import { BaseAction } from './BaseAction';
import { WidgetService } from 'src/app/bloom/services/widget-service.service'
import { PageService } from '../../services/page-service.service';
import { PageUtilityService } from '../../services/page-utility.service';

@Injectable({
  providedIn: 'root',
})
export class WidgetAction extends BaseAction {
  constructor(
    public router: Router,
    private metaService: MetaService,
    public actionServiceUtility: ActionServiceUtility,
    public expressionUtility: ExpressionUtility,
    public widgetService: WidgetService,
    public pageUtilityService: PageUtilityService,
    public myPageService: PageService,
    public myWidgetService: WidgetService
  ) {
    super();
  }

  getActionDetail() {
    return {
      name: 'Widget',
      id: 'widget',
    };
  }

  getActionConfiguration() {
    let config = {};
    return config;
  }


  /**
   *
   * @param options
   * @param event
   * @param skipPageModelInjection : if true, injected value for this widget is not updated in the pageModel
   * @returns
   */
  public doAction(options?: any, event?: any) {
    // console.log('options in doaction widget', options);
    // console.log('event in doaction widget', event);
    // console.log('this.metaService.pageModel', this.metaService);
    // console.log('pageService', this.pageService);
    let actionMap = options.actionMap;
    let onMouseEvent = options.event;

    if(!this.actionServiceUtility.isEventMatch(onMouseEvent, event)) return;

    if (actionMap.widgetActionType == 'set_value') {
      let widget = event?.widgetMap;
      if (!widget) widget = this.getSeletedWidget(actionMap.widget);
      if (!widget) { 
        console.error("widget that is referenced in action map, is not found in this page.")
        return
      }
      let newWidget: any = this.myWidgetService.createNewWidgetInstance(widget);

      let value = "";

      //if expression configured and no value
      if(newWidget.config?.expressionConfig?.id && !actionMap.value){
        value = this.expressionUtility.resolveExpression(newWidget.config?.expressionConfig, actionMap.value);
      } else {
        value = actionMap.value;
        // console.log("actionMap.widget: %s value: ", actionMap.widget, value)
      }
      let panelId = actionMap.widget.split('.')[0] || null;
      newWidget.setValue(value, false, panelId);
      console.log("value set:", value)
      let pageMeta = this.myPageService.pageMeta
      let panel = pageMeta.panels.find(p => p.id == actionMap.widget.split('.')[0])
      let dataInput: any = {
        dataBindConfig: newWidget.dataBindConfig,
        widgetId: newWidget.id,
        value: value,
        panelId: panel.id,
        panelType: panel.type,
        widgetName: newWidget.name,
        widgetType: newWidget.type
      }

      // console.log("actionMap dataInput", dataInput)

      this.myPageService.insertIntoPageModel(dataInput);

      // if(newWidget.type == 'richtext'){
      //   console.log("value set:", newWidget)
      // }

      // HOW IS IT AUTOMATICALLY REASSIGNING ? why dont we neet to set the new object back into page meta?
      // this.setSelectedWidget(actionMap.widget, newWidget)
    }

    if (actionMap.widgetActionType == 'effects') {
      if(onMouseEvent == "hover"){
        let widget = this.getSeletedWidget(actionMap.widget);
        if(event.type == 'mouseenter'){
          let stylesArr = actionMap.effectStyles;
          let styles = {};
          stylesArr.forEach((e) => {
              styles[e.attribute] = e.value;
          });
          console.log("styles", styles)
          widget.effectStyle = styles;
        }
        if(event.type == 'mouseleave'){
          widget.effectStyle = null
        }
      }
    }
  }

  getSeletedWidget(widgetMapId){
    var result;
    let panelId = widgetMapId.slice(0, widgetMapId.indexOf('.'));//widgetMapId.split(".")[0];
    let widgetId = widgetMapId.slice(widgetMapId.indexOf('.') + 1); //widgetMapId.split(".")[1];
    let pageMeta = this.myPageService.pageMeta;
    pageMeta.panels.forEach((panelMeta) => {
      let panel = panelMeta;
      if (panel.id == panelId){
        let widgets = this.widgetService.getWidgetsFromPanel(panel);
        widgets.forEach((widget) => {
          if (widget.id == widgetId) {
            result = widget;
            return result;
          }
        });
      }

    });
    return result;
  }

  setSelectedWidget(widgetMapId, newMeta: any){
    let pageMeta: any
    let widgetId = widgetMapId.split('.')[1];
    let panelId = widgetMapId.split('.')[0];
    pageMeta = JSON.parse(JSON.stringify(this.metaService.pageMeta.value))
    if(!pageMeta) return
    console.log("selected weidget")
    for (let i = 0; i < pageMeta.panels.length; i++) {
      let panel = pageMeta.panels[i];
      if(panelId == panel.id){
        let widgets = this.widgetService.getWidgetsFromPanel(panel);
        for (let j = 0; j < widgets.length; j++) {
          let widget = widgets[j];
          if (widget.id == widgetId) {
            widget = JSON.parse(JSON.stringify(newMeta))
            // console.log("pageMeta from action widget")
            // this.metaService.pageMeta.next(pageMeta)
            return
          }
        }
      }
    }
  }

  setDeepObjectValue(obj, path, value, setrecursively: boolean = false) {
    var pList = path.split('.');
    pList.reduce((a, b, level) => {
      if (setrecursively &&typeof a[b] === 'undefined' &&level !== pList.length) {
        a[b] = {};
        return a[b];
      }
      if (level === pList.length - 1) {
        a[b] = value;
        return value;
      }
      return a[b];
    }, obj);
    return obj;
  }

}
