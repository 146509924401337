import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionManager } from '../../ActionManager';
import { MetaService } from '../../../../services/meta-service';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { WidgetManager } from '../../../WidgetManager';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-action-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: false
})
export class NavigationComponent implements OnInit {
  myControl = new UntypedFormControl('');


  @Input() "actionConfig": any;
  @Input() "widget": any;
  config: any;
  sourceFields: any[] = [];
  navigationOptions: any = []
  navigationValue: any
  noValidnavigation: boolean;
  validateMessage: any;
  // @Output() "actionConfig" = new EventEmitter<any>();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    public metaService: MetaService,
    public actionManager: ActionManager,
    public widgetService: WidgetService,
    public pageService: PageService
  ) { }

  ngOnInit(): void {
    console.log("actionConfig");
    // this.onActionChange();


    let te = new TemplateEngine();
    this.metaService.pageMeta.subscribe(pageMeta => {
      this.sourceFields = [];
      // this.pageMeta = pageMeta
      // console.log("---> pagemeta", pageMeta)
      pageMeta.panels.forEach(panelMeta => {
        let panel = panelMeta;
        // if(panel.type == "detailspanel"){
        //   panel.widgets.forEach((w) => {
        //     console.log("inn -->", w)
        //     if(WidgetManager.getWidget(w.type)?.getMappingConfig()){
        //       let config = WidgetManager.getWidget(w.type)?.getMappingConfig();
        //       let data = {
        //         panel: panel,
        //         widget: w
        //       }
        //       this.sourceFields.push(te.fillTemplateObject(config, data))
        //     }
        //   })
        // } else {
          let widgets = this.widgetService.getWidgetsFromPanel(panel);
          widgets.forEach((widget ) => {
            if(WidgetManager.getWidget(widget.type)?.getMappingConfig(this.widget)){
              let config = WidgetManager.getWidget(widget.type)?.getMappingConfig();
              let data = {
                panel: panel,
                widget: widget
              }
              this.sourceFields.push(te.fillTemplateObject(config, data))
            }

          })
        // }

      })
      if(this.actionConfig.actionMap.pageType == 'bloom_page'){
        this.navigationValue = this.metaService.loadedPageStructure[this.actionConfig.actionMap.page]?.name
      }else if(this.actionConfig.actionMap.pageType == 'link'){
        this.navigationValue = this.actionConfig.actionMap.link
      }else if(this.actionConfig.actionMap.pageType == 'prev_page'){
        this.navigationValue = 'Previous page'
      }
      // console.log("this.sourceFields", this.sourceFields)
    })
    if(!this.actionConfig.actionMap.parameters) this.actionConfig.actionMap.parameters = [];
  }

  // onActionChange(){
  //   this.config = this.actionManager.getAction(this.actionConfig.action).getActionConfiguration()
  //   console.log("this.actionConfig ", this.actionConfig )
  //   if(this.actionConfig.action && !this.actionConfig.actionMap) this.actionConfig.actionMap = {};
  // }

  addNewAttribute(){
    if(!this.actionConfig.actionMap.parameters) this.actionConfig.actionMap.parameters = [];
    let mapObj = {
      keyField: {
        __id: ""
      },
      sourceField: {}
    };
    this.actionConfig.actionMap.parameters.push(mapObj)
    this.changeDetected()
  }

  onWidgetActionSelected(){
    console.log("this.actionConfig.actionMap", this.actionConfig.actionMap)
    this.changeDetected()
  }

  removePayloadAttribute(payloadData, i){
    payloadData.splice(i, 1);
    this.changeDetected()
  }

  setAttributeId(data){
    // data.__id = data.name;
  }

  navigationRecieved(e){
    console.log(e, this.metaService.loadedPageStructure.pages, this.metaService.loadedPageStructure.pages.includes(e) )
    if(this.metaService.loadedPageStructure.pages.includes(e)){
      this.actionConfig.actionMap.pageType = 'bloom_page'
      this.actionConfig.actionMap.page = e
      this.navigationValue = this.metaService.loadedPageStructure[e].name
      delete this.actionConfig.actionMap.link
      this.noValidnavigation = false;
    }else if(this.isValidUrl(e)){
      this.actionConfig.actionMap.pageType = 'link'
      this.actionConfig.actionMap.link = e
      delete this.actionConfig.actionMap.page
      this.noValidnavigation = false;
    }else if(e == 'prev_page'){
      this.actionConfig.actionMap.pageType = 'prev_page'
      console.log(this.pageService.previousUrl)
      this.navigationValue = 'Previous Page'
      delete this.actionConfig.actionMap.link
      delete this.actionConfig.actionMap.page
      this.noValidnavigation = false;
    }else{
      this.noValidnavigation = true;
      this.validateMessage = "Please put a valid URL or select a page"
    }
    this.changeDetected()
  }

  changeDetected(){
    this.metaService.userChangeDetected()
    this.selectionChanged.emit();
  }

  isValidUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }

  compareWith(o1: any, o2: any) {
    return o1 && o2 && o1.__id === o2.__id;
  }
}
