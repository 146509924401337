import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog
} from '@angular/material/dialog';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from '../../../modules/organization/connection/connection-listing/CustomPaginatorConfiguration';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { environment } from 'src/environments/environment';
import { PageService } from '../../services/page-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { WidgetManager } from '../../models/WidgetManager';
import { PLATFORM_ID } from '@angular/core';
import { Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CreateBloomPopupComponent } from 'src/app/shared/create-bloom/create-bloom-popup/create-bloom-popup.component';
import { BloomCollaborationComponent } from '../bloom-collaboration/bloom-collaboration.component';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';

@Component({
    selector: 'app-bloom-listing',
    templateUrl: './bloom-listing.component.html',
    styleUrls: ['./bloom-listing.component.css'],
    providers: [
        { provide: MatPaginatorIntl, useValue: CustomPaginator() }, // Here
    ],
    standalone: false
})
export class BloomComponent implements OnInit, OnDestroy {
  @ViewChild('bloomPaginator', { static: true })
  public bloomPaginator: MatPaginator | undefined;
  bloomListTableData: any = [];
  pageStructure: any;
  selectedConnectionView: string = 'view_list';
  unselectedView: string = 'view_module';
  showTip: string = 'Grid View';
  pageIndex: any = 0;
  pageLimit: any = 5;
  isPaginator: boolean = false;
  bloomLength: number = 0;
  spinner: boolean = false;
  startIndex: number = 0;
  newBloomCode: string;
  newTimerId: any = '';
  refreshPanel: boolean = false
  
  error = false;
  genericError: boolean = false;
  genericErrorMessage: string;
  isNoBloom: boolean = false;
  
  displayedColumns: string[] = ['number', 'name', 'actions'];
  authError: boolean = false;

  sharedBloomSpinner: boolean = false;
  sharedBloomFetchError: boolean = false;
  sharedBlooms: any[] = [];
  sharedBloomsPagination: any = {
    pageNumber: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 25],
    total: 100
  }
  sharedBloomsList: any[] = []

  currentBaseUrl: string;
  environment: any = environment;

  bloomListPanelMeta: any
  // bloomListPanelMetaOther: any
  bloomsList: any[] = []
  workspaceReady: boolean = false
  panelMetaReady: boolean = false
  // panelMetaReadyOther: boolean = false
  isBrowser: boolean;
  bloomListSkeletonSpinner: boolean = false

  sharedBloomListMeta = {
    listAttributes: [
      {
        "widgetType": "label",
        "columnName": "#",
        "sortEnabled": false,
        "show_hide": false,
        "value": "__serial__",
        "dataType": "NA",
        "isColumnSelected": true,
        "name": "__serial__",
        "__id": "___serial__",
        "fieldType": "label"
      },
      {
        __id: "logoUrl",
        name: "logo",
        dataType: 'string',
        filterable: false,
        sortable: false,
        sortEnabled: false,
        show_hide: false,
        fieldType: 'attribute',
        widgetType: 'image',
        isColumnSelected: true
      },
      {
        "__id": "name",
        "name": "Bloom Name",
        "dataType": "string",
        "sortEnabled": false,
        "show_hide": false,
        "fieldType": "attribute",
        "widgetType": "label",
        "isColumnSelected": true
      },
      {
        "__id": "code",
        "name": "Bloom Code",
        "dataType": "string",
        "sortEnabled": false,
        "show_hide": false,
        "fieldType": "attribute",
        "widgetType": "label",
        "isColumnSelected": false
      },
      {
        columnName: 'Edit',
        dataType: 'NA',
        fieldType: 'icon',
        name: 'edit',
        show_hide: false,
        sortEnabled: false,
        value: 'edit',
        widgetType: 'icon',
        __id: 'edit',
        isColumnSelected: true
      },
      {
        columnName: 'View',
        dataType: 'NA',
        fieldType: 'icon',
        name: 'view published bloom',
        show_hide: false,
        sortEnabled: false,
        value: 'preview',
        widgetType: 'icon',
        __id: 'view_published',
        isColumnSelected: true
      }
    ],
    // dataHeaders: ["#", "Name"],
    defaultListSize: 10,
    hideTitle: true,
    type: 'listpanel',
    viewTypes: {
      "views": [
          "table",
          "card",
          "board"
      ],
      "defaultView": "table",
      "boardStatusColumn": "",
      "userCanChoose": true,
      "table": {
          "name": "table",
          "displayName": "Table",
          "userCanChoose": true,
          "icon": "list"
      },
      "card": {
          "name": "card",
          "displayName": "Card",
          "userCanChoose": true,
          "icon": "grid_on"
      },
      "board": {
          "name": "board",
          "displayName": "Board",
          "userCanChoose": false,
          "icon": "view_kanban"
      }
    },
    userFilterEnabled: false,
    userSortEnabled: false,
    boxId: 'starch',
    connectionId: "",
    baseMap: {
      "box_id": "",
      "base_id": ""
    },
    boxObjectId: 'bloom',
    loadInitialData: false,
    boxConfigToken: '',
    pageSize: 10,
    paginationEnabled:true,
    paginationType: "pagebypage",
    id: "sharedBloomListing",
    noCache: true,
    noDataMessage: "You have no shared bloom"
  };

  constructor(
    private themeService: ThemeService,
    private router: Router,
    private ngZone: NgZone,
    private currentRoute: ActivatedRoute,
    private MetaService: MetaService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public connectionService: ConnectionService,
    public authService: AuthServiceService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService,
    private metaService: MetaService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  async ngOnInit() {
    if(!this.isBrowser) return;
    this.currentBaseUrl = window.location.protocol + '//' + window.location.hostname
    this.currentBaseUrl = window.location.port ? this.currentBaseUrl + ":" + window.location.port : this.currentBaseUrl
    console.log("current base url set", this.currentBaseUrl)
    if (
      !this.connectionService.selectedWorkSpace &&
      !this.authService.loggedIn
    ) {
      console.log("no workspace or log in")
      this.authService.authCheck();
      this.authService.authCheckPositive.subscribe(async (authStatus) => {
        console.log("auth check subscription", authStatus)
        //if logged in
        if (authStatus) {
          this.spinner = true;
          console.log('logged in');
          await this.themeService.loadTheme();
          this.spinner = false;
          await this.initialize();
          this.cdr.detectChanges();
        } else {
          console.log("not authed")
          this.error = true;
          this.authError = true;
          this.spinner = false;
          // redirect to login page
          this._snackBar.open('Apologies, the login attempt failed. Please reload the page and try logging in again.', 'ok', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          this.ngZone.run(() => {
            this.router.navigate(['../']);
          });
        }
      });
    }

    // else if (this.connectionService.selectedWorkSpace) {
    //   // this.getBlooms();
    //   await this.initialize()
    // }

    else if (this.authService.loggedIn) {
      console.log("logged in", this.authService.loggedIn)
      await this.initialize();
      this.cdr.detectChanges();
    }
  }

  navigateToSignUp(){
    this.router.navigate(['sign-up']);
  }

  ngOnDestroy(): void {
    clearInterval(this.newTimerId);
    this.authService?.authCheckPositive?.unsubscribe()
  }

  async initialize() {

    this.spinner = true;
    try {
      console.log("initialize hit")
      this.spinner = true;
      this.connectionService.getPreAuthenticatedToken();
      if(!this.connectionService.selectedWorkSpace) await this.connectionService.getWorkSpaceId();
      this.workspaceReady = true
      this.spinner = false;
       this.createListMeta()
      //  this.createListMetaForOtherBlooms()
      await this.getSharedBlooms();
    } catch (error) {
      console.error("Initialization error:", error);
    } finally {
      this.spinner = false;
    }
  }


  selectedRowData(data){
    console.log("selectedRowData hit", data)
    if(data.actionId == 'edit'){
      console.log("open bloom", data.rowDataRaw)
      this.openBloom(data.rowDataRaw.code, data.rowDataRaw.page_structure_id)
    }else if(data.actionId == 'share'){
      console.log("share bloom", data.rowDataRaw)
      this.onShare(data.rowDataRaw)
    }else if(data.actionId == 'clone'){
      console.log("clone hit")
      this.cloneBloom(data.rowDataRaw)
    }else if(data.actionId == 'view_published'){
      console.log("view published hit")
      console.log("currentBase url", this.currentBaseUrl, "published url", data.rowDataRaw.publishedUrl, "latest version",  data.rowDataRaw.latestVersion)
      if(data.rowDataRaw.publishedUrl) {
        window.open(this.currentBaseUrl + data.rowDataRaw.publishedUrl, "_blank")
      }else{
        console.log("bloom is not yet published")
        this._snackBar.open("Bloom is not published yet")
      }
    }
  }

  async onShare(currentBloomMeta){
    console.log(currentBloomMeta)
      let dialogRef = this.dialog.open(BloomCollaborationComponent, {
        width: '90vw',
        height: '90vh',
        panelClass: "collabdiolog",
        data: {
          bloomMap: currentBloomMeta,
          resourceType: 'bloom',
          workspaceMap: this.connectionService.selectedWorkSpaceMap
        },
      });
      var diologResult = await dialogRef.afterClosed().toPromise();
  }

  selectedSharedRowData(event){
    console.log(event)
    let bloomMeta
    if(event.data?.code?.value){
      let code = event.data?.code?.value
      this.sharedBlooms.forEach(bloom => {
        if(bloom.code == code){
          bloomMeta = bloom
        }
      });
    }
    if(bloomMeta){
      if(event.actionId == 'edit'){
        console.log("open bloom", event.rowDataRaw)
        this.openBloom(bloomMeta.code, bloomMeta.page_structure_id)
      }else if(event.actionId == 'view_published'){
        console.log("view published hit")
        console.log("currentBase url", this.currentBaseUrl, "published url", bloomMeta.publishedUrl, "latest version",  bloomMeta.latestVersion)
        if(bloomMeta.publishedUrl) {
          window.open(this.currentBaseUrl + bloomMeta.publishedUrl, "_blank")
        }else{
          console.log("bloom is not yet published")
          this._snackBar.open("Bloom is not published yet")
        }
      }
    }
  }

  createListMeta(){
    this.bloomListPanelMeta = {
      listAttributes: [
        {
          "widgetType": "label",
          "columnName": "#",
          "sortEnabled": false,
          "show_hide": false,
          "value": "__serial__",
          "dataType": "NA",
          "isColumnSelected": true,
          "name": "__serial__",
          "__id": "___serial__",
          "fieldType": "label"
        },
        {
          "__id": "logoUrl",
          "name": "Logo",
          "dataType": "string",
          "sortEnabled": false,
          "show_hide": false,
          "fieldType": "attribute",
          "widgetType": "image",
          "isColumnSelected": true
        },
        {
          "__id": "name",
          "name": "Bloom Name",
          "dataType": "string",
          "sortEnabled": false,
          "show_hide": false,
          "fieldType": "attribute",
          "widgetType": "label",
          "isColumnSelected": true
        },
        {
          "__id": "publish_source",
          "name": "Publish Type",
          "dataType": "object",
          "sortEnabled": false,
          "show_hide": false,
          "fieldType": "attribute",
          "widgetType": "label",
          "isColumnSelected": true,
          isDrillDown: true,
          nestedProperties: [
            {
              fieldType: "attribute",
              path: "type",
              widgetType: "label",
              headerTitle: "Publish Type"
            }
          ]
        },
        {
          columnName: 'Edit',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'edit',
          show_hide: false,
          sortEnabled: false,
          value: 'edit',
          widgetType: 'icon',
          __id: 'edit',
          isColumnSelected: true
        },
        {
          columnName: 'Clone',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'clone',
          show_hide: false,
          sortEnabled: false,
          value: 'content_copy',
          widgetType: 'icon',
          __id: 'clone',
          isColumnSelected: true
        },
        {
          columnName: 'View',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'view published bloom',
          show_hide: false,
          sortEnabled: false,
          value: 'preview',
          widgetType: 'icon',
          __id: 'view_published',
          isColumnSelected: true
        },
        {
          columnName: 'Delete',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'delete',
          show_hide: false,
          sortEnabled: false,
          value: 'delete',
          widgetType: 'icon',
          __id: 'delete',
          isColumnSelected: true,
          eventType: 'action',
          actionConfig: {
            action: 'resource-deletion',
            event: 'click'
          }
        },
        {
          columnName: 'Share',
          dataType: 'NA',
          fieldType: 'icon',
          name: 'share',
          show_hide: false,
          sortEnabled: false,
          value: 'share',
          widgetType: 'icon',
          __id: 'share',
          isColumnSelected: true
        }
      ],
      // dataHeaders: ["#", "Name"],
      defaultListSize: 10,
      hideTitle: true,
      filter: {
        "filterEnabled": true,
        "filterItems": [
          {
            "attribute": "version",
            "operator": "=",
            "value": "draft",
            "dataType": "string",
            "filterType": "static_filter"
          },
          {
            "attribute": "workspace_id",
            "operator": "=",
            "value": this.connectionService.selectedWorkSpace,
            "dataType": "string",
            "filterType": "static_filter"
          }
        ]
      },
      type: 'listpanel',
      viewTypes: {
        "views": [
            "table",
            "card",
            "board"
        ],
        "defaultView": "table",
        "boardStatusColumn": "",
        "userCanChoose": true,
        "table": {
            "name": "table",
            "displayName": "Table",
            "userCanChoose": true,
            "icon": "list"
        },
        "card": {
            "name": "card",
            "displayName": "Card",
            "userCanChoose": true,
            "icon": "grid_on"
        },
        "board": {
            "name": "board",
            "displayName": "Board",
            "userCanChoose": false,
            "icon": "view_kanban"
        }
      },
      userFilterEnabled: false,
      userSortEnabled: false,
      boxId: 'starch',
      connectionId: "",
      baseMap: {
        "box_id": "mongodb",
        "base_id": ""
      },
      boxObjectId: 'bloom',
      loadInitialData: true,
      boxConfigToken: environment.DB_BOX_TOKEN,
      pageSize: 10,
      paginationEnabled:true,
      paginationType: "pagebypage",
      id: "bloomListing",
      noCache: true,
      noDataMessage: "Create your first bloom now."
    };

    this.bloomListPanelMeta["listWidgetSet"] = this.getWidgetSet(this.bloomListPanelMeta)
    if(this.bloomListPanelMeta["listWidgetSet"]){
      this.bloomListPanelMeta["listWidgetSet"].delete.textFormat.color.value = '#d50000';
      this.bloomListPanelMeta["listWidgetSet"].clone.textFormat.color.value = '#4a148c';
      this.bloomListPanelMeta["listWidgetSet"].edit.textFormat.color.value = '#4a148c';
      this.bloomListPanelMeta["listWidgetSet"].share.textFormat.color.value = '#4a148c';
      this.bloomListPanelMeta["listWidgetSet"].view_published.textFormat.color.value = '#4a148c';
      this.bloomListPanelMeta["listWidgetSet"].name.textFormat.bold.value = true;
      this.bloomListPanelMeta["listWidgetSet"].name.textFormat.fontSize.value = 16 
    }

    console.log("Panel meta for bloom listing", this.bloomListPanelMeta)
    this.panelMetaReady = true
  }

  getWidgetSet(meta){
    let widgetSet = {}
    for (let i = 0; i < meta.listAttributes.length; i++){
      const attr = meta.listAttributes[i];
      if(!attr.isDrillDown){
        let widgetType: string = attr.widgetType == 'NA' ? attr.fieldType : attr.widgetType;
        widgetSet[attr.__id] = WidgetManager.getWidget(widgetType || 'label')
      }else {
        attr.nestedProperties.forEach(prop => {
          let widgetType: string = prop.widgetType == 'NA' ? prop.fieldType : prop.widgetType;
          widgetSet[attr.__id + '.' + prop.path] = WidgetManager.getWidget(widgetType || 'label')
        })
      }
    }
    return widgetSet
  }

  async getSharedBlooms() {
    this.sharedBloomSpinner = true;
    let that = this
    try{
      this.sharedBlooms = await this.resourcePermissionService.getSharedResources(
        this.authService.profile._id,
        'bloom',
        this.sharedBloomsPagination.pageNumber,
        this.sharedBloomsPagination.pageSize
      );
      console.log("shared blooms : ", that.sharedBlooms)
      this.sharedBloomFetchError = false
    } catch(e){
      this.sharedBloomFetchError = true
      console.log("error while getting shared blooms", e)
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {
          title: e.error?.error?.errorMap?.error || '',
          // description: e.error?.error?.errorMap?.error || '',
          message: "Could not fetch list of shared blooms",
          iconname: "error",
          isError: true
        },
        horizontalPosition: 'end',
      });
    } finally {
      this.sharedBloomSpinner = false;
    }
    if(this.sharedBlooms.length) {
      let sharedBloom = []
      that.sharedBlooms.forEach(bloom => {
        let bloomMeta = {}
        bloomMeta['name'] = bloom.name
        bloomMeta['logoUrl'] = bloom.logoUrl
        bloomMeta['code'] = bloom.code
        console.log(bloom, bloomMeta)
        sharedBloom.push(bloomMeta)
      });
      this.sharedBloomsList = sharedBloom
    }
    console.log(this.sharedBlooms, this.sharedBloomsList)
  }


  sharedBloomsPageEvent(event) {
    console.log("shared blooms page event", event)
    this.sharedBloomsPagination.pageNumber = event.pageIndex + 1
    this.sharedBloomsPagination.pageSize = event.pageSize
    this.getSharedBlooms()
  }


  async onNewBloomClicked() {
    this.router.navigate(['bloom', 'new']);
  }

  // generate random logo url
  getLogoUrl() {
    let randomLogoCode: any = Math.floor(Math.random() * 10);
    randomLogoCode = randomLogoCode + 1;
    if (randomLogoCode < 10) {
      randomLogoCode = '0' + randomLogoCode;
    }

    let logoUrl = environment.BLOOM_LOGO_BASE_URL + randomLogoCode + '.jpg';
    return logoUrl;
  }

  /**
   * opening a bloom is a 4 step process
   * 1. get associated page structure
   * 2. find the page id with homepage marker
   * 3. use the page code to formulate the url
   * 4. navigate to the url
   * @param bloomCode
   */
  openBloom(bloomCode: any, pageStructureId: any, isCollaborated?: boolean) {
    this.pageService.resetBloomData();
    this.metaService.resetBloomData()

    this.spinner = true;
    console.log('need to open bloom:', bloomCode);

    //get associated page structure

    //get the page structure
    this.MetaService.getPageStructure(pageStructureId)
      .then((pageStructure) => {
        this.pageStructure = pageStructure;

        //find the page code of the page with homepage marker
        let homePageCode = this.pageStructure['homePageCode'];

        let currentUrl = window.location.href;

        this.spinner = false;
        //use the page code to formulate the url and navigate
        this.router.navigate([`/bloom/${bloomCode}/${homePageCode}`], {
          fragment: 'edit',
          queryParams: { collaborated_bloom: isCollaborated },
        });

        // worth noting here, passing the fragment as {fragment: 'edit'} as 2nd parameter to navigateByUrl
        // should also work, but it doesn't.
      })
      .catch((err) => {
        this.spinner = false;
        console.log('error in getting page structure', err);
      });
  }

  /*
  set bloom permission and open bloom based on permission
  */
  async setPermissionAndOpenBloom(bloom: any) {
    // this.spinner = true
    // await this.bloomPermissionService.setWorkspacePrivilages(bloom);
    // console.log("bloom permission", this.bloomPermissionService.hasAccess(['bloom.edit', 'workspace.edit']))
    // this.spinner = false;

    // console.log("bloom", bloom)
    // if(this.bloomPermissionService.hasAccess(['bloom.edit', 'workspace.edit'])){
    //   this.openBloom(bloom.code, bloom.page_structure_id, true);
    //   return;
    // } else {
    // this.openBloom(bloom.code, bloom.page_structure_id, true);
    window.open(bloom.publishedUrl || bloom.published_url, '_blank');
    // }
  }

  connectionListToggle() {
    if (this.selectedConnectionView == 'view_list') {
      this.selectedConnectionView = 'view_module';
      this.unselectedView = 'view_list';
      this.showTip = 'List View';
    } else {
      this.selectedConnectionView = 'view_list';
      this.unselectedView = 'view_module';
      this.showTip = 'Grid View';
    }
  }

  workspaceSelectionChange(selectedWorkspace: any) {
    this.bloomListSkeletonSpinner = true
  }

  async refreshList() {
    this.bloomListSkeletonSpinner = false
    this.initialize()
  }

  pageChanged($event) {
    console.log($event);
    this.spinner = true;
    this.pageIndex = $event.pageIndex;
    this.pageLimit = $event.pageSize;
    console.log(this.startIndex);
    this.MetaService.getBloomByWorkspace(
      this.connectionService.selectedWorkSpace,
      'draft',
      false,
      this.pageIndex + 1,
      this.pageLimit
    ).then((data) => {
      this.bloomListTableData = data;
      this.startIndex = $event.pageIndex * $event.pageSize;
      this.spinner = false;
    });
  }

  async cloneBloom(bloom, index?){
    var dialog = this.dialog.open(CreateBloomPopupComponent, {
      minWidth: "60%",
      data: {
        title: "Clone Bloom",
        buttonText: "Clone"
      }
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("do", diologResult)
    if(!diologResult) return;

    let bloomNameMap = diologResult;
    this.spinner = true;
    console.log("bloom to clone", bloom)
    let page_structure;
    let clonedPage_structure = {pages: []}
    let pages;
    let clonedPages = []
    let clonedBloom = JSON.parse(JSON.stringify(bloom));

    ///Fetching page structure///
    await this.MetaService.getPageStructure(bloom.page_structure_id)
      .then((pageStructure) => {
        page_structure = JSON.parse(JSON.stringify(pageStructure))
      console.log(page_structure)
    })
    let idArray = []
    page_structure.pages.forEach(e => {
      idArray.push(page_structure[e].id)
    });
    console.log(idArray)
    ///////////////// Fetching all pages meta//////////////////
    await this.MetaService.getAllPages(idArray)
      .then((page) => {
        pages = JSON.parse(JSON.stringify(page))
        console.log(pages, page)
    })
    for(let i=0; i<pages.length; i++){
      delete pages[i]._id;
      delete pages[i].created_at;
      delete pages[i].modified_at;

      /// creating new page meta ///
      let newPageMeta: any
      try {
        newPageMeta = await this.metaService.create(pages[i])
        console.log("page created", newPageMeta)
        if(newPageMeta) clonedPages.push(newPageMeta)
      } catch (err) {
        console.log("could not create page", err)
        return
      }
    }
    console.log(clonedPages)
    clonedPages.forEach(p => {
      clonedPage_structure.pages.push(p.code)
      clonedPage_structure[p.code] = {
        name: p.name,
        code: p.code,
        id: p._id
      }
      clonedPage_structure['homePageCode'] = page_structure['homePageCode']
    })
    console.log(clonedPage_structure)

    /// creating new page structure ///
    let updatePageStrRes: any
    try {
      updatePageStrRes = await this.metaService.create_page_structure(clonedPage_structure);
    } catch (err) {
      console.log("could not update page structure", err)
      return
    }
    console.log(updatePageStrRes)

    //// creating new bloom meta //
    clonedBloom.name = bloomNameMap.name;
    clonedBloom.code = bloomNameMap.code;
    clonedBloom.created_at = new Date().toISOString();
    clonedBloom.modified_at = new Date().toISOString();
    clonedBloom.page_structure_id = updatePageStrRes._id;
    clonedBloom.version = 'draft';
    if(clonedBloom.publishedUrl){
      delete clonedBloom.publishedUrl;
    }
    if(clonedBloom.latestVersion){
      delete clonedBloom.latestVersion;
    }
    if(clonedBloom.publisher_email){
      delete clonedBloom.publisher_email;
    }
    delete clonedBloom._id;

    console.log(clonedBloom)


    let bloomCreationResponse
    try{
      bloomCreationResponse = await this.metaService.create_bloom(clonedBloom);
    }catch(e){
      console.log("bloom creation failed", e)
      return
    }

    console.log("bloom cloned",bloomCreationResponse)
    // console.log(index)
    this.bloomListPanelMeta = JSON.parse(JSON.stringify(this.bloomListPanelMeta))
    // this.bloomListTableData.splice(index + 1, 0, bloomCreationResponse)
    // console.log(this.bloomListTableData)
    // let list = JSON.parse(JSON.stringify(this.bloomListTableData))
    // this.bloomListTableData = [];
    // this.bloomListTableData = JSON.parse(JSON.stringify(list))
    this._snackBar.open('Bloom ' + bloom.name + ' is succesfully cloned', "", {duration: 3000});
    this.spinner = false;
    this.ngZone.run(() => { })
    this.triggerRefresh()
  }

  triggerRefresh(){
    this.refreshPanel = true;
    let that = this;
    setTimeout(function() {
      that.refreshPanel = false;
    }, 1000);
  }

  // publishedListRowAction(data: any){
  //   console.log("published list row action", data)
  //   if(data.actionId == 'edit'){
  //     console.log("open bloom", data.rowDataRaw)
  //     this.openListBloom(data.rowDataRaw)
  //   }else if(data.actionId == 'deleteById'){
  //     console.log("delete bloom", data.rowDataRaw)
  //     // this.onDelete(data.rowDataRaw)
  //   }else if(data.actionId == 'view_published'){
  //     console.log("view published hit")
  //     console.log("currentBase url", this.currentBaseUrl, "published url", data.rowDataRaw.publishedUrl, "latest version",  data.rowDataRaw.latestVersion)
  //     if(data.rowDataRaw.publishedUrl) {
  //       window.open(this.currentBaseUrl + data.rowDataRaw.publishedUrl, "_blank")
  //     }else{
  //       console.log("bloom is not yet published")
  //       this._snackBar.open("Bloom is not published yet")
  //     }
  //   }
  // }


  /**
   * 1. search by code to find a draft
   * 2. if found
   *    a. open
   *    b. return
   *
   * 3. if not found,
   *    a. trigger a clone as draft operation in workspace
   *    b. open
   *    c. return
   */
  // async openListBloom(bloom: any){
  //   console.log("will search by code", bloom.code)
  //   this.spinner = true
  //   let draftBloom = await this.metaService.getBloom(bloom.code, "draft")
  //   console.log("draft bloom found", draftBloom)
  //   if(draftBloom){
  //     this.openBloom(draftBloom.code, draftBloom.page_structure_id)
  //   }else{
  //     try{
  //       let res = await this.MetaService.createDraft(bloom)
  //       console.log("draft bloom created", res)
  //       this.openBloom(res.code, res.page_structure_id)
  //     }catch(e){
  //       console.log("could not create draft bloom", e)
  //       throw e
  //     }
  //   }
  //   this.spinner = false
  // }
}
