import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ThemeService } from './shared/services/theme.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { MixpanelService } from './shared/services/mixpanel.service';
import { ConnectionService } from './modules/organization/connection.service';
import { environment } from 'src/environments/environment';
import { filter, map, mergeMap, pairwise } from 'rxjs/operators';
import { PageService } from './bloom/services/page-service.service';
import { SeoService } from './core/services/SeoService';
import { BoxCache } from './core/boxInstances/BoxCache';
import { TransferState, makeStateKey } from '@angular/core';
import { UIConfigService } from './shared/services/UIConfigService';

// const PRE_RENDER_KEY = makeStateKey<string>('preRendered');


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false
})
export class AppComponent implements OnInit {
  title = 'AppiWorks';
  routerSubscription: any;
  isBrowser: any;

  constructor(
    public themeService: ThemeService,
    private translate: TranslateService,
    public router: Router,
    private route: ActivatedRoute,
    public msp: MixpanelService,
    public orgService: ConnectionService,
    public pageService: PageService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    public seoService: SeoService,
    private transferState: TransferState,
    private activatedRoute: ActivatedRoute,
    public uic: UIConfigService,
    private state: TransferState,
  ) {
    translate.addLangs(['en', 'fr']);
    this.isBrowser = isPlatformBrowser(platformId);
    // if(!this.isBrowser) return;

    this.routerSubscription = router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urls = event.urlAfterRedirects.split("?");
        if (orgService.userProfile?._id) {
          const trackMap: any = { type: "page" };
          trackMap.path = urls.length > 1 ? urls[1] : undefined;
          this.msp?.track(urls[0], trackMap);
        }
      }
    });

    router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        console.log('previous url', events[0].urlAfterRedirects);
        console.log('current url', events[1].urlAfterRedirects);
        pageService.previousUrl = events[0].urlAfterRedirects;
      });

    console.log("Hydration Check: TransferState keys ->", this.state.toJson());
  }

  ngOnDestroy() {
    // Unsubscribe from router events to prevent memory leaks
    this.routerSubscription?.unsubscribe();
  }

  ngAfterViewInit(){
    setTimeout(() => {
      console.log("Hydration Check: ", this.state.toJson());
    }, 1000); // ✅ Delay check
  }

  ngOnInit() {
    if(this.isBrowser){
      let url = window.location.href;
      this.seoService.updateTitleAndMeta();
      this.loadBoxData();
      // const isPreRendered = this.transferState.hasKey(PRE_RENDER_KEY);
      // if (isPreRendered) {
      //   this.transferState.remove(PRE_RENDER_KEY);
      // } else if(window?.location?.href?.includes("/p/")){
      //   this.pageService.isPrerendered = true;
      //   console.log('This URL was pre-rendered.');
      // }
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      mergeMap(route => route.data)
    ).subscribe(data => {
      console.log("[data] ac", data)
      this.uic.subProduct = data['subProduct']
    });
  }

  private async loadBoxData() {
    await BoxCache.loadBoxes()
  }
}
