<div>

  <mat-slide-toggle *ngIf="!displayConfig?.isHideWhiteLabel" [checked]="actionConfig.actionMap.whiteLabelConnection"
  (change)="whiteLabelChanged($event.checked)" color="primary"> Use whiteLabel connection</mat-slide-toggle>

  <autocomplete *ngIf="actionConfig?.actionMap?.whiteLabelConnection"
  placeholder="Select whitelabeled connection field"
  [data]="whitelabeledFields" [selectedValue]="actionConfig.actionMap?.whiteLabelMap?.widget"
  (result)="selectedWhiteLabeledConnection($event)">
  </autocomplete>


  <app-app-selection
    [existingBox]="actionConfig.actionMap.boxId"
    [config]="{isSupportStarch: true}"
    (selectedBox)="appSelected($event)"
  >
  </app-app-selection>

  <base-selection *ngIf="actionConfig.actionMap.boxId == 'starch'"
    [existingBase]="actionConfig.actionMap?.baseMap?.baseId"
      (selectedBase)="baseSelected($event)"></base-selection>

  <app-connection-selection
  *ngIf="actionConfig.actionMap.boxId && actionConfig.actionMap.boxId != 'starch'"
    [existingCon]="actionConfig.actionMap.connection"
    [config]="{ box_id: actionConfig.actionMap.boxId, focus: displayConfig?.connectionFocus }"
    (selectedConnection)="actionConfig.actionMap.connection = $event._id"
  ></app-connection-selection>

  <app-action-selection
    *ngIf="actionConfig.actionMap.boxId && actionConfig.actionMap.connection"
    [config]="{
      box_id: actionConfig.actionMap.boxId,
      connection: actionConfig.actionMap.connection,
      base_box_id: actionConfig.actionMap?.baseMap?.box_id,
      boxConfigToken: actionConfig.actionMap.boxConfigToken,
      supportCrudTypes: displayConfig?.supportActionCrudTypes,
      isListOnlyObject: displayConfig?.isListOnlyObject
    }"
    [existingActionId]="actionConfig.actionMap.action"
    (selectedAction)="actionConfig.actionMap.action = $event.__id; selectedAction($event)"
    (actionFields)="actionSelected($event)"
    (attributeOptionsInput)="attributeOptionsInput($event)"
  ></app-action-selection>
  <!-- (actionFields)="stepActionSelected($event, i)" -->
  <!-- (selectedAction)="step.action = $event.__id; stepOptions[i].action = $event" -->

  <shared-parameter-inputs  *ngIf="actionConfig.actionMap.action && actionConfig.actionMap.connection && selectedActionMap"
    [action]="selectedActionMap"
    [existingInputs]="{options: actionConfig.actionMap.inputParams}"
    (inputParameter)="inputOptionsRecevied($event)"
    [connection]="actionConfig.actionMap.connection"
    [isOnlyOptions]="true"
    [fieldListingMap]="{list: ['sourceFields'], sourceFields: sourceFields}"
    [attributesFetched]="">
  </shared-parameter-inputs>

  <headers-inputs *ngIf="selectedActionMap?.input?.headers"
    [isDynamicData]="selectedActionMap?.input?.headers?.semanticType == 'headers'"
    [headers]=""
    [existingInputs]="actionConfig.actionMap.headers"
    (inputParameter)="inputHeadersRecevied($event)"
    [sourceFields]="sourceFields"
    [systemFields]="systemFields"
  >
  </headers-inputs>

  <query-param-inputs *ngIf="selectedActionMap?.input?.queryParams"
    [isDynamicData]="selectedActionMap?.input?.queryParams?.semanticType == 'queryParams'"
    [queryData]=""
    [existingInputs]="actionConfig.actionMap.queryParams"
    (inputParameter)="inputQueryParamsRecevied($event)"
    [sourceFields]="sourceFields"
    [systemFields]="systemFields"
  >
  </query-param-inputs>

  <mat-accordion>
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header class="mapping-expansion">
        <mat-panel-title><mat-icon>swap_horiz</mat-icon><span class="mapping-title">Mapping</span></mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mapping-content">
        <app-mapping-setup *ngIf="actionFields.length > 0 || selectedActionMap?.input?.data?.semanticType == 'body'"
          (mappingChange)="onmappingChange($event)"
          [mappingArr]="actionConfig.actionMap.mapping"
          [isAllowCustomAppField]="selectedActionMap?.input?.data?.semanticType == 'body'"
          [sourceFields]="sourceFields"
          [appFields]="actionFields"
          [systemFields]="systemFields"
        >
        </app-mapping-setup>
      </div>
    </mat-expansion-panel>
  </mat-accordion> 

  <div class="outerCover">
    <mat-form-field class="innerCover">
      <mat-label>Success Message</mat-label>
      <textarea matInput
        [(ngModel)]="actionConfig.actionMap.successMessage"
       ></textarea>
    </mat-form-field>
  </div>

  <!-- source: {{sourceFields | json}} cdkTextareaAutosize cdkAutosizeMinRows="2"
  actionFields: {{actionFields | json}} -->
</div>
