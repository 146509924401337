import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PublishService } from '../services/publish.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { FormService } from 'src/app/form/form.service';
import { AuthServiceService } from '../services/auth-service.service';
import { environment } from 'src/environments/environment';
import { StarchService } from '../services/starch.service';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';

@Component({
    selector: 'form-publishing-dialog',
    templateUrl: './form-publishing-dialog.component.html',
    styleUrls: ['./form-publishing-dialog.component.scss'],
    standalone: false
})
export class FormPublishingDialogComponent implements OnInit {
  actionId: string;
  objectAttributes: any;
  submitWidget: any;
  appAction: any;
  formNameMap: any;
  listAttributes: any;
  spinner: boolean;
  formMeta: any;
  publishedUrl: string;
  publishSuccess: boolean;
  fullPublishedUrl: any;
  currentBaseUrl: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<FormPublishingDialogComponent>,
    private _snackBar: MatSnackBar,
    private connectionService: ConnectionService,
    private pageService: PageService,
    private automationService: AutomationService,
    private formService: FormService,
    private authService: AuthServiceService,
    private starchService: StarchService,
  ) {


  }

  ngOnInit(): void {
    console.log("[ListPublishingDialogModule] dialog data", this.dialogData);
    this.actionId = `${this.dialogData?.objectId}/create`;
    this.currentBaseUrl = window.location.protocol + '//' + window.location.hostname
    this.currentBaseUrl = window.location.port ? this.currentBaseUrl + ":" + window.location.port : this.currentBaseUrl
    this.appAction = {
      "event": "click",
      "action": "application",
      "actionMap": {
        boxId: this.dialogData.box.__id || null,
        connection: this.dialogData.connection._id,
        action: this.actionId,
        mapping: []
      }
    }
  }

  async actionSelected(e){
    this.objectAttributes = e;
    console.log("actionSelected", e)
    this.constructForm()
  }

  attibutesSelected(e){
    console.log("attibutesSelected in fp", e);
    this.listAttributes = e;
  }

  nameChanged(e){
    this.formNameMap = e;
  }

  async constructForm(){
    this.formMeta = await this.createNewFormMeta();
    let widgetMap = this.formService.getSubmitButton(this.formMeta);
    this.submitWidget = widgetMap.widget;
    console.log("constructForm",this.submitWidget)
  }

  attributeOptionsInput(e){

    this.appAction.actionMap.inputParams = e;
    console.log("attributeOptionsInput", e, this.appAction)
  }

  async onSave(options?:any){
    console.log()
    let data = {};
    if(options)  Object.assign(data, options)
    this.spinner = true;
    let base = await this.prepareBase(this.formNameMap.code, this.listAttributes);
    let baseMap = base?.baseMap
    let object = base?.object?.__id
    let baseAttributes = base?.attributes;
    console.log("baseAttributes", baseAttributes)
    baseAttributes.forEach(attr => {
      this.listAttributes.forEach(element => {
        if(attr.__id == element.__id) Object.assign(attr, element)
      });
      if(attr['primary'])  attr['enabled'] = false
      else attr['enabled'] = true
    })

    let submitButton:any = this.formService.formPanelButton(baseMap, object);
    let formPanel = this.formService.createFormPanel(baseMap, object);

    submitButton.id = Date.now();
    // submitButton['actionConfig']['actions'][0]['actionMap']['mapping'] = [];

    formPanel.name = 'formPanel_1';
    formPanel.id = new Date().setSeconds(new Date().getSeconds() + 50).valueOf();
    formPanel.formAttributes = baseAttributes;
    formPanel = this.automationService.generateFormWidgets(baseAttributes, formPanel, submitButton, submitButton['actionConfig']['actions'][0]['actionMap'].action)

    console.log("submitButton", submitButton)
    console.log("formPanel", formPanel);

    let tempPageMeta = await this.pageService.createPanel('button', {panels: []}, null)
    let submitPanel = tempPageMeta.panels[0];
    let colId = submitPanel.layoutMap.list[0];
    let rowId = submitPanel.layoutMap[colId].list[0];
    submitPanel.layoutMap[colId][rowId]['elements'][0] = submitButton
    submitPanel['noDelete'] = true
    submitPanel['noDrag'] = true
    submitPanel["noInsert"] = true
    submitPanel["isSubmitPanel"] = true;

    submitButton['noDelete'] = true;
    submitButton['noDrag'] = true

    console.log("submitPanel", submitPanel)

    let appActionMapping = submitButton['actionConfig']?.['actions']?.[0]?.['actionMap']?.['mapping'] || [];

    let appAction = this.appAction;
    appAction['actionMap']['mapping'] = appActionMapping;


    submitButton['actionConfig']['actions'].push(appAction);
    submitButton['actionConfig']['actions'].push(this.formService.createNavAction());
    formPanel.submitButtonMeta = submitButton;

    console.log("submitPanel", submitPanel);
    console.log("submitButton", submitButton)

    // create form and attach titlePanel, form panels and submit panel
    this.formMeta['panels'] = [this.formMeta['panels'][0], this.formMeta['panels'][1], formPanel, submitPanel]
    console.log("formMeta", this.formMeta)
    this.formMeta.name = this.formNameMap.name;
    this.formMeta.code = this.formNameMap.code;
    this.formMeta.successPageConfig = {
      actionType: 'thankyou',
      richTextContentHtml: '<h1 class="ql-align-center">🙌</h1><h1 class="ql-align-center"><strong>Thank you for submitting the form.</strong></h1><p class="ql-align-center">Your submission has been received successfully.</p>',
      externalUrl: '',
      isCelebrate: true
    }
    await this.publishForm(this.formMeta);
    this.spinner = false;
  }

  async createNewFormMeta() {
    let attributes = [];//base?.attributes

    let formPanel = this.formService.createEmptyFormPanel()
    let bannerUrl = this.formService.getImageUrl('banner')

    // create banner and attach
    let tempPageMeta = await this.pageService.createPanel('image', {panels: []}, null, null, false, bannerUrl)
    let bannerPanel = tempPageMeta.panels[0]
    bannerPanel["noDrag"] = true
    bannerPanel["noInsert"] = true
    let colId = bannerPanel.layoutMap.list[0]
    let rowId = bannerPanel.layoutMap[colId].list[0]
    let bannerWidget = bannerPanel.layoutMap[colId][rowId]['elements'][0]
    bannerWidget.config.src.value = bannerUrl
    bannerWidget.gridX = 12
    bannerWidget.gridY = 4
    console.log("banner widget", bannerWidget)

    // create form title attach
    tempPageMeta = await this.pageService.createPanel('label', {panels: []}, null)
    let titlePanel = tempPageMeta.panels[0]
    titlePanel["noDelete"] = true
    titlePanel["noDrag"] = true
    titlePanel["noInsert"] = true
    colId = titlePanel.layoutMap.list[0]
    rowId = titlePanel.layoutMap[colId].list[0]
    let titleWidget = titlePanel.layoutMap[colId][rowId]['elements'][0]
    titleWidget.config.value.value = "New form"
    titleWidget.textFormat.fontSize.value = 32
    titleWidget.textFormat.bold.value = true
    titleWidget.textFormat.color.value = "#3869a4"
    titleWidget.gridX = 12
    console.log("title widget", titleWidget)

    // add description
    tempPageMeta = await this.pageService.createPanel('label', tempPageMeta, titlePanel.id)
    console.log("tempPageMeta after desc", tempPageMeta)
    colId = titlePanel.layoutMap.list[0]
    rowId = titlePanel.layoutMap[colId].list[1]
    let descriptionWidget = titlePanel.layoutMap[colId][rowId]['elements'][0]
    descriptionWidget.config.value.value = "This is the description of the form. Customize it as per your need."
    descriptionWidget.gridX = 12
    descriptionWidget.textFormat.color.value = "#444444"
    descriptionWidget.textFormat.fontSize.value = 16

    tempPageMeta = await this.pageService.createPanel('separator', tempPageMeta, titlePanel.id)


    let submitButton:any = this.formService.createEmptyFormPanelButton();
    console.log("submitButton", submitButton)
    submitButton.id = Date.now();
    submitButton['noDelete'] = true;
    submitButton['noDrag'] = true;
    console.log("gridX set", submitButton.gridX)
    submitButton['actionConfig']['actions'][0]['actionMap']['mapping'] = [];
    formPanel.submitButtonMeta = submitButton;
    formPanel.name = 'formPanel_1';
    formPanel.id = new Date().setSeconds(new Date().getSeconds() + 50).valueOf();
    formPanel.formAttributes = attributes;
    formPanel = this.automationService.generateFormWidgets(attributes, formPanel, submitButton, submitButton['actionConfig']['actions'][0]['actionMap'].action)
    console.log("panelConfig", formPanel)

    // create submit panel and attach (will only have the decoupled submit button)
    tempPageMeta = await this.pageService.createPanel('button', {panels: []}, null)
    let submitPanel = tempPageMeta.panels[0]
    colId = submitPanel.layoutMap.list[0]
    rowId = submitPanel.layoutMap[colId].list[0]
    submitPanel.layoutMap[colId][rowId]['elements'][0] = submitButton
    submitPanel['noDelete'] = true
    submitPanel['noDrag'] = true
    submitPanel["noInsert"] = true
    submitPanel["isSubmitPanel"] = true

    // create form and attach titlePanel, form panels and submit panel
    let form: any = this.formService.createFormMeta();
    form['panels'] = [bannerPanel, titlePanel, formPanel, submitPanel]
    console.log("form meta", form)

    return form;
  }

  async publishForm(form){
    let formCreationResponse;
    this.spinner = true;
    try{
      formCreationResponse = await this.formService.create_form(form);
      console.log('form created', formCreationResponse);
      let copy = JSON.parse(JSON.stringify(form));
      copy["status"] = "published"
      delete copy._id

      let publishResponse = await this.formService.create_form(copy);
      console.log('form publishResponse', publishResponse);
      this.publishedUrl = "/form/p/" + form.code;
      if(!this.dialogData.connection.published_views){
        this.dialogData.connection.published_views = {
          object_ids: []
        }
      }
      if(!this.dialogData.connection.published_views[this.dialogData.objectId]){
        this.dialogData.connection.published_views['object_ids'].push(this.dialogData.objectId)
        this.dialogData.connection.published_views[this.dialogData.objectId] = {}
      }
      if(!this.dialogData.connection.published_views[this.dialogData.objectId]["published_forms"]){
        this.dialogData.connection.published_views[this.dialogData.objectId]["published_forms"] = {
          list_ids: []
        }
      }
      if(publishResponse._id){
        this.dialogData.connection.published_views[this.dialogData.objectId]["published_forms"].list_ids.push(publishResponse._id)
        this.dialogData.connection.published_views[this.dialogData.objectId]["published_forms"][publishResponse._id] = {
          id: publishResponse._id,
          name: this.formMeta.name,
          url: this.publishedUrl,
          logo_url: this.formMeta.logoUrl
        }
      }
      console.log("list attached to connection", this.dialogData.connection)

      let workspaceId = await this.connectionService.getWorkSpaceId();
      let updateResponse = await this.connectionService.updateConnection(this.dialogData.connection, workspaceId)
      console.log("connection updated", updateResponse);

      this.fullPublishedUrl = this.publishedUrl ? this.currentBaseUrl + this.publishedUrl : "NULL"
      this.publishSuccess = true;
    }catch(e){
      console.log("form creation failed", e)
      this.spinner = false
      this._snackBar.open("Could not create form", "Ok");
      return;
    }

    this.spinner = false
  }

  navigateToNewPublished(){
    window.open(this.fullPublishedUrl, '_blank')
  }

  async prepareBase(code: string, defaultAttributes?: any){
    return await this.formService.prepareBase(code, defaultAttributes);
  }


}

