
import { Injectable, Inject, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable()
export class SsrInterceptor implements HttpInterceptor {
  constructor(
    private state: TransferState,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Generate a unique key using the URL and stringified request body
    const uniqueKey = `${req.url}_${JSON.stringify(req.body || {})}`;
    const stateKey = makeStateKey<any>(uniqueKey);

    if (isPlatformBrowser(this.platformId)) {
      const cachedResponse = this.state.get(stateKey, null);
      if (cachedResponse) {
        console.log("[SSR] Retrieved Cached Response:", JSON.stringify(cachedResponse));
        // Ensure headers are correctly formatted
      const headers = new HttpHeaders(cachedResponse.headers || {});

      // Delay removal slightly to prevent hydration mismatch
      setTimeout(() => this.state.remove(stateKey), 1500); // Increased delay
        return of(new HttpResponse({
          body: cachedResponse.body || null,
          status: cachedResponse.status || 200,
          statusText: cachedResponse.statusText || 'OK',
          headers: headers || undefined,
          url: cachedResponse.url || req.url
        }));
      }
    }

    return next.handle(req).pipe(
      tap(event => {
        if (isPlatformServer(this.platformId) && event instanceof HttpResponse) {
          // console.log("[SSR] event", event.clone())
          this.state.set(stateKey, {
            body: event.body,
            status: event.status,
            statusText: event.statusText,
            headers:  event.headers?.keys().reduce((acc, key) => {
              acc[key] = event.headers.getAll(key);
              return acc;
            }, {} as any),
            url: event.url
          });
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error("❌ HTTP Error:", error);
        return throwError(() => error);
      })
    );
  }
}
