import { Component, EventEmitter, Input, NgModule, NgZone, Output, SimpleChanges } from '@angular/core';
import { FilterConfigModule } from '../filter-config/filter-config.module';
import { FilterSetComponentModule } from '../filter-set-component/filter-set-component.module';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule, UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

interface Condition {
  value: string;
  viewValue: string;
}

@Component({
    selector: 'app-filter-component',
    imports: [
        FilterConfigModule,
        FilterSetComponentModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatSlideToggleModule,
        MatButtonModule
    ],
    templateUrl: './filter-component.component.html',
    styleUrl: './filter-component.component.scss'
})


export class FilterComponentComponent {
  @Input() filter;
  @Input() options;
  @Input() boxObjectAttributes;
  @Input() enableSecurity;
  @Input() supportApplyButton: boolean;
  @Input() hideToggle: boolean;
  @Input() maskOperator: boolean;
  @Input() lockFilterType: boolean;
  @Input() showAttributePicker: boolean;
  @Input() fieldListingMap: any;
  @Input() boxObjectFunction: any;
  @Input() pageMeta: any; // if pageMeta is supplied, page reference map will be created for in field picker
  @Output() "selectedFilter" = new EventEmitter<any>();
  @Output() "cancelFilter" = new EventEmitter<any>();
  @Output() filtersValid = new EventEmitter<any>();

  outerCondition: Condition[] = [
    {value: 'and', viewValue: 'And'},
    {value: 'or', viewValue: 'Or'},
  ];
  selectedOuterCondition = this.outerCondition[0].value;

  isFilterValid: boolean;
  selectFilterAttributeForm = new UntypedFormGroup({});
  isShowFilterGroup: boolean = false;
  filterableAttributes: any = [];

  constructor(private ngZone: NgZone){

  }

  //{issupportgroupfilter:true,relationalOperators:['and','or']}
  
  ngOnInit(): void {
    this.filterableAttributes = this.boxObjectAttributes.filter(attr => attr.filterable === true);
    if (this.options?.isSupportGroupfilter) this.isShowFilterGroup = true;

    /*In case some pre existing filters [externalFilters] are required to be made read Only, we identify from the flag passed inside the options and
      in that case for each and every filterItems we are attaching a property called 'readOnly' (Temporarily. Not saved in meta) and making un-editable in the GUI.'*/
    if(this.options?.isExistingFiltersReadOnly === true){
      if(this.filter?.filterItems.length){
        let replaceFilterItems = [];
        for(let item of this.filter?.filterItems){
          item['readOnly'] = true;
          replaceFilterItems.push(item);
        }
        this.filter.filterItems = replaceFilterItems;
      }
    }

    if(this.filter) {
      if(!this.filter?.operator) this.filter.operator = 'and';
      this.selectedOuterCondition = this.filter?.operator || this.outerCondition[0].value;
    }
  }

  saveFilterConfig(event){
    this.selectedFilter.emit(event);
  }

  cancelFilterInfo(){
    this.cancelFilter.emit(true)
  }

  filterValid(event){
    this.filtersValid.emit(event)
  }

  selectOuterCondition(event){
    this.selectedOuterCondition = (event.target as HTMLSelectElement).value;
    this.filter.operator = this.selectedOuterCondition
    this.selectedFilter.emit(this.filter)
  }

  filterToggleChanged(toggleValue: any){
    console.log(toggleValue)
    this.filter.filterEnabled = toggleValue.checked;
    if(this.filter.filterEnabled == false){
      this.isShowFilterGroup = false
      this.filter.filterItems = [];
      if(this.filter?.operator) delete this.filter.operator
      this.emitFilterOnInput()
    } else {
      if (this.options?.isSupportGroupfilter) this.isShowFilterGroup = true;
      if(!this.filter?.operator) this.filter.operator = 'and';
      this.selectedOuterCondition = this.filter?.operator || this.outerCondition[0].value;
    }
    if(this.filter.filterEnabled && (!this.filter.filterItems || this.filter.filterItems.length == 0)){
      this.addFilterItem();
    }
    this.checkValidation()
  }

  emitFilterOnInput(){
    this.checkValidation();
    if (this.supportApplyButton) return;
    this.selectedFilter.emit(this.filter)
  }

  addFilterItem(){
    this.filter.filterItems.push({
      attribute: '',
      operator: '=',
      value: '',
      dataType: '',
      filterType: 'static_filter'
    })
    this.selectFilterAttributeForm.addControl("form" + (this.filter.filterItems.length - 1), new UntypedFormControl())
    this.checkValidation()
  }
  
  checkValidation(){
    console.log("filter", this.filter?.filterItems)
    this.isFilterValid = true;
    if(!this.filter.filterEnabled) return;
    for (let index = 0; index < this.filter?.filterItems.length; index++) {
      const element = this.filter?.filterItems[index];
      if(!element.attribute) this.isFilterValid = false;
      if(!["~", "!~"].includes(element.operator) && !element.value) this.isFilterValid = false;
    }
    this.filtersValid.emit(this.isFilterValid)
  }
}
