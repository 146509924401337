import { WidgetUtilityService } from "../../services/widget-utility.service";
import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class DateInput extends Widget {
  type = 'date';
  gridX = 6;
  minGridX = 3;
  config = {
    props: [
      // "presetDate",
      'tooltip',
      // 'customOutputFormat',
      'viewOnly',
      'appearance',
    ],

    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    customOutputFormat: {
      displayName: "Custom output date format",
      type: 'date-time-format-input',
      enabled: false,
      customDate: true,
      customDateFormat: 'MM-dd-YYYY',
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the value',
      value: false,
      type: 'boolean',
      resetValue: false
    }
  }

  //text formatting properties
  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)

    this.config['title'].value = 'Choose a date';
    this.config['value'].resetValue = '';
    this.config['defaultValue'].valueType = 'date';
    this.config['placeholder'].value = 'Enter date';

     //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
  }

  public getMappingConfig() {
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig(){
    let map = {
      valuePath: "config.value.value"
    }
    return map;
  }

  createFormattedValue(rawDateTimeValue){
    if(!rawDateTimeValue) return ''
    // console.log("in local time", WidgetUtilityService.toIsoString(new Date(rawDateTimeValue)))
    const rawDateTime = (rawDateTimeValue !== '__currentdatetime__') ? new Date(rawDateTimeValue) : new Date()
    let val = WidgetUtilityService.toIsoString(rawDateTime)
    return val || ''
  }

  public getWidgetMeta() {
    return this
  }
}
