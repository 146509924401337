<div class="cover">
  <div class="gap" style="height: 1rem;"></div>

  <div class="section">
    <div *ngIf="['choice', 'select'].includes(widgetMeta?.type) && authService?.subproduct == 'form' && formService?.validSections?.length">
      <mat-slide-toggle (change)="valueBasedFormSectionChanged();" [(ngModel)]="isSectionBasedButton">Go to section based on answer</mat-slide-toggle>
    </div>

    <div class="sectionHeading">{{ staticOptions.length ? "Options" : "There are no static options for this widget" }}</div>
    <div class="gap" style="height: .5rem;"></div>
    <div class="sectionBody" cdkDropList (cdkDropListDropped)="drop($event)">
      <div
        style="width: 100%; cursor: default;"
        class="optionItem"
        *ngFor="let option of staticOptions;
        let i = index"
        cdkDrag
        (mouseenter)="hoveredIndex = i" (mouseleave)="hoveredIndex = -1"
        fxLayout="row wrap" fxLayoutAlign="flex-start center"
        [ngClass]="{ 'editing-border': editModeIndex === i }"
      >

      <div>
        <div class="dragIcon optionChild" fxLayoutAlign="center center" fxFlex="6" matTooltip="Rearrange">
          <mat-icon cdkDragHandle style="cursor: move;" class="icon" [style.visibility]="hoveredIndex === i ? 'visible' : 'hidden'">drag_indicator</mat-icon>
        </div>

        <div class="serial optionChild" fxLayoutAlign="center center" fxFlex="6">{{ i + 1 }}</div>

        <div class="radio optionChild" fxLayoutAlign="flex-start center" fxFlex="13">
          <mat-checkbox
            fxLayoutAlign="center center"
            matTooltip="Set Default Option"
            color="primary"
            (change)="defaultChanged($event, i)"
            [value]="option.value"
            [checked]="option.default"
          >
          </mat-checkbox>
        </div>

        <div class="name optionChild" fxFlex="45" fxLayoutAlign="flex-start center">{{ option.name }}</div>

        <div class="edit optionChild" fxLayoutAlign="center center" fxFlex="15">
          <ng-container *ngIf="editModeIndex !== i; else editModeIcon">
            <button mat-icon-button class="smallIcon" (click)="editOption(i)" matTooltip="Edit Option"><mat-icon class="icon">edit</mat-icon></button>
          </ng-container>
          <ng-template #editModeIcon>
            <button mat-icon-button class="smallIcon saveIcon" (click)="saveEditedOption()" matTooltip="Save"><mat-icon class="icon">done</mat-icon></button>
          </ng-template>
        </div>

        <button mat-icon-button class="optionChild smallIcon deleteIcon" fxFlex="15" color="warn" (click)="deleteOption(i)" matTooltip="Delete Option"><mat-icon class="icon">delete</mat-icon></button>
      </div>

        <div
          *ngIf="editModeIndex === i"
          fxFlex="100"
          fxLayout="column" fxLayoutAlign="center center"
          style="box-sizing: border-box;"
        >
          <mat-form-field class="nameForm noPaddingBottom">
            <mat-label>Display name</mat-label>
            <input matInput type="text" [(ngModel)]="option.name" style="width: 100%">
          </mat-form-field>
          <div style="height: .5rem;"></div>
          <mat-form-field class="valueForm noPaddingBottom">
            <mat-label>Value</mat-label>
            <input matInput type="text" [(ngModel)]="option.value" style="width: 100%">
          </mat-form-field>
        </div>


        <div *ngIf="authService?.subproduct == 'form' && isSectionBasedButton" class="formSection">
          <div fxLayoutAlign="center center" fxFlex="6">
          </div>
          <mt-select
          [list]="formService?.validSections" [selectedValue]="option?.formSection || '_next'"
          [options]="{'viewStyle': 'compact'}"
          (inputReceived)="formConditionInputReceived($event, option)"
          ></mt-select>
        </div>


        <!-- <div
          fxFlex="20"
          fxLayout fxLayoutAlign="space-around center"
          style="box-sizing: border-box;"
        >
          <div
            fxFlex="84"
            *ngIf="editModeIndex === i"
            fxLayout fxLayoutAlign="space-around center"
            style="box-sizing: border-box;"
          >
            <mat-form-field class="nameForm noPaddingBottom" fxFlex="42">
              <input matInput type="text" [value]="option.name" (input)="optionNameChanged($event, i)" style="width: 100%">
            </mat-form-field>
            <mat-form-field class="valueForm noPaddingBottom" fxFlex="42">
              <input matInput type="text" [value]="option.value" (input)="optionValueChanged($event, i)" style="width: 100%">
            </mat-form-field>
          </div>

        </div> -->

        <!-- <div class="edit optionChild" fxLayoutAlign="center center" fxFlex="8">
          <ng-container *ngIf="editModeIndex !== i; else editModeIcon">
            <button mat-icon-button class="small-icon-button" (click)="editOption(i)" matTooltip="Edit Option"><mat-icon class="icon">edit</mat-icon></button>
          </ng-container>
          <ng-template #editModeIcon>
            <button mat-icon-button class="small-icon-button" (click)="saveEditedOption()" matTooltip="Done"><mat-icon class="icon">done</mat-icon></button>
          </ng-template>
        </div>

        <button mat-icon-button class="small-icon-button" fxFlex="8" (click)="deleteOption(i)" matTooltip="Delete Option"><mat-icon class="icon">delete</mat-icon></button> -->
        <!-- <div class="delete optionChild" fxLayoutAlign="center center" fxFlex="4" matTooltip="Delete Option">
          <mat-icon class="icon" (click)="deleteOption(i)">delete</mat-icon>
        </div> -->

      </div>
      <!-- <mat-radio-group style="width: 80%">
      </mat-radio-group> -->

      <div class="gap" style="height: .5rem;"></div>

      <div fxLayout fxLayoutAlign="flex-start center" class="optionAction" fxLayoutGap="1.5rem">
        <button mat-stroked-button color="primary" (click)="addNewStaticOption()">
          <div fxLayoutAlign="space-around center">
            <mat-icon>add</mat-icon>
            <span>New Static Option</span>
          </div>
        </button>

        <div fxLayoutAlign="flex-start">
          <span *ngIf="defaultCount" class="clearDefault" (click)="clearDefaultStatic(); saveChanges()">Clear Default Option</span>
        </div>
      </div>

    </div>
  </div>


  <div style="height: 1.5rem;"></div>
  <div class="section">
    <mat-slide-toggle [checked]="dynamicOptions.enabled" (change)="dynamicOptionsToggle($event)" color="primary">Enable Dynamic Options</mat-slide-toggle>

    <div style="height: 1rem;"></div>
    <div *ngIf="dynamicOptions.enabled" class="dynamicOptionsFieldset">
      <div class="dynamicOptionsLegend">Dynamic Options</div>
      <div style="height: 1.5rem;"></div>

      <section class="example-section">
        <mat-checkbox class="example-margin" (change)="userDataSelected()" [(ngModel)]="dynamicOptions.userData">Use user data</mat-checkbox>
      </section>

      <widget-data-source-box-config *ngIf="!dynamicOptions.userData"
        [dataSource]="dynamicOptions"
        (newDataSource)="newDynamicDataSourceRceived($event)"
      >
      </widget-data-source-box-config>

      <div *ngIf="isAttributesReady" fxLayout="column" fxLayoutAlign="center stretch">
        <div style="height: 1rem"></div>
        <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="1rem" style="width: 100%">
          <mat-form-field class="noPaddingBottom" appearance="outline">
            <mat-label>Select Name Attribute</mat-label>
            <input
              #nameAttributeInput
              type="text"
              aria-label="attribute autocomplete"
              matInput
              [formControl]="nameAttributeControl"
              [matAutocomplete]="nameAttributeInputAuto"
            >
            <button *ngIf="nameAttributeInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="nameAttributeControl.patchValue('')">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #nameAttributeInputAuto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="nameAttributeSelected($event); ">
              <mat-option *ngFor="let attr of filteredNameAttributes | async; trackBy: trackByFn" [value]="attr">
                <span>{{attr.__id}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <div *ngIf="advancedModeOpenName">
            <mat-form-field class="nameForm noPaddingBottom" appearance="outline">
              <mat-label>Name Path</mat-label>
              <input matInput type="text" [(ngModel)]="namePath">
            </mat-form-field>
          </div>

          <button mat-icon-button [matTooltip]="advancedModeOpenName ? 'Close Advanced Mode' : 'Open Advanced Mode'" fxLayoutAlign="center center" fxLayoutGap=".5rem" (click)="advancedModeOpenName = !advancedModeOpenName">
            <mat-icon class="icon" >engineering</mat-icon>
          </button>
          <!-- <ng-container *ngIf="!advancedModeOpenName; else advancedModeForName">
          </ng-container> -->
        </div>

        <div style="height: .5rem"></div>

        <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="1rem" style="width: 100%">
          <mat-form-field class="noPaddingBottom" appearance="outline">
            <mat-label>Select Value Attribute</mat-label>
            <input
              #valueAttributeInput
              type="text"
              aria-label="attribute autocomplete"
              matInput
              [formControl]="valueAttributeControl"
              [matAutocomplete]="valueAttributeInputAuto"
            >
            <button *ngIf="valueAttributeInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="valueAttributeControl.patchValue('')">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #valueAttributeInputAuto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="valueAttributeSelected($event); ">
              <mat-option *ngFor="let attr of filteredValueAttributes | async; trackBy: trackByFn" [value]="attr">
                <span>{{attr.__id}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <div *ngIf="advancedModeOpenValue">
            <mat-form-field class="nameForm noPaddingBottom" appearance="outline">
              <mat-label>Value Path</mat-label>
              <input matInput type="text" [(ngModel)]="valuePath">
            </mat-form-field>
          </div>

          <button mat-icon-button [matTooltip]="advancedModeOpenValue ? 'Close Advanced Mode' : 'Open Advanced Mode'" (click)="advancedModeOpenValue = !advancedModeOpenValue">
            <mat-icon class="icon">engineering</mat-icon>
          </button>

        </div>
        <!-- <div fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap=".5rem">
        </div> -->

        <!-- FILTER -->
        <ng-container *ngIf="dynamicOptions.filter && !dynamicOptions.userData">
          <!-- <app-filter-config
            [filter]="dynamicOptions.filter"
            [boxObjectAttributes]="attributes"
            [supportApplyButton]="false"
            [hideToggle]="false"
            [lockFilterType]="true"
            (selectedFilter)="filterSelected($event)"
          >
          </app-filter-config> -->
          <app-filter-component
            [filter]="dynamicOptions.filter"
            [boxObjectAttributes]="attributes"
            [supportApplyButton]="false"
            [hideToggle]="false"
            [lockFilterType]="true"
            (selectedFilter)="filterSelected($event)"
          ></app-filter-component>
        </ng-container>
      </div>
    </div>
  </div>

  <div style="height: 1rem;"></div>

</div>
