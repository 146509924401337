import { Component, ChangeDetectionStrategy, OnInit, Inject, Input, Output, ViewChild, OnChanges, SimpleChanges, NgZone, OnDestroy, EventEmitter, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { MetaService } from '../../services/meta-service';
import { PageService } from '../../services/page-service.service';
import { BoxService } from '../../services/box-service.service';

import { PanelComponent } from '../../page/panel/panel.component';
import { ListPanelDialogComponent } from './list-panel-dialog/list-panel-dialog.component';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { WidgetManager } from '../../models/WidgetManager';
import { ListPanelService } from './list-panel.service';
import { ListPanelPopupComponent } from '../../../shared/list-panel-popup/list-panel-popup.component';
// import { ListPanelPopupModule } from 'src/app/shared/list-panel-popup/list-panel-popup.module';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { ActivatedRoute } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { WIDGET_OPTIONS, WidgetUtilityService } from '../../services/widget-utility.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SystemFieldsService } from 'src/app/shared/services/system-fields.service';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';
import { UrlParamsService } from 'src/app/shared/services/URLParamService';
import { ResourceDeletionService } from 'src/app/shared/services/resource-deletion.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';

interface Page {
  number: number,
  size: number,
  total: number,
  nextPageToken?: string,
  previousPageToken?: string
}

@Component({
    selector: 'app-list-panel',
    templateUrl: './list-panel.component.html',
    styleUrls: ['./list-panel.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListPanelComponent extends PanelComponent implements OnInit, OnChanges, OnDestroy {

  @Input() panelMeta;
  @Input() refreshData:boolean = false;
  @Input() builderMode;

  // if true, the delete by id will be handled internally, i.e. delete confirmation dialog will be triggered from inside list panel
  // if false, rowData will be emitted to the parent component with appropriate actionId and data for deletion in parent
  // @Input() handleDeleteInternally: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input() inputData;
  @Input() isDynamicWidth: boolean; //If true, do the list panel size calculation.
  @Output() rowData = new EventEmitter();
  @Output() checkboxSelected = new EventEmitter();
  @Output() filterObj = new EventEmitter();

  @ViewChild('filterMenu') filterMenu: MatMenuTrigger
  @ViewChild('sortMenu') sortMenu: MatMenuTrigger

  pageMeta: any;
  pageSizeOptions = [5, 10, 20, 50, 100, 200];
  panelMetaLocal: any;
  tableData: any = [];
  pageTokenArr: any = ['',];
  gotResponse: boolean;
  dataBindConfigResult: any;
  dataLength: any;
  displayedColumns: any[];
  dataSource: any;
  margin: any;
  listPanelSize: number = 1;
  getScreenWidth: any;
  showSinglePagePagination: boolean = false
  dataTableContainerStyles: any;
  // loadingData: boolean = false

  readyTableData: any = undefined;

  searchPanelId: number = 0

  searchFilterSubscription: any;
  runSpinner: boolean = false;
  dataLoadError: any

  currentPageCode: string;
  page: Page = {
    number: 1,
    size: 20,
    total: 0,
    nextPageToken: undefined,
    previousPageToken: undefined
  };

  currentViewType: string
  rawBoxData: any
  _multiView: boolean = false
  pageEventfirstHit: boolean;
  isFilterSupported: boolean = false;
  isSortSupported: boolean = false;
  get multiView(){
    return this._multiView;
  }

  set multiView(val){
      this._multiView = val;
  }
  viewCount: number = 0;

  dataModel: any

  lastNavigationDataSubscriptionId:any

  currentPageCodeSubscription: any
  routeParameterSubscription: any
  navigationDataSubscription: any
  dataModelSubscription: any
  pageMetaSubscription: any;
  routeDataSubscription: any
  currentFilters: any;
  currentEvent: any;
  isNoMorePage: boolean;

  dataSources: any[] = [
    // { source: 'searchpanelresult', precedence: 3},
    { source: 'navigation', precedence: 2},
    { source: 'selfload', precedence: 1}
  ]
  dataLoadLockOwner: string = ''    // navigation | searchpanelresult | selfload

  dataModelTriggerFired: boolean = false;
  isInputData: boolean = false;
  availableAttributes: any = [];
  selectedAttributes: any = [];
  customOrder: any
  isNavigationFilterReceived: boolean;
  isPageFilterReceived: boolean;

  userFilter: any[] = [];
  searchPanelFilters: any[] = []
  internalFilters: any[] = []
  externalFilters: any[] = []
  userSort: any[] = [];
  externalSort: any[] = []
  isQueryFilterReceived: boolean;
  isCustomDataSource: boolean;
  customDataSourceSubscription: any;

  showPagination: boolean = false;
  lastUsedDataConfig: any

  pageModel: any;
  pageModelSub: any;

  isLoadingMore: boolean = false;   // indicates if single page pagination is in progress

  deletionBuffer: any
  chartWidth: any = '32';
  initialDataLoaded: boolean = false;

  chartOptions: WIDGET_OPTIONS = {
    hideChartIfNoData: true
  }

  @ViewChild("menu5Trigger") menu5Trigger!: MatMenuTrigger;

  constructor(
    private boxService: BoxService,
    private metaService: MetaService,
    private _snackBar: MatSnackBar,
    private TokenUtil: TokenUtil,
    public pageService: PageService,
    private dialog: MatDialog,
    private zone: NgZone,
    private spinnerService: SpinnerService,
    private listPanelService: ListPanelService,
    public resourcePermissionService: ResourcePermissionService,
    public route: ActivatedRoute,
    private widgetUtilityService: WidgetUtilityService,
    public cdr: ChangeDetectorRef,
    private systemFieldsService: SystemFieldsService,
    private httpCacheService: HttpCacheService,
    private urlService: UrlParamsService,
    private resourceDeletionService: ResourceDeletionService,
    public authService: AuthServiceService,
    // private templateEngine: TemplateEngine,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      super()
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    }

  async createSubListPanel(input){
    if(!this.isBrowser) return;
    console.log("createSubListPanel", input)
    let array = []
    let data = input.data;

    if(input.type != 'arrayofobjects'){
      for(let key in data){
        let obj = {};
        if(input.type == 'arrayofprimitives') obj['values'] = data[key];
        else {
          obj['key'] = key;
          obj['value'] = data[key];
        }
        array.push(obj);
      }
    } else array = data;

    if(array && array.length > 0) {
      let subPanelMeta:any = {} ;
      subPanelMeta['panelMeta'] = this.listPanelService.createSubPanelMeta(array);
      this.listPanelService.processSubData(array, subPanelMeta);
      subPanelMeta.isInputData = true;
      subPanelMeta.key = input?.key;

      this.listPanelService.handleSubListView(subPanelMeta);
      subPanelMeta.isSubPanel = true;

      this.listPanelService.subPanelMetas.push(subPanelMeta);

    }


    if(this.listPanelService.subPanelMetas?.length > 0){
      this.listPanelService.subPanelMeta = this.listPanelService.subPanelMetas[this.listPanelService.subPanelMetas.length - 1];
    }

    let dialogRef = this.dialog.open(ListPanelPopupComponent, {
      // minHeight: '50vh',
      // minWidth: '60vw',
      // maxHeight: '90vh',
      data: {
      },
    })

    let dialogRes = await dialogRef.afterClosed().toPromise();
    console.log("dialogRes", dialogRes)
  }

  async ngOnInit() {
    this.getScreenWidth = window.innerWidth;
    console.log("panel meta received in list panel component: ", JSON.parse(JSON.stringify(this.panelMeta || '')));
    this.isFilterSupported = this.panelMeta?.getFn?.options?.filter
    this.isSortSupported = this.panelMeta?.getFn?.options?.sort
    if(this.panelMeta) this.panelMetaLocal = JSON.parse(JSON.stringify(this.panelMeta))
    this.constructAvailableFilterValues();
    this.metaService.formPanelLoaded = false;
    console.log("INPUT DATA", this.inputData);
    this.inputData = JSON.parse(JSON.stringify(this.inputData || null))
    if(this.inputData) { // && this.inputData.length > 0
      this.handleInputData()
      return;
    }
    this.handleListView(this.panelMetaLocal)
    if(this.panelMetaLocal?.margin){
      this.margin = this.panelMetaLocal.margin
    }

    this.setListPanelSize(this.getSelectedColumns())
    // // set default pagination info
    // this.page.number = this.panelMetaLocal.defaultPageSize'
    if (this.panelMetaLocal.maxPerPageLimit) {
      this.pageSizeOptions = this.pageSizeOptions.filter((pageSize) => (pageSize <= this.panelMetaLocal.maxPerPageLimit))
    }

    this.currentPageCodeSubscription = this.pageService.currentPageCode.subscribe(code => {
      // console.log("current page code subscription", code)
      this.currentPageCode = code
    })

    this.pageMetaSubscription = this.metaService.pageMeta.subscribe(meta => {
      if(this.currentPageCode == meta?.code){
        this.pageMeta = meta;
      }
    })


    /**
     * page filter based data load will be triggered from here
     */
    // this.dataModelSubscription =  this.pageService.$dataModelSub.subscribe(async dataModel => {
    //   if(this.pageMeta?.code !== this.currentPageCode) return
    //   if(this.pageMeta?._id !== dataModel._pageId) return
    //   if(this.dataModelTriggerFired) return
    //   if(!dataModel || Object.keys(dataModel).length <= 1) return

    //   this.dataModel = dataModel

    //   if(!this.panelMetaLocal?.filter?.filterEnabled || !this.panelMetaLocal?.filter?.filterItems?.filter(f => f.filterType == 'page_filter')?.length) return
    //   console.log("dataModel received in list panel", this.dataModel)

    //   if(this.pageFilterReplacer().status){
    //     this.dataModelTriggerFired = true
    //     console.log("calling data from dataModel sub")

    //     let effectivePageFilters: any[] = []
    //     if(this.panelMetaLocal.filter && this.panelMetaLocal.filter.filterEnabled && this.panelMetaLocal.filter.filterItems.length){
    //       let filterResult: any = this.pageFilterReplacer()
    //       if(filterResult.status){
    //         effectivePageFilters = filterResult.filters
    //         console.log("filters prepared", filterResult.filters)
    //       }else{
    //         console.log("filters not available. can not fetch data")
    //         return
    //       }
    //     }
    //     console.log("calling load data from dataModelSub: panelMeta", JSON.parse(JSON.stringify(this.panelMetaLocal)))
    //     console.log("[load-Data] 2")
    //     await this.loadData(effectivePageFilters)
    //   }
    // })

    this.routeDataSubscription = this.route.queryParams.subscribe(async params => {
      if(!params || Object.keys(params).length == 0){
        params = this.urlService.parseUrlToObj();
      }
      if(Object.keys(params).length) this.isQueryFilterReceived = true;
      this.initParamsLoad(params);
    })

    // SUBSCRIBE TO PAGE SERVICE'S SEARCH RESULT BUFFER IF DATA SOURCE IS A SEARCH PANEL
    this.searchFilterSubscription = this.pageService.$searchResultBuffer.subscribe(async res=>{
      console.log("data from search panel", res)
      if(!res || !res.searchInputValues || (res.outputListPanelId !== this.panelMetaLocal.id)) return
      this.searchPanelId = res.searchPanelId;

      this.page.number = 1
      this.page.nextPageToken = undefined
      this.page.previousPageToken = undefined
      // this.listPanelService.setPaginationBackup(this.page)

      // let lastUsedConfig: any = this.lastUsedDataConfig
      // let filters = lastUsedConfig?.filters?.length ? lastUsedConfig.filters : [];

      if(['and', 'or'].includes(res.searchInputValues.operator) && res.searchInputValues.filterItems?.length){
        const filters = [];
        const formattedFilterObj = JSON.parse(JSON.stringify(res.searchInputValues));
        const filterItems = formattedFilterObj.filterItems;
        for (const key in filterItems) {
          const obj = {
            attribute: filterItems[key].attribute.__id,
            dataType: filterItems[key].dataType || "string",
            filterType: "static_filter",
            operator: filterItems[key]?.attribute?.defaultOperator || "%",
            value: filterItems[key].value
          }
          filters.push(obj)
        }
        formattedFilterObj.filterItems = filters.filter(f => f.value)
        await this.loadData(formattedFilterObj)

      }else{
        let filters = []
        for (const key in res.searchInputValues) {
          let obj = {
            attribute: key,
            dataType: res.searchInputValues[key].dataType || "string",
            filterType: "static_filter",
            operator: res.searchInputValues[key]?.attribute?.defaultOperator || "=",
            value: res.searchInputValues[key].value
          }
          filters.push(obj)
        }
        this.searchPanelFilters = filters.filter(f => f.value)
        this.externalFilters = this.searchPanelFilters
        this.listPanelService.setFiltersBackup(this.externalFilters, this.panelMeta.id)

        console.log("calling load data from searchResultSub")
        console.log("[load-Data] 3")
        await this.loadData(this.searchPanelFilters)
      }
    })

    this.customDataSourceSubscription = this.pageService.$customDataSource.subscribe(async(data:any) => {
      const widget = data;
      if(this.panelMetaLocal.id == data.id && this.panelMetaLocal.dataSource == 'custom'){

        let data = widget.customData;
        if(data && data.length > 0) {
          console.log("widget", widget);
          this.inputData = data;
          console.log("panel meta received in list panel component:created ", this.panelMetaLocal);
          // this.createPanelMeta(this.panelMetaLocal);
          this.rawBoxData = this.inputData; // todo - this should be set after paginated
          this.processData(this.inputData);
          this.isCustomDataSource = true;
          this.handleListView(this.panelMetaLocal)
          return;
        }
      }
    })

    this.pageModelSub = this.pageService.$pageModelSub.subscribe(async pageModel => {
      // console.log("page model subscription in list panel", JSON.parse(JSON.stringify(pageModel)))
      // console.log("local filters in list panel", JSON.parse(JSON.stringify(this.panelMetaLocal?.filter?.filterItems || [])))
      this.pageModel = JSON.parse(JSON.stringify(pageModel))
      // if(!this.readyTableData) return // if data
      let isFilterChanged: boolean = false
      let replacedFilters
      let unreplacedFilterItems = JSON.parse(JSON.stringify(this.panelMeta?.filter?.filterItems || []))
      if(unreplacedFilterItems.length) replacedFilters = this.resolvePageRefTemplatesInFilter(JSON.parse(JSON.stringify(pageModel)), unreplacedFilterItems, true)
      // console.log("replaced filters", JSON.stringify(replacedFilters))
      // console.log("local filters", JSON.stringify(this.panelMetaLocal?.filter?.filterItems || []))
      if(JSON.stringify(replacedFilters) !== JSON.stringify(this.panelMetaLocal.filter?.filterItems || [])) {
        isFilterChanged = true
      }

      this.panelMetaLocal?.filter?.filterItems.forEach(f => {
        let equivalentReplacedFilter = replacedFilters?.find(rf => rf.__id == f.__id && rf.operator == f.operator)
        if(f.value != equivalentReplacedFilter?.value) {
          isFilterChanged = true
        }
      })
      if(!isFilterChanged) return

      //if there are templated unreplaced value, so assuming they are not found or not effective filter values, we are removing them from the filteritems
      replacedFilters = replacedFilters?.filter(f => {
        return (f.value || f.value == false) && f.value.match(/\$\{[^\}]+\}/g) == null
      }) || []
      // && JSON.stringify(this.lastUsedDataConfig?.filters) !== JSON.stringify(replacedFilters)
      if(replacedFilters.length && isFilterChanged) {
        this.panelMetaLocal.filter.filterItems = replacedFilters

        let paginationBackup = this.listPanelService.getPaginationBackup()

        if(paginationBackup?.panelId == this.panelMeta.id) {
          this.page = paginationBackup?.data || this.page
        } else {
          this.page.size = this.panelMetaLocal.defaultListSize || 10;
        }
        if(!this.isNavigationFilterReceived && !this.isQueryFilterReceived) {
          this.loadData(replacedFilters)
          this.isPageFilterReceived = true;
        }
      }
    })

    this.selectedAttributes = this.panelMetaLocal.listAttributes;

    this.initDefaultLoad()
    // this.cdr.detectChanges();

    //for preview mode take the param value from service and init
    if(this.metaService.previewMode && this.metaService.bloomPreviewMap[this.currentPageCode]) {
      let params = this.urlService.parseUrlToObj(this.metaService.bloomPreviewMap[this.currentPageCode].pathParams);
      this.initParamsLoad(params);
    }

  }

  async initDefaultLoad(){
    if(this.panelMetaLocal.loadInitialData && !this.isNavigationFilterReceived && !this.isQueryFilterReceived && !this.isPageFilterReceived){
      this.externalFilters = this.listPanelService.getFilterBackup(this.panelMeta.id)
      console.log("filter backup restored", this.externalFilters)
      this.externalSort = this.listPanelService.getSortBackup()
      let paginationBackup = this.listPanelService.getPaginationBackup()

      if(paginationBackup?.panelId == this.panelMeta.id) {
        this.page = paginationBackup?.data || this.page
      } else {
        this.page.size = this.panelMetaLocal.defaultListSize || 10;
      }

      let options = {clearCache: this.refreshData};
      console.log("[load-Data] 5")
      this.initialDataLoaded = true;
      await this.loadData([], [], options)
      this.cdr.detectChanges();
    }
  }

  async initParamsLoad(params){
    console.log("query params received in list panel: ", params, this.isNavigationFilterReceived);
      let isLoadValid = true;
      if(params.isRefresh) this.refreshData = true
      let configuredNavFilters = this.panelMetaLocal?.filter?.filterItems?.filter(f => f?.filterType == 'navigation_filter');
      if(!configuredNavFilters?.length) {isLoadValid = false;}

      let navFilters = [];
      for (const keyRaw in params) {
        let key = decodeURIComponent(keyRaw)
        if(key != "v") {
          navFilters.push({
            attributeId: key,
            dataType: "string",
            value: decodeURIComponent(params[key])
          })
        }
        console.log("query param based filters prepared", navFilters, configuredNavFilters)
      }

      // check if at least one configured filters of navigation type has corresponding values coming in from query params
      let isFilterAvailable: boolean = false;
      for (let i = 0; i < configuredNavFilters.length; i++) {
        let f = configuredNavFilters[i]
        let filterFoundInParams = navFilters.find(filter => filter.attributeId == f.__id || f.value)
        if(filterFoundInParams) {
          isFilterAvailable = true;
          break;
        }
      }
      if (!isFilterAvailable) {
        console.log("none of the configured navigation filters have corresponding values in query params")
        isLoadValid = false;
        return
      }
      if(!this.inputData?.length && !this.isNavigationFilterReceived && isLoadValid) {
        this.currentFilters = this.navigationFilterReplacer(navFilters);
        console.log("calling load data from queryParams subscription")
        this.isQueryFilterReceived = true;
        console.log("[load-Data] 4", this.currentFilters)
        await this.loadData(this.currentFilters);
      } else if(!this.initialDataLoaded) { //if default load not done
        this.initDefaultLoad();
      }
  }


  getSelectedColumns() {
    return this.panelMetaLocal.listAttributes.reduce((columnsCount, currAtrr) => {
      if(currAtrr['isColumnSelected'] && ['attribute', 'label'].includes(currAtrr['fieldType'])) {
        columnsCount += 1;
      }
      return columnsCount;
    }, 0)
  }

  /**
   * example 1:
   * f1 || f2 || (f3 && f4)
    {
      operator: "or",
      filterItems: [
        {f1}, {f2}, { operator: 'and', filterItems: [ {f3}, {f4} ]}
      ]
    }
    */

  /**
   * example 2:
   * f1 && (f2 || f3)
    {
      operator: 'and',
      filtersItems: [
        {f1}, {operator: 'or', filterItems: [ {f2}, {f3} ]}
      ]
    }
    */
  /**
   * handles new extensible data structure for filters with mixed operators
   * @param filter: any (object: example given above)
   * @returns a filter string recursively created from multi-level filter object
   *
   * TODO: need to adjust for parentheses when multi level object actually comes
   */
  constructFilterString(filter: any): string {
    console.log("FILTER : ",filter)//del
    let filterStr = "";

    // Check if it is the top-level filter
    if (filter.filterEnabled && filter.filterItems?.length) {
      const delim = filter.operator === 'and' ? ',' : ';';

      // Process each filter item in the top-level filter
      filterStr = filter.filterItems
          .map((f) => this.constructFilterString(f))
          .filter(Boolean) // Remove empty strings
          .join(delim);
  }
  // Handle group filters
  else if (filter.filterItems?.length) {
      const delim = filter.operator === 'and' ? ',' : ';';

      const nestedFilterStr = filter.filterItems
          .map((f) => this.constructFilterString(f))
          .filter(Boolean)
          .join(delim);

      // Wrap group filters with braces
      filterStr += `{${nestedFilterStr}}`;
  }
  // Handle normal filters (leaf nodes)
  else if (filter.attribute && filter.operator && filter.value !== undefined) {
      filterStr += `${filter.attribute}${filter.operator}${filter.value}`;
      if (filter.dataType) filterStr += `|${filter.dataType}`;
  }
    console.log("Final filter string prepared : ",filterStr)//del
    return filterStr;
}

  handleInputData(){
    this.isInputData = true;
    this.createPanelMeta(this.panelMetaLocal);
    this.setListPanelSize(this.getSelectedColumns())
    console.log("panel meta received in list panel component:created ", this.panelMetaLocal);
    this.processData(this.inputData)
  }

  getCurrentViewType(){
    if(this.getScreenWidth < 440) {
      return "card";
    } else return this.currentViewType || "table";
  }

  navigationFilterReplacer(navFilters: any){
    let filters: any[] = JSON.parse(JSON.stringify(this.panelMetaLocal?.filter?.filterItems || []))
    console.log("filters", filters)
    for (let i = 0; i < filters.length; i++) {
      if(filters[i].filterType !== 'navigation_filter') continue
      let navFilterObj = navFilters.find(navFilter => (navFilter.parameter || navFilter.attributeId) == filters[i].value)
      if(navFilterObj?.value || navFilterObj?.value == false){
        filters[i].value = navFilterObj.value;
        filters[i].dataType = navFilterObj.dataType //TODO change
      } else continue
    }
    return filters;
  }

  async ngOnChanges(changes: SimpleChanges) {
    console.log("changes", changes)
    if(changes.panelMeta?.firstChange) return;

    if(changes.panelMeta && changes.panelMeta.currentValue){
      if (JSON.stringify(changes.panelMeta.currentValue) == JSON.stringify(this.panelMetaLocal)) {
        console.log("list panel meta not changed; returning")
        return
      }

      this.panelMetaLocal = JSON.parse(JSON.stringify(changes.panelMeta.currentValue))
      this.panelMetaLocal.listAttributes.forEach(attr => { if(!attr.hasOwnProperty('isColumnSelected')) attr['isColumnSelected'] = true })

      //---------------- handle list view -------------
      this.handleListView(changes.panelMeta.currentValue)
      // --------------- handle data load  ------------------------
      // first time load is handled by onInit
      if(!this.panelMetaLocal.loadInitialData || this.isNavigationFilterReceived || this.isQueryFilterReceived){
        return
      }else{
        if(!this.checkDataLoadLock('selfload')) return
        this.acquireDataLoadLock('selfload')

        if(changes.panelMeta.firstChange){
          this.externalFilters = this.listPanelService.getFilterBackup(this.panelMeta.id)
          this.externalSort = this.listPanelService.getSortBackup()
          let paginationBackup = this.listPanelService.getPaginationBackup()
          console.log("pagination backup restored", paginationBackup)
          if(paginationBackup?.panelId == this.panelMeta.id) {
            this.page = paginationBackup.data || this.page
            console.log("page initialized", this.page.size)
          } else {
            this.page.size = this.panelMetaLocal.defaultListSize || 10
            console.log("page size set", this.page.size)
          }
        }

        console.log("calling load data from onchanges", changes.panelMeta)
        console.log("[load-Data] 6")
        await this.loadData([], [])
      }
    }
    if(changes.inputData) {
      console.log("input data changed", changes.inputData.currentValue)
      if(this.panelMeta) this.panelMetaLocal = JSON.parse(JSON.stringify(this.panelMeta))
      if(changes.inputData.currentValue.length) this.handleInputData()
    }
    if(changes.refreshData && changes.refreshData.currentValue){
      console.log("refreshData", changes.refreshData.currentValue)
      if(changes.refreshData.currentValue){
        this.refreshPanel()
      }
    }
  }

  setListPanelSize(headersLength: number) {
    if (!this.isDynamicWidth) {
      this.dataTableContainerStyles = {};
      return;
    }

    if(headersLength <= 5) {
      this.listPanelSize = 1;
    } else if (headersLength > 5 && headersLength <= 10) {
      this.listPanelSize = 1.5;
    } else if(headersLength > 10 && headersLength <= 15) {
      this.listPanelSize = 2.5;
    } else if(headersLength > 15 && headersLength <= 20) {
      this.listPanelSize = 3.0;
    } else {
      this.listPanelSize = Math.ceil((headersLength - 20) / 10) + 3.0;
    }
    this.dataTableContainerStyles = this.getDataTableContainerStyles();
    console.log('listPanelSize', headersLength, this.listPanelSize)
  }

  createPanelMeta(meta?) {
    var buttonCount = 0;
    let panelmeta:any = meta;
    var listAttr:any = [];
    if(!meta){
      panelmeta = {
        listAttributes: [],
        type: "listpanel",
        viewTypes: {
          defaultView: this.getScreenWidth < 440 ? "card" : "table"
        }
      };
    }

    if(!this.isCustomDataSource && panelmeta?.listAttributes?.length > 0){
      for (let i = 0; i < panelmeta.listAttributes.length; i++){
        let attr = panelmeta.listAttributes[i]
        if(attr.fieldType == 'attribute' || attr.fieldType == 'label'){
          if(!attr.hasOwnProperty('isColumnSelected')) attr['isColumnSelected'] = true
          listAttr.push(JSON.parse(JSON.stringify(attr)))
          // if (attr.isColumnSelected) {
          //   listAttr.push(JSON.parse(JSON.stringify(attr)))
          // }
        }
      }
      // looping separately to place the icons after attributes
      for (let i = 0; i < panelmeta.listAttributes.length; i++){
        let attr = panelmeta.listAttributes[i]
        if(attr.fieldType == 'icon'){
          if(!attr.hasOwnProperty('isColumnSelected')) attr['isColumnSelected'] = true
          listAttr.push(attr)
          buttonCount++
          // if (attr.isColumnSelected){
          //   listAttr.push(attr)
          //   buttonCount++
          // }
        }
      }

      panelmeta.listAttributes = listAttr;
    } else panelmeta.listAttributes = [];

    console.log("attributes handled", JSON.parse(JSON.stringify(panelmeta)))
    // if(meta && Object.keys(meta).length > 0) {
    //   panelmeta = {
    //     viewTypes: {
    //       defaultView: this.getScreenWidth < 440 ? "card" : "table"
    //     }
    //   };
    //   panelmeta = Object.assign(panelmeta, meta);
    // }

    let dataHeader = this.getHeaders(this.inputData);
    this.setListPanelSize(dataHeader.length);

    console.log("dataHeader", dataHeader)
    for(let key in dataHeader){

      let suppliedAttr = panelmeta.listAttributes.find(a => a.__id == dataHeader[key])
      if (!suppliedAttr) {
        let listAttMap = {};
        listAttMap['__id'] = dataHeader[key];
        listAttMap['name'] = dataHeader[key];
        listAttMap['dataType'] = "string";
        listAttMap['filterable'] = false;
        listAttMap['sortable'] = false;
        listAttMap['sortEnabled'] = false;
        listAttMap['show_hide'] = false;
        listAttMap['fieldType'] = 'attribute';
        listAttMap['widgetType'] = 'label';
        listAttMap['isColumnSelected'] = true

        if (buttonCount) {
          panelmeta.listAttributes.splice(panelmeta.listAttributes.length - buttonCount, 0, listAttMap);
        } else {
          panelmeta.listAttributes.push(listAttMap);
        }
      } else {
        let index = panelmeta.listAttributes.findIndex(a => a.__id == dataHeader[key]);

        if (index !== 0) { // Skip if it's the first element for the first occurrence
          panelmeta.listAttributes.splice(index, 1); // Remove from current position
          panelmeta.listAttributes.push(suppliedAttr); // Push to the end
        }
      }

    }

    console.log("panelmeta.listAttributes", JSON.parse(JSON.stringify(panelmeta.listAttributes)))

    panelmeta.listWidgetSet = this.listPanelService.generateWidgetSet(panelmeta.listAttributes, panelmeta, panelmeta.connectionId || "", panelmeta.boxId || "", panelmeta.boxObjectId || "", this.isInputData);

    this.panelMetaLocal = panelmeta;
    // this.panelMeta = JSON.parse(JSON.stringify(this.panelMetaLocal))
    console.log("this.panelMetaLocal", JSON.parse(JSON.stringify(this.panelMetaLocal)))

  }

  getHeaders(data) {
    var headers = []

    if(this.panelMetaLocal?.dataSource == "custom" && this.panelMetaLocal?.customAttributes?.length > 0){
      this.panelMetaLocal.customAttributes.forEach(element => {
        headers.push(element.__id);
      });
    } else {
      if(this.panelMetaLocal?.dataHeaders){
        return this.panelMetaLocal.dataHeaders
      }
      data.forEach(function(record) {
        var fields = Object.keys(record)
        for(var i = 0; i < fields.length; i++) {
          if(headers.indexOf(fields[i]) == -1) {
            headers.push(fields[i])
          }
        }
      });
    }
    return headers;
  }


  //check and load all filter value options if available, this will be used in filter value dropdown
  async constructAvailableFilterValues() {
    if (!this.panelMetaLocal?.userFilterEnabled) return; // Exit early if user filters are not enabled
    // Loop through each attribute
    for (let attributeMap of this.panelMetaLocal?.listAttributes) {
      // Loop through each widgetSet in listWidgetSet
      for (const widgetSetKey of Object.keys(this.panelMetaLocal.listWidgetSet)) {
        // Skip if the attribute __id does not match widgetSetKey
        if (attributeMap.__id !== widgetSetKey) continue;
        // Get the corresponding widget set map
        const widgetSetMap = this.panelMetaLocal.listWidgetSet[widgetSetKey];
        // Check if the widget type is allowed for filtering
        if (this.listPanelService.getOptionFilterWidgetTypes().includes(widgetSetMap.type)) {
          let values = await this.widgetUtilityService.getAvailableOptions(widgetSetMap);
          attributeMap["valueOptions"] = values;
          attributeMap["valueType"] = "select"; //set value type as select
        }
      }
    }
    this.cdr.markForCheck();
  }

  getDataTableContainerStyles() {
    if (!this.isDynamicWidth) return {};
    return {
      'margin-left': (this.margin?.left || 0) + '%',
      'margin-right': (this.margin?.right || 0) + '%',
      width: (this.listPanelSize * 100) + '%'
    }
  }

  // isDataConfigChanged(old: any, neW: any){
  //   // console.log("current meta", JSON.parse(JSON.stringify(this.panelMetaLocal)))
  //   console.log("old", JSON.parse(JSON.stringify(old || {})))
  //   console.log("new", JSON.parse(JSON.stringify(neW || {})))

  //   if(
  //     (this.isChanged(old.attributeOptions, neW.attributeOptions)) ||
  //     (this.isChanged(old.boxConfigToken, neW.boxConfigToken)) ||
  //     (this.isChanged(old.boxId, neW.boxId)) ||
  //     (this.isChanged(old.boxObjectId, neW.boxObjectId)) ||
  //     (this.isChanged(old.connectionId, neW.connectionId)) ||
  //     (this.isChanged(old.dataSource, neW.dataSource)) ||
  //     (this.isChanged(old.filter, neW.filter)) ||
  //     (this.isChanged(old.sort, neW.sort)) ||
  //     (this.isChanged(old.getFnOptions, neW.getFnOptions)) ||
  //     (this.isListAttributesChanged(old.listAttributes, neW.listAttributes))
  //   ){
  //     return true
  //   }
  //   return false
  // }

  // isChanged(old: any, neW: any){
  //   if((old == undefined && neW) || (neW == undefined && old)) return true
  //   if(old == undefined && neW == undefined) return false
  //   if(JSON.stringify(old) != JSON.stringify(neW)) return true
  //   else return false
  // }

  // isListAttributesChanged(oldList, newList){
  //   if(oldList.length != newList.length) return true
  //   let isDiff: boolean = false;
  //   oldList.forEach(oldAttr => {
  //     if(!newList.find(newAttr => newAttr.__id == oldAttr.__id)){
  //       isDiff = true
  //     }
  //   })
  //   console.log("attribute set changed")
  //   return isDiff
  // }


  ngOnDestroy(): void {

    this.searchFilterSubscription?.unsubscribe()
    // this.navigationDataSubscription?.unsubscribe()
    this.pageMetaSubscription?.unsubscribe()
    this.dataModelSubscription?.unsubscribe()
    this.currentPageCodeSubscription?.unsubscribe()
    this.routeDataSubscription?.unsubscribe()
    this.pageModelSub?.unsubscribe()
  }

  //-----------------------------------------FUNCTIONS--------------------------------------

  private checkDataLoadLock(source: string){
    if(!this.dataLoadLockOwner) return true
    if(!source) return false
    let requestBy = this.dataSources.find(element => element.source == source)
    let lockOwner = this.dataSources.find(element => element.source == this.dataLoadLockOwner)
    return requestBy.precedence >= lockOwner.precedence ? true : false
  }

  private acquireDataLoadLock(source: string){
    this.dataLoadLockOwner = source
  }

  /**
   * @returns
   * {
   *    status: [boolean] whether all replacement values are available,
   *    filters?: [array] filters (after replacement of actual values in case of page filters)
   * }
   *
   */
  pageFilterReplacer(){
    if(!this.panelMetaLocal['filter'] || !this.panelMetaLocal['filter']['filterEnabled']) return {status: true, filters: []}
    // if(this.panelMetaLocal['filter']['filterItems'].findIndex(filter => filter.dynamic) == -1)
    //   return {status: true, filters: this.panelMetaLocal['filter']['filterItems']}
    console.log("dataModel", this.dataModel)
    let replacedFilters: any[] = []

    for (let i = 0; i < this.panelMetaLocal['filter']['filterItems'].length; i++){
      let filter = this.panelMetaLocal['filter']['filterItems'][i]

      if(filter.filterType !== 'page_filter') {
        replacedFilters.push(JSON.parse(JSON.stringify(filter)))
        continue
      }
      if(!this.dataModel) return { status: false }

      console.log("dissecting", filter.value)
      let connectionId = filter.value.split(':')[0]
      let boxObjectId = filter.value.split(':')[1]
      let attributeId = filter.value.split(':')[2]
      console.log(connectionId, boxObjectId, attributeId)
      if(
        this.dataModel[connectionId] &&
        this.dataModel[connectionId][boxObjectId] &&
        this.dataModel[connectionId][boxObjectId][attributeId] &&
        this.dataModel[connectionId][boxObjectId][attributeId]['value']
      ){
        let newFilter = JSON.parse(JSON.stringify(filter))
        newFilter.value = this.dataModel[connectionId][boxObjectId][attributeId]['value']
        console.log("value from dataModel", newFilter.value)
        replacedFilters.push(newFilter)
      }else{
        console.log("data for ", filter, "not available")
        return {
          status: false
        }
      }
    }
    console.log("page filters replaced", JSON.parse(JSON.stringify(this.panelMetaLocal['filter']['filterItems'])))
    return {
      status: true,
      filters: replacedFilters
    }
  }


  async refreshPanel() {
    // this.resetPagination()
    console.log("last used config", JSON.parse(JSON.stringify(this.lastUsedDataConfig || {})))
    if(this.lastUsedDataConfig){
      let res:any = await this.loadByDataConfig(this.lastUsedDataConfig, {clearCache: true})
      this.page.nextPageToken = res.page.nextPageToken
      this.page.previousPageToken = res.page.previousPageToken
      this.page.total = res.page.total
      this.listPanelService.setPaginationBackup({ panelId: this.panelMeta.id, data: this.page })
      // this.dataLength = this.page.total || 1000
      this.dataLength = 1000

      this.rawBoxData = res.data
      this.checkPaginationVisibility()

      if(!this.checkDataLoadLock('selfload')) return
      this.acquireDataLoadLock('selfload')

      // set in cache
      this.pageService.listPanelDataCache[this.panelMeta.id] = {
        data: res,
        setAt: Date.now()
      }

      this.processData(res.data)
    }else{
      console.log("[load-Data] 7")
      this.loadData(null, null, {clearCache: true});
    }
  }

  formInternalFilters(){
    let internalFilters: any[] = []
    if(!this.panelMetaLocal?.filter?.filterEnabled) return []
    this.panelMetaLocal?.filter?.filterItems.forEach((filter, i) => {
      if (filter.filterType == 'page_filter' && filter.value) {
        let pageModel = this.pageService.getPageModel()
        console.log("page model", pageModel)
        let panelId = filter.value.split(':')[0]
        let widgetId = filter.value.split(':')[1]
        let widgetVal = pageModel[panelId]?.[widgetId]?.value
        console.log("widget value", widgetVal)
        if (widgetVal) {
          let temp = JSON.parse(JSON.stringify(filter))
          temp.value = widgetVal
          internalFilters.push(temp)
        }
        console.log("page filters added", internalFilters)

      } else if (filter.filterType == 'static_filter') {
        internalFilters.push(filter)
        console.log("static filters added", internalFilters)
      }
    })
    return internalFilters
  }


  async loadData(filters?: any, sortItems?: any[], options?: any){
    console.log("load Data hit", filters)
    // if (this.panelMetaLocal?.filter?.filterEnabled) {
    //   if(!filters || (Array.isArray(filters) && !filters?.length) || (typeof filters == 'object' && !filters?.filterItems?.length)) {
    //     filters = this.panelMetaLocal.filter
    //   } else if(typeof filters == 'object' && filters?.filterItems?.length) {
    //     //construct filters for free flow search
    //     const newFilters = this.panelMetaLocal.filter.filterItems.concat(filters)
    //     let filterObj = {
    //       operator: "and",
    //       filterItems: newFilters,
    //       filterEnabled: true
    //     }
    //     filters = filterObj;
    //   }
    // }
    this.runSpinner = true
    this.cdr.markForCheck();
    // this.cdr.detectChanges()
    // this.spinnerService.show()
    let listAttributeIds: any[] = this.getListAttributeIdsToLoad()

    //initialize dataBindConfig to send to getAny in metaService
    let dataBindConfig = {
      baseMap: this.panelMetaLocal.baseMap || {},
      boxConfigToken: this.panelMetaLocal.boxConfigToken,
      boxId: this.panelMetaLocal.boxId,
      connectionId: this.panelMetaLocal.connectionId,
      boxObject: this.panelMetaLocal.boxObjectId,
      pageNumber: this.page.number,
      pageSize: this.page.size,
      options: this.panelMetaLocal.getFnOptions,
      attributes: listAttributeIds
    }

    if(!Array.isArray(filters) && filters?.operator && filters?.filterItems){
      dataBindConfig['filters'] = this.constructFilterString(filters)
    } else if(filters?.length){
      dataBindConfig['filters'] = this.checkSecurityFilters(filters);
      console.log("checked security filters", dataBindConfig['filters'])
    } else{
      this.internalFilters = this.formInternalFilters()
      dataBindConfig['filters'] = this.internalFilters.concat(this.externalFilters)
      // console.log("internal filters", this.internalFilters)
      // console.log("external filters", this.externalFilters)
      // console.log("all filters", JSON.parse(JSON.stringify(dataBindConfig['filters'])))
    }

    // SORT
    let sort: any = ''
    if(this.customOrder && this.customOrder.length > 0){
      this.customOrder.forEach((sortBy, i) => {
        if(i > 0) sort += `,`
        sort += `${sortBy.__id}=${sortBy.order}`
      });
    } else if(sortItems?.length){
      sort = this.getSortString(sortItems)
    } else {
      let internalSort = this.panelMetaLocal.sort?.sortEnabled ? (this.panelMetaLocal?.sort?.sortAttributes || []) : []
      let allSort = internalSort.concat(this.externalSort)
      sort = this.getSortString(allSort)
    }
    dataBindConfig['sort'] = sort


    // resolve templated values in filter if any
    dataBindConfig['filters'] = await this.resolveSystemFieldTemplatesInFilter(dataBindConfig['filters'], true)
    // console.log("resolveSystemFieldTemplatesInFilter", dataBindConfig['filters'])
    dataBindConfig['filters'] = this.resolvePageRefTemplatesInFilter(this.pageModel, dataBindConfig['filters'])
    // console.log("resolvePageRefTemplatesInFilter", dataBindConfig['filters'])

    //fetch data
    let res: any
    try{
      console.log("dataBindConfig prepared, calling loadByDataConfig", dataBindConfig)
      if(!this.panelMeta.noCache && options?.useCache && this.pageService.listPanelDataCache?.[this.panelMeta.id]?.['data']){
        res = this.pageService.listPanelDataCache?.[this.panelMeta.id]?.['data']
      }else{
        res = await this.loadByDataConfig(dataBindConfig, options)
      }
      // console.log("flags", this.isNavigationFilterReceived, isFromOnChange, res)
      // if(this.isNavigationFilterReceived && isFromOnChange) return;
      this.page.nextPageToken = res.page.nextPageToken
      this.page.previousPageToken = res.page.previousPageToken
      this.page.total = res.page.total
      this.listPanelService.setPaginationBackup({ panelId: this.panelMeta.id, data: this.page })
      // this.dataLength = this.page.total || 1000
      this.dataLength = 1000

      this.rawBoxData = res.data
      this.checkPaginationVisibility()

      if(!this.checkDataLoadLock('selfload')) return
      this.acquireDataLoadLock('selfload')

      // set in cache
      this.pageService.listPanelDataCache[this.panelMeta.id] = {
        data: res,
        setAt: Date.now()
      }
      console.log("calling processData in panel", this.panelMeta.id)
      this.processData(res.data)
      this.dataLoadError = ''
    }catch(err){
      console.log("error occurred while fetching data", err)
      // this.dataLoadError = ''
      console.error("------------ data bind config exec failed for list panel -------------", err)
      this.runSpinner = false
      this.dataLoadError = `${err.status}  ${err.name}`
      console.log("should print", this.dataLoadError, "for payload", dataBindConfig)
    }
    // if(this.panelMetaLocal.loadInitialData){
    // }
    this.runSpinner = false;
    this.cdr.detectChanges();
  }

  async resolveSystemFieldTemplatesInFilter(filters: any[], isNoRemove: boolean = false){
    for(let i = 0; i < filters.length; i++) {
      let f = filters[i]
      if(f.templateSignature?.startsWith('${') && f.templateSignature?.endsWith('}')){
        f.value = f.templateSignature
      }
      if(typeof f.value == 'string' && f.value?.startsWith('${') && f.value?.endsWith('}')){
        // try resolving with system field values
        let systemFieldValues: any = await this.systemFieldsService.getSystemFields()
        let data = {}
        systemFieldValues.fields.forEach(field => {
          data[field] = systemFieldValues[field].value
        })
        let te = new TemplateEngine()
        f.value = te.fillAny(f.value, data, isNoRemove)
        filters[i] = JSON.parse(JSON.stringify(f))
      }
    }
    // console.log("system field templates resolved", JSON.parse(JSON.stringify(filters)))
    return filters
  }

  resolvePageRefTemplatesInFilter(pageModel: any, filters: any[], isNoRemove: boolean = false){
    // console.log("resolve PageRefTemplatesInFilter", JSON.parse(JSON.stringify(filters)))
    let replacementMap = {}
    Object.keys(this.pageModel || {}).forEach(panelId => {
      Object.keys(this.pageModel[panelId]).forEach(widId => {
        if(typeof this.pageModel[panelId]?.[widId]?.value == 'string' && !this.pageModel[panelId]?.[widId]?.value?.match(/\$\{[^\}]+\}/g)?.length){
          replacementMap[panelId] = replacementMap[panelId] || {}
          replacementMap[panelId][widId] = this.pageModel[panelId][widId].value
        }
      })
    })
    // console.log("replacement map created", replacementMap)
    for(let i = 0; i < filters.length; i++) {
      let f = JSON.parse(JSON.stringify(filters[i]))
      // console.log("checking filter", f)
      if(f.templateSignature?.startsWith('${') && f.templateSignature?.endsWith('}')){
        f.value = f.templateSignature
      }
      if((typeof f.value == 'string') && f.value?.startsWith('${') && f.value?.endsWith('}')){
        // console.log("this filter has template", f.value)
        // try resolving with page model values
        let te = new TemplateEngine()
        f.value = te.fillAny(f.value, replacementMap, isNoRemove)
        filters[i] = f
        // console.log("after template replacement", filters[i])
      }
    }
    // console.log("page ref templates resolved", JSON.parse(JSON.stringify(filters)))
    return filters
  }

  getSortString(arr: any[]){
    let sort: string = ''
    arr.forEach((sortBy, i) => {
      if(sortBy.attribute){
        if(i > 0) sort += `,`
        sort += `${sortBy.attribute}=${sortBy.order}` //|${sortBy.dataType}
      }
    });
    return sort
  }


  /**
   *
   * @returns
   */
  getListAttributeIdsToLoad(){
    // console.log("getting list attribute ids to load, panelMeta", JSON.parse(JSON.stringify(this.panelMetaLocal.listAttributes)))
    let listAttributeIds = []
    this.panelMetaLocal.listAttributes.forEach(attribute => {
      // if(!attribute.value && attribute.__id !== '__checkbox__'){
      if(attribute.fieldType == 'attribute' && attribute.isColumnSelected){
        listAttributeIds.push(attribute.__id)
        attribute?.navigationSettings?.navFilterAttributes?.forEach(navFilterAttr => listAttributeIds.push(navFilterAttr.__id))
      }
    });
    // console.log("after including filtered list attributes", JSON.parse(JSON.stringify(listAttributeIds)))

    // add the status column attribute also if not already added
    if(
      this.panelMetaLocal.viewTypes?.boardStatusColumn &&
      listAttributeIds.findIndex(id => id == this.panelMetaLocal.viewTypes?.boardStatusColumn.__id) == -1
    ){
      listAttributeIds.push(this.panelMetaLocal.viewTypes.boardStatusColumn.__id)
    }


    // attach the primary attribute also if primary attribute is present and not already in list attributes
    if(
      this.panelMetaLocal.primaryAttribute &&
      this.panelMetaLocal.listAttributes.findIndex(attr => attr.__id == this.panelMetaLocal.primaryAttribute.__id) == -1
    ){
      listAttributeIds.push(this.panelMetaLocal.primaryAttribute.__id)
    }


    // add navigation attributes also if not already added
    this.panelMetaLocal.listAttributes.forEach(attr => {
      if(attr.navigationSettings?.navFilterAttributes?.length){
        attr.navigationSettings.navFilterAttributes.forEach(navAttr => {
          if(listAttributeIds.includes(navAttr.__id)) return
          if(this.panelMetaLocal.listAttributes.findIndex(a => a.__id == navAttr.__id) > -1) return
          listAttributeIds.push(navAttr.__id)
        })
      }
    })

    listAttributeIds = [...new Set(listAttributeIds)]
    console.log("list attribute Ids", JSON.parse(JSON.stringify(listAttributeIds)))
    return listAttributeIds
  }


  /**
   *
   * @param dataBindConfig
   * @returns
   */
  async loadByDataConfig(dataBindConfig, options?){

    this.runSpinner = true
    this.cdr.markForCheck();
    let mode: string;
    if (this.panelMeta.authMode) {
      mode = this.panelMeta.authMode;
    } else if(!this.builderMode){
      mode = 'user_api_key'
    }
    let res: any
    try{
      this.lastUsedDataConfig = dataBindConfig;
      // console.log("this.metaService:", this.metaService);

      // Check if bloomMeta, interaction_options, and necessary product and user details are available
      // Specifically, check if the product is "HYPERLIST" then track the usage metrics
      if (
        this.metaService?.bloomMeta?.interaction_options?.product === "HYPERLIST" &&
        this.metaService?.creator_email &&
        this.metaService?.creator_workspace_id
      ) {
        const userEmail = this.metaService.creator_email;
        const workspaceId = this.metaService.creator_workspace_id;
        const user = await this.authService.getWorkspaceUser(userEmail);
        if (user) {
          if (!options) options = {};
          const interactionToken = `Interaction ${workspaceId}:${user._id}:::HYPERLIST:HYPERLIST:`;
          options.interactiontoken = interactionToken;
        } else {
          console.log(`User not found for email: ${userEmail}. Cannot generate interaction token.`);
        }
      } else {
        console.log("Required data is missing: bloomMeta, interaction_options, product, creator_email, or creator_workspace_id. Cannot generate interaction token.");
      }

      // inject same filter in summary charts also
      if (this.panelMetaLocal.summary?.enabled && this.panelMetaLocal.summary?.charts?.length) {
        this.panelMetaLocal.summary.charts.forEach(chart => {
          console.log("chart", chart)
          chart.config.dataSource.filter.filterEnabled = true
          chart.config.dataSource.filter.filterItems = dataBindConfig.filters

          // set chart width
          if (this.panelMetaLocal.summary.charts.length == 1) {
            this.chartWidth = '95'
          } else if (this.panelMetaLocal.summary.charts.length == 2) {
            this.chartWidth = '47.5'
          } else {
            this.chartWidth = '32.5'
          }
        })
      }


      res = await this.boxService.getAny(dataBindConfig, mode, options)
      console.log("boxService getAny result:", res)
      if(res?.page?.nextPageToken && !this.pageEventfirstHit) this.pageTokenArr.push(res.page.nextPageToken)
      this.runSpinner = false
      this.cdr.markForCheck();
      return res
    }catch(err){
      this.runSpinner = false
      this.cdr.markForCheck();
      throw err
    }
  }

  checkSecurityFilters(filters: any[] = []){
    let result = [];
    filters.forEach(e => {
      console.log("filter-->", e)
      if(e?.isSecurity && e?.securityConfig && e.securityConfig?.securities?.length > 0){
        let access:any = this.resourcePermissionService.checkAccess(e.securityConfig);
        console.log("in access", access);
        if(access?.show) result.push(e);
      } else result.push(e);
    })
    return result;
  }



  /**
   *
   * @param records
   * @param sliceFrom
   * @param sliceSize
   */
  processData(records: any) {
    console.log("process data hit", records)
    // console.log("viewTypes", this.panelMetaLocal.viewTypes)
    // console.log("grouping attribute", this.panelMetaLocal.viewTypes.boardStatusColumn.__id)
    this.spinnerService.show()
    this.cdr.markForCheck();
    let primaryAttributeId: string = this.panelMetaLocal.primaryAttribute ? this.panelMetaLocal.primaryAttribute.__id : ''

    let groupAttrId: string
    if(this.panelMetaLocal.viewTypes?.boardStatusColumn && this.panelMetaLocal.viewTypes.boardStatusColumn?.__id){
      groupAttrId = this.panelMetaLocal.viewTypes.boardStatusColumn.__id
    }
    // console.log("grouping attribute", groupAttrId)
    let tableData: any = []
    records.forEach((record, i) => {
      let tableRow: any = {}
      if(primaryAttributeId){
        tableRow['__recordId'] = record[primaryAttributeId]
      }
      // console.log("record", record)
      // console.log("grouping value", record[groupAttrId])
      if(groupAttrId){
        tableRow[groupAttrId] = {value: record[groupAttrId], type: 'grouping'}
      }

      this.panelMetaLocal.listAttributes.forEach(attrObj => {
        // if(attrObj.isColumnSelected == false) return;
        if(!attrObj.isDrillDown){
          if(attrObj.fieldType == 'attribute'){
            tableRow[attrObj.__id] = {value: record[attrObj.__id], type: 'attribute', widgetType: attrObj.widgetType}
          }else if(attrObj.fieldType == 'label'){
            let val = attrObj.value
            if(attrObj.value == "__serial__") val = i + 1
            if(attrObj.value == "__index__") val = i
            tableRow[attrObj.__id] = {value: val, type: 'label', columnName: attrObj.columnName, widgetType: 'label'}

          }else if(attrObj.fieldType == 'icon'){
            tableRow[attrObj.__id] = {value: attrObj.value, type: 'icon', columnName: attrObj.columnName, widgetType: 'icon'}
          }else if(attrObj.fieldType == 'button'){
            tableRow[attrObj.__id] = {value: attrObj.value, type: 'button', columnName: attrObj.columnName, widgetType: 'button'}
          } else if(attrObj.fieldType == 'checkbox') {
            tableRow[attrObj.__id] = {value: "", type: 'checkbox', columnName: "", widgetType: 'checkbox'}
          }
        } else {
          for (let i = 0; i < attrObj.nestedProperties.length; i++) {
            const nestedProp = attrObj.nestedProperties[i];
            let temp = record[attrObj.__id]
            let resultValue;
            // if(typeof temp == 'object' && !Array.isArray(temp)){
            if(typeof temp == 'object'){
              resultValue = this.widgetUtilityService.getDeepObjectValue(temp, nestedProp.path)
              console.log("nested value retrieved", resultValue)
            }else{
              // console.log("nested value does not exist")
            }
            // console.log("nested value is:", resultValue)
            tableRow[attrObj.__id + '.' + nestedProp.path] = {value: resultValue, type: 'attribute', widgetType: nestedProp.widgetType}
          }
        }
      });
      tableData.push(tableRow)
    });
    if (!Object.keys(this.panelMetaLocal.listWidgetSet || {}).length) {
      this.panelMetaLocal['listWidgetSet'] = this.listPanelService.generateWidgetSet(this.panelMetaLocal.listAttributes, tableData)
    }
    this.readyTableData = tableData
    this.runSpinner = false
    this.spinnerService.hide();
    this.cdr.markForCheck();
    console.log("ready table data", this.readyTableData)
  }

  async loadMoreData(){
    // this.loadingData = true;
    this.isLoadingMore = true;
    this.cdr.markForCheck();
    if(!this.currentEvent){
      this.currentEvent = {
        pageSize: this.panelMetaLocal.defaultListSize,
        length: 1000,
        pageIndex: 0,
        previousPageIndex: -1
      }
    }
    this.currentEvent.pageIndex = this.currentEvent.pageIndex +1;
    this.currentEvent.previousPageIndex = this.currentEvent.previousPageIndex +1;
    await this.pageEvent(this.currentEvent);
    this.isLoadingMore = false
  }

  // selectAttributeMenuOpened(){
  //   this.availableAttributes.forEach((e) => {
  //     e.isColumnSelected = false;
  //     this.panelMetaLocal.listAttributes.forEach((list) => {
  //       if(e.__id == list.__id ){
  //         e.isColumnSelected = true;
  //       }
  //     })
  //   })
  // }

  async columnSelectionChanged(selectedAttributes){
    console.log("columnSelectionChanged > selectedAttributes", JSON.parse(JSON.stringify(selectedAttributes)))

    //if input data passed then we don't need to process filter, sort or column selection so returning here.
    if (this.inputData?.length) return;

    let attrs = []
    selectedAttributes.forEach(attr => {
      let temp = this.panelMeta.listAttributes?.find(a => a.__id == attr.__id)
      if(temp) {
        attrs.push(temp)
      }
    })

    // put edit and delete icons at end (if present)
    let deleteIndex = attrs.findIndex(attr => attr.__id == 'delete')
    let deleteAttr = deleteIndex > -1 ? attrs.splice(deleteIndex, 1) : []
    let editIndex = attrs.findIndex(attr => attr.__id == 'edit')
    let editAttr = editIndex > -1 ? attrs.splice(editIndex, 1) : []
    attrs = [...attrs, ...editAttr, ...deleteAttr]

    console.log("attributes", JSON.parse(JSON.stringify(attrs)))

    this.panelMetaLocal.listAttributes = attrs;
    this.setListPanelSize(this.getSelectedColumns());

    if(!this.currentEvent){
      this.currentEvent = {
        pageSize: this.panelMetaLocal.defaultListSize,
        // length: 5,
        pageIndex: 0,
        previousPageIndex: -1
      }
    }
    this.panelMetaLocal.listWidgetSet = this.listPanelService.generateWidgetSet(this.panelMetaLocal.listAttributes, this.panelMeta, this.panelMeta.connectionId || "", this.panelMeta.boxId || "", this.panelMeta.boxObjectId || "");
    console.log("widget set generated", this.panelMetaLocal.listWidgetSet)

    // to avoid triggering data load due to default emit from column selection during initialization process
    if(!this.rawBoxData?.length) return
    this.lastUsedDataConfig['attributes'] = this.getListAttributeIdsToLoad()
    await this.pageEvent(this.currentEvent)
    // console.log("panelMetaLocal", this.panelMetaLocal)
    // console.log("panelMeta", this.panelMeta)
  }

  groupAttrSelectionChanged(newGroupingAttribute: any) {
    console.log("group attr selection changed", newGroupingAttribute)

    // instead of directly changing the property inside panelMeta, we are explicitly changing the reference of panelMeta so that onChanges is triggered in child component
    let temp = JSON.parse(JSON.stringify(this.panelMetaLocal))
    temp.viewTypes.boardStatusColumn = newGroupingAttribute;
    this.panelMetaLocal = temp

    this.menu5Trigger.closeMenu()
  }

  async customOrderChanged(sortAttribute){
    console.log("sort attribute", sortAttribute);
    this.customOrder = [sortAttribute];
    this.paginator.pageIndex = 0;
    console.log("calling load data from customOrderChanged")
    console.log("[load-Data] 8")
    await this.loadData([])
  }

  async selectedRowData(data : any){
    // if action is to is to delete record from list panel we will emit it from deleteResource()
    if(data?.attribute?.actionConfig?.action == 'record-deletion') return
    console.log("SELECTED ROW DATA RECIEVED IN LIST PANEL", data);
    let listAttributeIds: any[] = this.getListAttributeIdsToLoad()
    let filterObj = {
      attributes: listAttributeIds,
      filter: this.userFilter,
      sort: this.userSort
    }
    this.filterObj.emit(filterObj)
    this.rowData.emit(data)
  }

  selectedCheckboxData(data: any) {
    this.checkboxSelected.emit(data);
  }

  async pageEvent(event: any){
    console.log("PAGE EVENT FIRED", event);
    if(event.pageSize == undefined) return  // initial trigger when mat-paginator initialize
    //handling for list panels if the data is passed as input param.
    if (!this.lastUsedDataConfig) return;
    this.pageEventfirstHit = true;
    this.runSpinner = true
    this.cdr.markForCheck();

    this.page['size'] = event.pageSize || this.page['size']
    this.page['number'] = !isNaN(event.pageIndex) && event.pageIndex >= 0 ? (event.pageIndex + 1) : this.page['number']
    this.listPanelService.setPaginationBackup({ panelId: this.panelMeta.id, data: this.page })

    let dataBindConfig = this.lastUsedDataConfig
    dataBindConfig["pageNumber"] = this.page.number
    dataBindConfig["pageSize"] = this.page.size
    dataBindConfig["nextPageToken"] = this.pageTokenArr?.[event?.pageIndex] || ''
    dataBindConfig["previousPageToken"] = this.page?.previousPageToken || ''

    if(this.currentEvent && event.pageSize != this.currentEvent?.pageSize){
      dataBindConfig["pageNumber"] = 1;
      dataBindConfig["nextPageToken"] = '';
      dataBindConfig["previousPageToken"] = '';
    }
    this.currentEvent = event;

    let res: any
    try{
      res = await this.loadByDataConfig(dataBindConfig)
      console.log("res : ",res)
      if(res?.page?.nextPageToken) {
        this.pageTokenArr[event?.pageIndex + 1] = res.page.nextPageToken
      }
    }catch(e){
      console.error("could not load data", e)
    }

    if(event?.pageSize && res.data.length < event?.pageSize){
      this.dataLength = event.pageIndex * event.pageSize + res.data.length;
      this.isNoMorePage = true;
    } else {
      this.page = res.page
      // this.dataLength = this.page.total || 1000
      this.dataLength = 1000
    }

    console.log("dataLength set", this.dataLength, this.httpCacheService.listPanelLoadDataMeta)

    if(this.panelMetaLocal.paginationType && this.panelMetaLocal.paginationType == "singlepage"){
      this.rawBoxData = this.rawBoxData.concat(res.data);
      this.processData(this.rawBoxData);
    } else {
      this.rawBoxData = res.data;
      this.processData(res.data)
    }

    this.checkPaginationVisibility()

    if(!this.checkDataLoadLock('selfload')) return
    this.acquireDataLoadLock('selfload')

    // set in cache
    this.pageService.listPanelDataCache[this.panelMeta.id] = {
      data: res,
      setAt: Date.now()
    }
    // this.loadingData = false
    // this.processData(res.data)
  }



  handleListView(meta){
    // console.log("list view settings", meta.viewTypes)
    this.currentViewType = meta?.viewTypes?.defaultView || 'table';
    this.viewCount = 0
    if(!meta?.viewTypes?.userCanChoose){
      this.multiView = false
    }else{
      meta.viewTypes.views.forEach(view => {
        // console.log("view", meta.viewTypes[view])
        if(meta.viewTypes[view].userCanChoose){
          this.viewCount++
        }
      });
      if(this.viewCount > 1) {
        this.multiView = true
      }
      else this.multiView = false
    }
    // this.cdr.detectChanges()
  }

  openSettings(){
    let dialogRef = this.dialog.open(ListPanelDialogComponent, {
      minHeight: '50vh',
      minWidth: '80vw',
      maxHeight: '90vh',
      data: {
        firstHit: false,
        panelMeta: JSON.parse(JSON.stringify(this.panelMeta)),
        pageMeta: this.pageMeta
      },
    })

    dialogRef.afterClosed().subscribe(async data=>{
      if(!data){
        console.log("list panel configuration dialog closed unexpectedly")
        return
      }
      console.log("list panel config dialog resolved", JSON.parse(JSON.stringify(data)))
      this.runSpinner = true
      this.cdr.markForCheck();
      // this.panelMetaLocal = JSON.parse(JSON.stringify(data))
      this.panelMetaLocal = JSON.parse(JSON.stringify(data))
      this.panelMeta = this.panelMetaLocal

      this.currentViewType = this.panelMetaLocal.viewTypes.defaultView

      // scan through pageMeta.panels and replace panel matching this.panelMetaLocal.id
      this.pageMeta.panels.forEach((panel, i) => {
        if(panel.id == this.panelMetaLocal.id){
          this.pageMeta.panels[i] = this.panelMetaLocal
        }
      });
      // console.log("panel updated in pageMeta", JSON.parse(JSON.stringify(this.pageMeta)))

      // overwrite the dataLoadLock to load fresh data
      this.dataLoadLockOwner = 'selfload'

      this.metaService.pageMeta.next(this.pageMeta)
      this.metaService.userMadeChanges.next(true);
      this.pageService.pageMeta = this.pageMeta
      // console.log("page meta pushed to sub", JSON.parse(JSON.stringify(this.metaService.pageMeta.value)))

      // handle list view
      this.handleListView(this.panelMetaLocal)
      // await this.fieldSelectionInit()

      if(this.panelMetaLocal.loadInitialData){
        if(!this.checkDataLoadLock('selfload')) return
        this.acquireDataLoadLock('selfload')
        this.page.size = this.panelMetaLocal.defaultListSize || 10
        console.log("calling load data from afterClosed")
        console.log("[load-Data] 9")
        await this.loadData()
      }
    })
  }

  panelMetaChange(meta: any){
    // console.log("panel meta before change", JSON.parse(JSON.stringify(this.panelMetaLocal || {})))
    // console.log("panel meta after change", JSON.parse(JSON.stringify(meta || {})))
    // console.log("existing page meta", JSON.parse(JSON.stringify(this.pageMeta || {})))
    this.panelMetaLocal = meta
    // this.updatePage()
    this.newPanelMeta.emit(this.panelMetaLocal)
    // this.metaService.userMadeChanges.next(true)
  }

  changeView(event){
    console.log("changeView triggered", event.value)
    this.currentViewType = event.value
  }

  updatePage(){
    let panelIndex = this.pageMeta.panels.findIndex(panel => panel.id == this.panelMetaLocal.id)
    if(panelIndex > -1) this.pageMeta.panels[panelIndex] = this.panelMetaLocal
    console.log("page meta updated", JSON.parse(JSON.stringify(this.pageMeta)))
    this.metaService.pageMeta.next(this.pageMeta)
    this.pageService.pageMeta = this.pageMeta
  }

  async applyUserFilters(filter: any){
    this.page.number = 1
    // this.listPanelService.setPaginationBackup(this.page)

    console.log("user level filter received", JSON.parse(JSON.stringify(filter)))
    // console.log("calling load data from apply filter, last used config:", JSON.parse(JSON.stringify(this.lastUsedDataConfig)))
    this.filterMenu?.closeMenu()
    this.userFilter = filter.filterItems || []
    this.userFilter.forEach(f => f['origin'] = f['origin'] || 'userfilter')
    this.externalFilters = this.userFilter
    this.listPanelService.setFiltersBackup(this.externalFilters, this.panelMeta.id)
    console.log("[load-Data] 10")
    await this.loadData();
  }

  async applyUserSort(sort: any){
    console.log("sort: ", sort)
    console.log("calling load data from applysort")
    this.sortMenu?.closeMenu()
    this.userSort = sort.sortAttributes || []
    this.externalSort = this.userSort
    this.listPanelService.setSortBackup(this.externalSort)
    console.log("external sort", this.externalSort)
    console.log("[load-Data] 11")
    await this.loadData()
  }

  cancelFilter(){
    this.filterMenu?.closeMenu()
    // this.applyUserFilters({})
  }

  cancelSort(){
    // close sort menu
    this.sortMenu?.closeMenu()
    this.applyUserSort({})
  }

  checkPaginationVisibility(){
    if(
      this.panelMetaLocal.paginationType && this.panelMetaLocal.paginationType != 'singlepage' &&
      this.panelMetaLocal.paginationEnabled  && this.rawBoxData?.length
    ) this.showPagination = true

    if(!this.rawBoxData?.length && this.currentEvent?.pageIndex == 0) this.showPagination = false

    if(this.panelMetaLocal.paginationType && this.panelMetaLocal.paginationType == 'singlepage' &&  this.rawBoxData?.length){
      this.showSinglePagePagination = true;
      console.log(this.showSinglePagePagination)
      let currenloadedDataCount = this.httpCacheService.listPanelLoadDataMeta.result.page.number * this.httpCacheService.listPanelLoadDataMeta.result.page.size
      let totalCount = this.httpCacheService.listPanelLoadDataMeta.result.totalCount
      console.log(currenloadedDataCount, totalCount)
      if(currenloadedDataCount < totalCount) this.showSinglePagePagination = true
      if(currenloadedDataCount == totalCount || currenloadedDataCount > totalCount) this.showSinglePagePagination = false
      console.log(this.showSinglePagePagination, this.isNoMorePage)
      if((!currenloadedDataCount || !totalCount || totalCount == 0) && !this.isNoMorePage){
        console.log(this.showSinglePagePagination, this.isNoMorePage)
        this.showSinglePagePagination = true
      }
    //  console.log(this.showSinglePagePagination)
     // (!this.panelMetaLocal.paginationType || this.panelMetaLocal.paginationType != 'singlepage') && (!(this.page?.number == 1 && this.page?.size > this.rawBoxData?.length)) && this.panelMetaLocal.paginationEnabled  && this.rawBoxData?.length
    }
    // console.log(this.showPagination)
  }


  /**
   * connection, bloom, forms and starch listings deletes are handled here
   */
  async deleteResource(data){

    if (this.deletionBuffer) {
      this._snackBar.open("Previous deletion is in progress. Please wait.", "Close", { duration: 2000 });
      return
    }

    data['resource'] = this.panelMeta.boxObjectId // attach resource type
    console.log("data to delete", data)
    console.log("processed data before deletion", JSON.parse(JSON.stringify(this.rawBoxData || this.inputData || [])))
    let dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '600px',
      data: {
        resourceName: (data?.attribute?.actionConfig?.action == 'record-deletion') ? 'record' : data.rowDataRaw.name,
        resource: this.panelMeta.boxObjectId,
        dataObj: data.rowDataRaw
      },
    });
    dialogRef.afterClosed().subscribe(async (deleteConfirmed) => {
      if (!deleteConfirmed) return
      console.log("delete confirmed")
      // delete the resource locally to reflect instantly
      // hold the locally deleted record (in case it needs to be reverted)
      this.deletionBuffer = data
      console.log("pushed into buffer", JSON.parse(JSON.stringify(this.deletionBuffer)))

      this.removeDataFromTable(data)

      try {
        let deleteResponse
        if(data?.attribute?.actionConfig?.action == 'record-deletion'){
          data['deleteConfirmed'] = true
          //in case of record deletion we will emit back the record to parent component to handle delete operation.
          this.rowData.emit(data)
        } else {
          deleteResponse = await this.resourceDeletionService.delete(data)
        }
        console.log("delete response", deleteResponse)

      } catch(e) {
        console.error("delete failed: error received", e)

        // revert the deletion locally
        let deletedRecord = this.deletionBuffer
        console.log("delete backup is", JSON.parse(JSON.stringify(deletedRecord)))
        let i = deletedRecord.index
        let newArr = this.rawBoxData.slice(0, i).concat(deletedRecord.rowDataRaw, this.rawBoxData.slice(i))
        this.rawBoxData = newArr
        this.processData(this.rawBoxData)
        console.log("added back", JSON.parse(JSON.stringify(this.rawBoxData)))
      } finally {

        // clear buffer
        this.deletionBuffer = null
        console.log("removed from buffer", JSON.parse(JSON.stringify(this.deletionBuffer)))
      }
    })
  }
  removeDataFromTable(data:any){
    let arrAfterRemove = this.rawBoxData.filter((row, index) => index != data.index)
    this.rawBoxData = JSON.parse(JSON.stringify(arrAfterRemove));
    this.processData(this.rawBoxData)
  }

  trackByFn(index:number, item:any):any{
    return item || index
  }
}
