import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { WidgetManager } from '../../../WidgetManager';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { SystemFieldsService } from 'src/app/shared/services/system-fields.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { FormService } from 'src/app/form/form.service';
import { AttributeService } from 'src/app/bloom/services/attribute-service.service';
import { ActionServiceGUIUtility } from '../../ActionServiceGUIUtility';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-action-application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.scss'],
    standalone: false
})
export class ApplicationComponent implements OnInit {
  private destroy$ = new Subject<void>();

  constructor(
    public metaService: MetaService,
    public widgetService: WidgetService,
    public systemFieldsService: SystemFieldsService,
    private authService: AuthServiceService,
    private formService: FormService,
    private attributeService: AttributeService,
    public actionServiceGUIUtil: ActionServiceGUIUtility
  ) {

   }

   widgetManager: any = WidgetManager;
   whitelabeledFields: any = [];

  @Input() "actionConfig": any;
  @Input() "displayConfig": any;
  @Input() "widget": any;
  @Input() "pagemeta": any;
  @Input() actionIndex:number;
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();
  @Output() actionSelectionOptionsInput: EventEmitter<any> = new EventEmitter();

  config: any;
  actionFields: any = [];
  sourceFields: any = [];
  systemFields: any = [];

  selectedActionMap:any
  panelOpenState: boolean = false;

  ngOnInit(): void {
    this.getSystemFields();
    console.log("[APPLICATION-COMPONENT] in app action", this.actionConfig);
    if(this.actionConfig.action) {
      if(!this.actionConfig.actionMap) this.actionConfig.actionMap = {};
      if(!this.actionConfig.actionMap.mapping) this.actionConfig.actionMap.mapping = [];
    }


    this.metaService.pageMeta.pipe(takeUntil(this.destroy$)).subscribe(pageMeta => {
      console.log("[APPLICATION-COMPONENT] pageMeta--->", pageMeta, this.authService?.subproduct)
      // this.pageMeta = pageMeta

      //For Form, we are taking all sections panels and generating sourcefields as we need all sections fields for mapping
      if(this.authService?.subproduct == "form") { // !pageMeta &&
        let formMeta = this.formService?.formMeta?.value;
        pageMeta = { panels: []};
        formMeta?.sections?.forEach(element => {
          if(element?.panels?.length) pageMeta.panels = pageMeta.panels.concat(element.panels);
        });
      }
      if(pageMeta) this.manageSourceFields(pageMeta);
    })

    console.log("loadedPageStructure in ", this.metaService.loadedPageStructure)
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete(); // Ensures cleanup
  }

  attributeOptionsInput(e){
    this.actionSelectionOptionsInput.emit(e);
    this.triggerChange()
  }

  triggerChange(){
    this.metaService.userChangeDetected()
  }


  async getSystemFields(){
    let res = await this.systemFieldsService.getSystemFields();
    for (let index = 0; index < res?.fields?.length; index++) {
      const field = res?.fields[index];
      this.systemFields.push(res[field])
    }
    console.log("SYSTEM FIELDS: ",this.systemFields);
  }

  appSelected(e){
    console.log("appselected", e);
    this.actionConfig.actionMap.boxId = e.__id;
    this.triggerChange()
  }

  baseSelected(base){
    this.actionConfig.actionMap['baseMap'] = {
      box_id: base.storage_box,
      base_id: base._id
    };
    this.actionConfig.actionMap.boxConfigToken = base.storage_token;
    this.triggerChange()
  }

  selectedAction(event ){
    this.selectedActionMap = event;
    console.log("[Application-com] actionSelected fields", event);
    let sourceFields = [];
    event?.output?.list?.forEach(element => {
      let obj = event?.output[element];
      obj.__id = obj.__id || obj.name;
      sourceFields.push(obj);
    });
    this.actionServiceGUIUtil.setActionSourceFields(Number(this.actionIndex), sourceFields);
    this.actionConfig.action_fields = sourceFields; //storing fields for future reference
    this.sourceFields = this.actionServiceGUIUtil.addPreviousActionsSourceFields(Number(this.actionIndex), this.sourceFields);
  }

  actionSelected(e){
    console.log("[Application-com] actionSelected", e);
    this.actionFields = e;
    // this.actionServiceGUIUtil.setActionSourceFields(e);
    this.selectionChanged.emit({type: "actionSelected", event: e})
    this.triggerChange()
  }

  inputOptionsRecevied(e){
    console.log("inputOptionsRecevied", e);
    this.actionConfig.actionMap.inputParams = e?.options
    this.triggerChange()
  }

  inputQueryParamsRecevied(e){
    this.actionConfig.actionMap.queryParams = e;
  }

  inputHeadersRecevied(e){
    this.actionConfig.actionMap.headers = e;
  }

  onmappingChange(e){
    console.log("onmappingChange", e)
    this.actionConfig.actionMap.mapping = JSON.parse(JSON.stringify(e));
    this.selectionChanged.emit();
    this.triggerChange()
  }

  selectedWhiteLabeledConnection(e){
    console.log("selectedWhiteLabeledConnection", e);
    this.actionConfig.actionMap.whiteLabelMap = {
      widget: e
    }
    this.triggerChange()
  }

  whiteLabelChanged(e){
    console.log("whiteLabelChanged", e);
    this.actionConfig.actionMap.whiteLabelConnection = e;
    this.triggerChange()
  }

  manageSourceFields(pageMeta){
    console.log("pageMeta", pageMeta)
    // let te = new TemplateEngine();
    this.sourceFields = [];
    // pageMeta?.panels?.forEach(panelMeta => {
    //   let panel = panelMeta;
    //   let widgets = this.widgetService.getWidgetsFromPanel(panel);
    //   widgets.forEach((widget ) => {

    //     if(WidgetManager.getWidget(widget.type)?.getMappingConfig(this.widget)){
    //       let config = WidgetManager.getWidget(widget.type)?.getMappingConfig();
    //       let data = {
    //         panel: panel,
    //         widget: widget
    //       }
    //       this.sourceFields.push(te.fillTemplateObject(config, data))
    //     }

    //     if(widget.type == "connection" && WidgetManager.getWidget(widget.type)?.getMappingConfig(this.widget)){
    //       let config = WidgetManager.getWidget(widget.type)?.getMappingConfig();
    //       let data = {
    //         panel: panel,
    //         widget: widget
    //       }
    //       this.whitelabeledFields.push(te.fillTemplateObject(config, data));
    //       console.log("this.whitelabeledFields", this.whitelabeledFields)
    //     }
    //   })
    // })

    let fieldMap = this.attributeService.manageSourceFields(pageMeta, this.widget)
    this.sourceFields = fieldMap.sourceFields;
    this.whitelabeledFields = this.whitelabeledFields.concat(fieldMap.whitelabeledFields);

    console.log("[APPLICATION-COMPONENT] sourceFields", this.sourceFields)
    this.triggerChange()
  }

}
