import { Component, Input, OnInit, ViewChild, Inject, Output, EventEmitter, OnChanges, SimpleChanges, Directive, HostListener, AfterViewInit, ElementRef, PLATFORM_ID, NgZone, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router'
import { MatMenuTrigger } from '@angular/material/menu';

import { TokenUtil } from 'src/app/core/services/TokenUtil.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ActionManager } from 'src/app/bloom/models/Action/ActionManager';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { Subject } from 'rxjs';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
declare const google: any

@Directive({
    selector: '[outerCover]',
    standalone: false
})
export class resize {
  @HostListener('mousedown', ['$event'])
  onClick(target) {
    console.log("target clicked", target)
  }
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: false
})
export class LoginComponent extends BaseWidgetComponent implements OnInit, OnChanges, AfterViewInit {

  contextMenuActions: any = [];
  styles: any;
  element: any;
  parent: any;
  mouseDownPos: any = 0;
  initialWidth: any = 0;
  securityMap: any = {}

  spinner: boolean = false

  private destroy:any = new Subject();

  hoveredNow: boolean = false;
  isBrowser: any;

  constructor(public router: Router,
    public dialog: MatDialog,
    public tokenUtil: TokenUtil,
    public http: HttpClient,
    public elementRef: ElementRef,
    public actionManager: ActionManager,
    public metaService: MetaService,
    public resourcePermissionService: ResourcePermissionService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    private authService: AuthServiceService,
    private themeService: ThemeService,
    private ngZone: NgZone,
    private renderer: Renderer2, private el: ElementRef,
    public pageService: PageService
    ) {
      super(metaService, pageService, resourcePermissionService)
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    }

  ngAfterViewInit() {
    if (!this.builderMode) {
      this.addDivWithId();

    }
   }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }

    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
  }

  ngOnInit(): void {
    console.log("[LOGIN] this.widgetMeta", this.widgetMeta)

    if(!this.isBrowser) return;

    this.route.params.subscribe(routeData => {
      console.log("route data received: ", routeData)
    })

    // if(!this.builderMode) {
    //   this.addDivWithId()
    //   this.googleInit();
    // }

    super.ngOnInit();
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngAfterViewChecked() {

  }

  addDivWithId() {
    console.log("addDivWithId")
    const div = this.renderer.createElement('div');
    this.renderer.setAttribute(div, 'id', 'buttonDiv');
    this.renderer.setAttribute(div, 'fxLayoutAlign', 'center center');
    this.renderer.appendChild(this.outerCover.nativeElement.querySelector('.innerCover'), div);
    this.googleInit();
  }


  googleInit(){
    google.accounts.id.initialize({
      client_id:  environment.GOOGLE_CLIENT,
      callback: this.handleCredentialResponse.bind(this),
      // context: "signin",
      ux_mode: "popup",
      // auto_select: true
    });

    google.accounts.id.renderButton(
      this.document.getElementById("buttonDiv"),
      {
        theme: "filled_blue",
        // size: "medium",
        logo_alignment: "left",
        text: "signin_with",
        type: "standard",
        shape: "rectangular"

      }  // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog
  }

  async handleCredentialResponse(e){
    console.log("handleCredentialResponse", e);
    let that = this;
    this.spinner = true;
    // var userMap = this.tokenUtil.getDecodedJWT(e.credential);
    var userMap = await this.authService.checkAndGetBloomUser(e);
    this.metaService.bloomUserMap = userMap;
    this.metaService.bloomUserLoggedIn = true;
    let themeResult = await this.themeService.getExistingTheme(userMap._id);
    if (themeResult?.data?.[0]) {
      this.themeService.settings_Id = themeResult?.data[0]._id;
      this.themeService.setTheme(themeResult.data[0].themeSetting?.theme);
      this.themeService.currentLocale = themeResult.data[0].localeSetting;
      this.themeService.textDirection = themeResult.data[0].themeSetting.direction;
      if (themeResult.data[0].themeSetting.direction == 'rtl') {
        this.themeService.enableDirMode('rtl');
      }
    }
    console.log("userMap themeResult", userMap, themeResult);
    this.ngZone.run(() => {
      // if(this.isDialog){
        this.spinner = false;
        // that.loggedIn.emit(userMap);
      // } else {
        this.router.navigate(['p', this.metaService.bloomMeta.code, this.metaService.bloomMeta?.page_structure_map?.homePageCode]);
      // }

    })


  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  @ViewChild('menuTrigger') buttonMenuTrigger: MatMenuTrigger
  @ViewChild('outerCover') outerCover: ElementRef;


  //----------------------------METHODS-------------------------

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit"
      ],

    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    switch (event.actionType) {
      case "delete":
        this.onDelete()
        break;
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = event.data;
          super.generateStyles()
          // this.newWidgetMeta.emit(this.widgetMeta)
        }
        break;
      case "bindData":
        console.log("reached widget componenet, will update widget meta")
        this.widgetMeta.dataBindConfig = event.returnData
        // this.newWidgetMeta.emit(this.widgetMeta)
        this.buttonMenuTrigger?.closeMenu();
        break;
      case "executeBinding":
        this.executeBinding()
        break;
      case "customPropertyUpdate":
        console.log("RETURNED FROM MENU", event)
        this.widgetMeta.config[event.propertyName].value = event.data
        // this.newWidgetMeta.emit(this.widgetMeta)
        break;

      default:
        break;
    }
  }

  onOuterClick() {
    console.log("onOuterClick", Date.now())
    if (this.builderMode) {
      console.log("calling from onOuterClick")
      this.toggleWidgetSelection()
    }
  }

  mouseEnter(e) {
    // this.executeAction(e)
  }

  mouseLeave(e) {
    // this.executeAction(e)
  }

  async onClick(event: Event) {
    this.spinner = true
    this.widgetMeta.config.spin = true;
    let res = await this.executeAction(event);
    this.widgetMeta.config.spin = false;
    this.spinner = false
  }

  async executeAction(e) {
    if(!this.widgetMeta.actionConfig || !this.widgetMeta.actionConfig.actions) return
    if (!this.builderMode){
      let res = await this.actionManager.executeActions(this.widgetMeta.actionConfig.actions, e);
      return res
    }
    //  this.actionManager.getAction(this.widgetMeta.actionConfig.action).doAction(this.widgetMeta.actionConfig, e)
  }

  toggleWidgetSelection() {
    // console.log("toggle widget selection called")
    // toggle widget selection
    if (this.selectedWidgetId == this.widgetMeta.id) {
      this.widgetSelection.emit(-1);
      this.selectedWidgetId = -1
    } else {
      this.widgetSelection.emit(this.widgetMeta.id)
      this.selectedWidgetId = this.widgetMeta.id
    }
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.buttonMenuTrigger?.closeMenu();
  }

  executeBinding() {
    console.log('execute binding hit, will hit url', this.widgetMeta.dataBindConfig.url)
    console.log("config", this.widgetMeta.dataBindConfig.boxConfig)
    let bloomCode = 'bloom_1131'
    let token = this.tokenUtil.getStatelessToken()
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`)
      .set('boxConfigToken', this.widgetMeta.dataBindConfig.boxConfigToken)

    let payload = {
      parameters: {
        "query": {
          "attributes": "",
          "filter": "code=" + bloomCode + "|string",
          "sort": "id=ASC",
          "page": "1|10|1000"
        }
      }
    }

    let url = this.widgetMeta.dataBindConfig.url

    this.http.post(url, payload, { headers: headers }).subscribe(
      (response: any) => {
        console.log("execute data binding response", response);
      },
      (error: any) => {
        console.log('error occured in executing data binding', error)
      })
  }

  timer: any = 0;
  delay = 200;
  prevent = false;

}
