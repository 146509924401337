<!-- style="border: 1px solid black" -->
<div class="outerCover"
  *ngIf="builderMode"
  [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id ? false : hoveredNow"
  (mouseenter)="widgetMouseenter()" (mouseleave)="widgetMouseleave()" (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="selectedWidgetId !== widgetMeta.id && !hoveredNow"></widget-drag-handle>
  <div class="innerCover">
    <!-- <div class="widget-title" *ngIf="widgetMeta.config.showTitle?.value || true" [ngStyle]="styles">{{ widgetMeta.config.title?.value || "" }}</div> -->
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles" [showControls]="hoveredNow" (widgetTitleChanged)="newWidgetMeta.emit(widgetMeta)"></inline-title>
    
    <div>
      <mat-form-field
        disabled
        fxFlexFill
        [ngClass]="{ 'align-prefix-suffix': widgetMeta.config.appearance.value !== 'outline' }"
        [floatLabel]="widgetMeta?.config?.floatLabel?.value || 'auto'"
        [appearance]="widgetMeta.config.appearance && widgetMeta.config.appearance.value ? widgetMeta.config.appearance.value : 'standard'"
        [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
        (mousedown)="$event.stopPropagation()"
      >
        <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.label?.value || widgetMeta.config.placeholder.value || widgetMeta.name }}</mat-label>
        <!-- disabled -->
        <!-- [class]="getFormFieldClass()" -->
        <input
          matInput type="text"
          (keyup)="userInputDetected()"
          [ngModel]="widgetMeta.config?.value?.value"
          (ngModelChange)="widgetMeta.config?.value.value = $event"
          [required]="widgetMeta?.config?.required?.value ? widgetMeta.config.required.value : false"
          [placeholder]="widgetMeta.config.placeholder.value"
        >
        <span *ngIf="widgetMeta.config.prefix?.value" matPrefix>&nbsp;&nbsp;{{ widgetMeta.config.prefix?.value }}&nbsp;</span>
        <span *ngIf="widgetMeta.config.suffix?.value" matSuffix>&nbsp;{{ widgetMeta.config.suffix?.value }}&nbsp;&nbsp;</span>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="outerCover" *ngIf="!builderMode && (!widgetMeta.config.hidden || !widgetMeta.config.hidden.value)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <!-- style="border: 1px solid red" -->
  <div
    class="innerCover"
    [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
    *ngIf="!widgetMeta.config?.viewOnly || !widgetMeta.config?.viewOnly.value"
  >
    <!-- <div class="widget-title" [ngStyle]="styles">{{ widgetMeta.config.title?.value || "" }}</div> -->
    <inline-title [widgetMeta]="widgetMeta" [builderMode]="builderMode" [styles]="styles"></inline-title>

    <mat-form-field
      fxFlexFill
      [floatLabel]="widgetMeta?.config?.floatLabel?.value || 'auto'"
      [ngClass]="{ 'align-prefix-suffix': widgetMeta.config.appearance.value !== 'outline' }"
      [appearance]="this.widgetMeta.config.appearance && this.widgetMeta.config.appearance.value ? this.widgetMeta.config.appearance.value : 'standard'"
      (mousedown)="$event.stopPropagation()"
    >
      <mat-label *ngIf="!widgetMeta.config.title?.show || !widgetMeta.config.title?.value?.length">{{ widgetMeta.config.label?.value || widgetMeta.config.placeholder.value || widgetMeta.name }}</mat-label>
      <input
        matInput #input type="text"
        [formControl]="fc"
        [placeholder]="widgetMeta.config.placeholder.value"
        [required]="widgetMeta?.config?.required?.value ? widgetMeta.config.required.value : false"
        (keyup)="userInputDetected()"
        [ngModel]="widgetMeta.config?.value?.value"
        (ngModelChange)="widgetMeta.config?.value.value = $event"
      >
        <span *ngIf="widgetMeta.config.prefix?.value" matPrefix>&nbsp;&nbsp;{{ widgetMeta.config.prefix?.value }}&nbsp;</span>
        <span *ngIf="widgetMeta.config.suffix?.value" matSuffix>&nbsp;{{ widgetMeta.config.suffix?.value }}&nbsp;&nbsp;</span>
        <!-- <mat-error *ngIf="form.controls[widgetMeta.id].hasError('required') && (form.controls[widgetMeta.id].dirty || form.controls[widgetMeta.id].touched)">This field is required</mat-error> -->
        <mat-error *ngIf="fc.invalid && fc.hasError('required')">This field is required</mat-error>
        <mat-error *ngIf="fc.invalid && customErrorMessage">{{customErrorMessage}}</mat-error>
        <!-- [placeholder]="widgetMeta.config.placeholder.value" -->
        <!-- [required]="widgetMeta.config.required && widgetMeta.config.required.value" -->
        <mat-hint *ngIf="widgetMeta.note">
          <span style="font-size: small;margin-left: 10px; color: gray;" *ngIf="widgetMeta.note?.message">Note: {{widgetMeta.note.message}}</span>
          <a *ngIf="widgetMeta.note?.knowMoreLink" style="font-size: small;" [href]="widgetMeta.note.knowMoreLink" target="_blank"> &nbsp;know more</a>
        </mat-hint>
    </mat-form-field>
  </div>

  <div *ngIf="widgetMeta.config?.viewOnly && widgetMeta.config.viewOnly.value"
    class="innerCover label-z-index content" [ngStyle]="styles">
    <mat-label [ngStyle]="styles">{{ widgetMeta.config?.value?.value }}</mat-label>
  </div>
</div>
